import React, { useEffect, useState } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import "./TimeClock.css";

const TimeClock = (props) => {
  const [value, setValue] = useState(new Date());
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const runWhenPageIsFullyParsed = async () => {
    let startTime = props.startTime;

    let clockHourNodes = await document.querySelectorAll(
      ".react-clock__mark__number"
    );

    let clockMinuteNodes = await document.querySelectorAll(
      ".react-clock__minute-mark__body"
    );

    for (let x = 0; x < clockMinuteNodes.length; x++) {
      clockMinuteNodes[x].id = x + 1;
    }

    let startHour = parseInt(startTime.split(":")[0]);
    let startMinutes = parseInt(startTime.split(":")[1]);
    let clockStartHour = startHour === 12 ? 0 : startHour;

    let nodeNum = clockStartHour * 4 + Math.floor((startMinutes / 60) * 4);
    if (clockHourNodes.length > 0) {
      setPageLoaded(true);
      if (startMinutes === 0) {
        clockHourNodes[startHour - 1].style.color = "yellow";
      } else {
        clockMinuteNodes[nodeNum].style.backgroundColor = "yellow";
        clockMinuteNodes[nodeNum].style.height = "10px";
        clockMinuteNodes[nodeNum].style.width = "5px";
      }
    } else {
      return;
    }
  };

  if (document.readyState === "complete") {
    if (pageLoaded !== true) {
      // already fired, so run logic right away
      runWhenPageIsFullyParsed();
      // console.log("how many times is this rendering");
    }
  } else {
    // not fired yet, so let's listen for the event
    window.addEventListener("DOMContentLoaded", runWhenPageIsFullyParsed);
  }

  return (
    <Clock
      size={100}
      className="clockStyle"
      renderNumbers={true}
      value={value}
    />
  );
};

export default TimeClock;
