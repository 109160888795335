import React from 'react';
import {withRouter} from 'react-router'
import {Card, Button, Container} from 'react-bootstrap'

const Oops = () => {
    const closeHandler=()=>{
        localStorage.clear()
        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
    }
    return ( 
        <Container className='text-center fluid justify-content-center' style={{width:'1920px'}}>
        
        <Card className='justify-content-center' style={{width:'480px'}}>
            {/* <Card.Img variant="top" src={process.env.PUBLIC_URL + "/UHCCommunity.png"} height='80px' className='text-center'/> */}
            <Card.Header className='text-center'><h5>Oops!<br/>It appears you do not have access.</h5></Card.Header>
            <Card.Body>
            <Card.Text className='text-center'>
            <p>PCR is performed in certain markets, and based on your location, PCR is currently not available.</p>
            <p>OR</p>
            <p>It appears as if you were not selected to participate in the PCR program. If you become eligible to participate in the future, you will be contacted by an Optum Outreach representative.</p>
            <Button variant="warning" onClick={closeHandler}>Close</Button>
            </Card.Text>
            </Card.Body>
        </Card>
        </Container>
     );
}
 
export default withRouter(Oops);