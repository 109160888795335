import { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./RejectionOptions.css";

const RejectionOptions = (props) => {
  const { options = [], setRejectionReason } = props;
  const [reason, setReason] = useState(null);
  const [otherReasonDescription, setOtherReasonDescription] = useState(null);
  const half = Math.ceil(options.length / 2);
  const handleClose = () => {};

  const handleSetRejectionReason = (option) => {
    setOtherReasonDescription(null);
    setReason(option);
  };

  const handleOtherReasonDescription = (e) => {
    setOtherReasonDescription(e.target.value.toUpperCase());
  };

  const handleSubmit = () => {
    setRejectionReason(reason, otherReasonDescription);
  };

  const generateOptions = (optionSet) => {
    const radioButtons = optionSet.map((option, index) => {
      return (
        <div key={index} className="mb-3">
          <Form.Check type="radio" id={"rejectionOption" + index}>
            <Form.Check.Input
              type={"radio"}
              name="rejectionOptions"
              isValid
              onClick={handleSetRejectionReason.bind(null, option)}
            />
            <Form.Check.Label className="rejectionOptionsLabel">
              {option.toUpperCase()}
            </Form.Check.Label>
            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          </Form.Check>
        </div>
      );
    });

    radioButtons.push(
      <div key={radioButtons.length} className="mb-3">
        <Form.Check type="radio" id={"rejectionOption" + radioButtons.length}>
          <Form.Check.Input
            type={"radio"}
            name="rejectionOptions"
            isValid
            onClick={handleSetRejectionReason.bind(null, "other")}
          />
          <Form.Check.Label className="rejectionOptionsLabel"></Form.Check.Label>
          <Form.Control
            type="text"
            placeholder={"Other".toUpperCase()}
            value={otherReasonDescription}
            onChange={handleOtherReasonDescription}
            className="d-inline-block w-auto"
            key={reason + "description"}
            disabled={reason !== "other"}
          />
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
        </Form.Check>
      </div>
    );
    return radioButtons;
  };

  const radioButtons = generateOptions(options);

  const firstCol = radioButtons.slice(0, half);
  const secondCol = radioButtons.slice(-half);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <div className="d-flex flex-column rejectionOptionsContainer">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">{firstCol}</div>
          <div className="d-flex flex-column">{secondCol}</div>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <Button
            id="modalRejectButton"
            onClick={handleSubmit}
            disabled={reason === null}
          >
            {`Reject`.toUpperCase()}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RejectionOptions;
