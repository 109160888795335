import React, { useState, useEffect } from "react";
import {Grid} from "@mui/material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import "./CMSForm.css";
import qrcode from "../../images/qrcode.png";
import Popover from "react-bootstrap/Popover";
import { Formik, Field} from "formik";
import * as yup from "yup";
import RejectionOptions from "./RejectionOptions";
// import placeOfServiceCodes from "./placeOfServiceCodes";
// import modifiers from "./modifiers";
import moment from 'moment-timezone'
import { Tooltip } from "react-bootstrap";
import {posDescr,modifierDescr,taxonomyDescr} from "../../utils/hover"
import _ from 'underscore'

const schema = yup.object().shape({
  services: yup
    .array()
    .of(
      yup.object().shape({
        charges: yup.string(),
        cpt: yup.mixed(),
        daysUnits: yup.mixed(),
        dosFrom: yup.mixed(),
        dosTo: yup.mixed(),
        emg: yup.mixed(),
        epsdtFamilyPlan: yup.mixed(),
        idQual: yup.mixed(),
        modifier1: yup.mixed(),
        modifier2: yup.mixed(),
        modifier3: yup.mixed(),
        modifier4: yup.mixed(),
        placeOfService: yup.mixed(),
        pointer: yup.mixed(),//.required("Required"),
        pointerO:yup.mixed(),
        renderingProviderId: yup.mixed(),
        renderingProviderNpi: yup.mixed()
      })
  ),
  hpName: yup.string(),
  hpAddress: yup.string(),
  //hpAddress2: yup.string(),
  hpCityStateZip: yup.string(),
  insuranceType: yup.string(),
  member_id: yup.string(), //.max(29, "Must be 29 characters or less "),
  member_name: yup.string(),//.required("Required"),
  member_dobMM: yup.string(),//.required("Required"),
  member_dobDD: yup.string(),//.required("Required"),
  member_dobYY: yup.string(),//.required("Required"),
   /*insuredName: yup
    .string()
    //.required("Required")
    .max(29, "Must be 29 characters or less "),*/
  /*patientAddress: yup
    .string()
    //.required("Required")
    .max(28, "Must be 28 characters or less "),*/
  member_gender: yup.string(),//.required("Required"), 
  member_address: yup
    .string(),
    //.required("Required")
    // .max(28, "Must be 28 characters or less "),
  member_city: yup
    .string(),
    //.required("Required")
    // .max(24, "Must be 24 characters or less "),
  member_state: yup
    .string(),
    //.required("Required")
    // .max(3, "Must be 3 characters or less "),
  /*insuredState: yup
    .string()
    //.required("Required")
    .max(3, "Must be 3 characters or less "),*/
  member_zip: yup
    .string(),
    //.required("Required")
    // .max(12, "Must be 12 characters or less "),
  member_phone: yup
    .string(),
   // //.required("Required")
    // .max(10, "Must be 10 characters or less "),
 /* patientAreaCode: yup
    .string()
    //.required("Required")
    .max(3, "Must be 3 characters or less "),*/
 /*insuredPhone: yup
    .string()
    //.required("Required")
    .max(10, "Must be 10 characters or less "),*/
  member_areaCode: yup
    .string(),
   // //.required("Required")
    // .max(3, "Must be 3 characters or less "),
  other_insured_name: yup
    .string()
    .nullable(),
    // .max(28, "Must be 28 characters or less "),
  otherInsuredNumber: yup
    .string()
    .nullable(),
    // .max(28, "Must be 28 characters or less "),
  other_insured_policy: yup
    .string()
    .nullable(),
    // .max(28, "Must be 28 characters or less "),
  nuccClaimCodes: yup.string().nullable(), //.max(19, "Must be 19 characters or less"),
  insured_policy: yup
    .string()
    .nullable(),
    // .max(29, "Must be 29 characters or less "),
  insured_dob: yup.string().nullable(),
  insured_name: yup.string().nullable(),
  employment: yup.string(),//.required("Required"),
  autoAccident: yup.string(),//.required("Required"),
  autoAccidentState: yup.string().nullable(),
  // .max(2, "Must be 2 characters "),
  otherAccident: yup.string(),//.required("Required"),
  member_signature: yup.string(),//.required("Required"),
  member_signature_date: yup.string(),//.required("Required"),
  insured_signature: yup.string(),//.required("Required"),
  insured_dobMM: yup.string().nullable(),
  insured_dobDD: yup.string().nullable(),
  insured_dobYY: yup.string().nullable(),
  npi: yup.string().nullable(),
  serviceProviderNpi: yup.string().nullable(),
  // services: yup.array().of(
  //   yup.object().shape({
  //     renderingProviderNpi: yup.string().nullable(),
  //   })
  // ),
  tax_id: yup
    .string()
    .nullable(),
    // .required(
    //   "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
    // )
    // .max(
    //   9,
    //   "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
    // )
    // .min(
    //   9,
    //   "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
    // ),
  taxIdType: yup
    .string(),
    // .required(
    //   "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
    // ),
  billingProviderNpi: yup.string(),
});

// const schema = yup.object().shape({
//   services: yup
//     .array()
//     .of(
//       yup.object().shape({
//         charges: yup.string(),
//         cpt: yup.mixed(),
//         daysUnits: yup.mixed(),
//         dosFrom: yup.mixed(),
//         dosTo: yup.mixed(),
//         emg: yup.mixed(),
//         epsdtFamilyPlan: yup.mixed(),
//         idQual: yup.mixed(),
//         modifier1: yup.mixed(),
//         modifier2: yup.mixed(),
//         modifier3: yup.mixed(),
//         modifier4: yup.mixed(),
//         placeOfService: yup.mixed(),
//         pointer: yup.mixed().required("Required"),
//         pointerO:yup.mixed(),
//         renderingProviderId: yup.mixed(),
//         renderingProviderNpi: yup.mixed()
//       })
//   ),
//   hpName: yup.string(),
//   hpAddress: yup.string(),
//   //hpAddress2: yup.string(),
//   hpCityStateZip: yup.string(),
//   insuranceType: yup.string(),
//   member_id: yup.string().max(29, "Must be 29 characters or less "),
//   member_name: yup.string().required("Required"),
//   member_dobMM: yup.string().required("Required"),
//   member_dobDD: yup.string().required("Required"),
//   member_dobYY: yup.string().required("Required"),
//    /*insuredName: yup
//     .string()
//     .required("Required")
//     .max(29, "Must be 29 characters or less "),*/
//   /*patientAddress: yup
//     .string()
//     .required("Required")
//     .max(28, "Must be 28 characters or less "),*/
//   member_gender: yup.string().required("Required"), 
//   member_address: yup
//     .string()
//     .required("Required")
//     .max(28, "Must be 28 characters or less "),
//   member_city: yup
//     .string()
//     .required("Required")
//     .max(24, "Must be 24 characters or less "),
//   member_state: yup
//     .string()
//     .required("Required")
//     .max(3, "Must be 3 characters or less "),
//   /*insuredState: yup
//     .string()
//     .required("Required")
//     .max(3, "Must be 3 characters or less "),*/
//   member_zip: yup
//     .string()
//     .required("Required")
//     .max(12, "Must be 12 characters or less "),
//   member_phone: yup
//     .string()
//    // .required("Required")
//     .max(10, "Must be 10 characters or less "),
//  /* patientAreaCode: yup
//     .string()
//     .required("Required")
//     .max(3, "Must be 3 characters or less "),*/
//  /*insuredPhone: yup
//     .string()
//     .required("Required")
//     .max(10, "Must be 10 characters or less "),*/
//   member_areaCode: yup
//     .string()
//    // .required("Required")
//     .max(3, "Must be 3 characters or less "),
//   other_insured_name: yup
//     .string()
//     .nullable()
//     .max(28, "Must be 28 characters or less "),
//   otherInsuredNumber: yup
//     .string()
//     .nullable()
//     .max(28, "Must be 28 characters or less "),
//   other_insured_policy: yup
//     .string()
//     .nullable()
//     .max(28, "Must be 28 characters or less "),
//   nuccClaimCodes: yup.string().nullable().max(19, "Must be 19 characters or less"),
//   insured_policy: yup
//     .string()
//     .nullable()
//     .max(29, "Must be 29 characters or less "),
//   insured_dob: yup.string().nullable(),
//   insured_name: yup.string().nullable(),
//   employment: yup.string().required("Required"),
//   autoAccident: yup.string().required("Required"),
//   autoAccidentState: yup.string().nullable().max(2, "Must be 2 characters "),
//   otherAccident: yup.string().required("Required"),
//   member_signature: yup.string().required("Required"),
//   member_signature_date: yup.string().required("Required"),
//   insured_signature: yup.string().required("Required"),
//   insured_dobMM: yup.string().nullable(),
//   insured_dobDD: yup.string().nullable(),
//   insured_dobYY: yup.string().nullable(),
//   npi: yup.string().nullable(),
//   serviceProviderNpi: yup.string().nullable(),
//   services: yup.array().of(
//     yup.object().shape({
//       renderingProviderNpi: yup.string().nullable(),
//     })
//   ),
//   tax_id: yup
//     .string()
//     .nullable()
//     .required(
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
//     )
//     .max(
//       9,
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
//     )
//     .min(
//       9,
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
//     ),
//   taxIdType: yup
//     .string()
//     .required(
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)"
//     ),
//   billingProviderNpi: yup.string(),
// });

// const isFormValid = (formikProps)=>{
//   return formikProps.isValid
// }

const ConditionalWrapper = ({ condition, wrapper, children, descriptions }) =>
  condition ? wrapper(children, descriptions) : children;

const getPopoverStyling = (error, description) => {
  if (error)
    return <Popover className="popoverError">
    <Popover.Content>{error}</Popover.Content>
  </Popover>
  else if (description?.title)
    return <Popover>
    <Popover.Title as="h3">{description.title}</Popover.Title>
    <Popover.Content>
      {description.long}
    </Popover.Content>
  </Popover>
}

const ErrorAndDescriptionWrapper = (props) => {
  const { error, description, name, activeField, children } = props;
  return (
    <ConditionalWrapper
      condition={error || description?.title}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          show={activeField === name}
          overlay={
            getPopoverStyling(error, description)
          }
        >
          {children}
        </OverlayTrigger>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

const ErrorAndDescriptionWrapper2 = (props) => {
  const { error, description, name, activeField, children, showErrorMessage } = props;
  // console.log(showErrorMessage)
  return (
    <ConditionalWrapper
      condition={error || description?.title}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          show={showErrorMessage ? activeField === name : false}
          overlay={
            getPopoverStyling(error, description)
          }
        >
          {children}
        </OverlayTrigger>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

const BasicFieldWrapper = (props) => {
  const { name, activeField, validate, description, id, children, wrapperStyles, isBlank } = props;

  if (isBlank) {

    if(name === 'totalChargeDollars') {
      return (
        <Field
          name={'totalChargeContinued'}
          validate={undefined}
          id={undefined}
        >
          {({ field, form, meta }) => (
          <ErrorAndDescriptionWrapper
            name={field.name}
            error={meta.error}
            activeField={activeField}
            description={description}
          >
            <div className="rowContainer" style={wrapperStyles} >
              {React.cloneElement(children, { ...field })}
              {meta.error && (
                <ExclamationTriangleFill
                  color={"var(--optum-error-state-red)"}
                  size={12}
                />
              )}
            </div>
          </ErrorAndDescriptionWrapper>
        )}
        </Field>
      );
    }

    if(name === 'totalChargeCents') {
      return (
        <Field
          name={'totalCentsContinued'}
          validate={undefined}
          id={undefined}
        >
          {({ field, form, meta }) => (
          <ErrorAndDescriptionWrapper
            name={field.name}
            error={meta.error}
            activeField={activeField}
            description={description}
          >
            <div className="rowContainer" style={wrapperStyles} >
              {React.cloneElement(children, { ...field })}
              {meta.error && (
                <ExclamationTriangleFill
                  color={"var(--optum-error-state-red)"}
                  size={12}
                />
              )}
            </div>
          </ErrorAndDescriptionWrapper>
        )}
        </Field>
      );
    }

    return <div className="rowContainer" style={wrapperStyles}>{children}</div>
  }
  
  return (
    <Field
      name={name}
      validate={validate ? validate : undefined}
      id={id ? id : undefined}
    >
      {({ field, form, meta }) => (
        <ErrorAndDescriptionWrapper
          name={field.name}
          error={meta.error}
          activeField={activeField}
          description={description}
        >
          <div className="rowContainer" style={wrapperStyles} >
            {React.cloneElement(children, { ...field })}
            {meta.error && (
              <ExclamationTriangleFill
                color={"var(--optum-error-state-red)"}
                size={12}
              />
            )}
          </div>
        </ErrorAndDescriptionWrapper>
      )}
    </Field>
  );
};

const BasicFieldWrapper2 = (props) => {
  // console.log(props)
  const { name, activeField, validate, description, id, children, wrapperStyles, showErrorMessage, isBlank } = props;
  if (isBlank) return <div className="rowContainer" style={wrapperStyles}>{children}</div>

  return (
    <Field
      name={name}
      validate={validate ? validate : undefined}
      id={id ? id : undefined}
    
    >
      {({ field, form, meta }) => (
        <ErrorAndDescriptionWrapper2
          name={field.name}
          error={meta.error}
          activeField={activeField}
          description={description}
          showErrorMessage={showErrorMessage}
        >
          <div className="rowContainer" style={wrapperStyles} >
            {React.cloneElement(children, { ...field })}
            {meta.error && (
              <ExclamationTriangleFill
                color={"var(--optum-error-state-red)"}
                size={12}
              />
            )}
          </div>
        </ErrorAndDescriptionWrapper2>
      )}
    </Field>
  );
};


const CMSRadio = (props) => {
  const {
    number,
    id,
    title,
    title2,
    value,
    label,
    field,
    meta,
    // setFieldValue,
    setFieldTouched,
    activeField,
    handleFocus,
    disabled,
    readOnly
  } = props;
 
  return (
    <div className="checkboxContainer colContainer spaceBetween">
      <div className="colContainer">
        { label && (
          <label
            className="text checkboxLabel checkboxLabelMargin"
            style={{ marginLeft: 18 }}       
          >
            {title.toUpperCase()}
          </label>
        )}
        { title2 && (
          <label className="checkboxTitle2 text checkboxLabel checkboxLabelMargin">{title2.toUpperCase()}</label> 
        )}
      </div>
      <ErrorAndDescriptionWrapper
        name={field.name}
        error={meta.error}
        activeField={activeField}
      >
        <>
          {number && <h3 className="text checkboxNumber">{number}.</h3>}
          <input
            id={title}
            type="radio"
            className="radio cmsRadio"
            value={value}
            checked={field.value === value}
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            disabled={disabled}
            readOnly={readOnly}
            {...field}
          />
          {label ? (
            <label id={id} className="checkboxItalicLabel text checkboxLabel checkboxTitle">({label})</label>
          ) : (
            <label
              className="text checkboxLabel checkboxTitle"   
              id={id}         
            >
              {title.toUpperCase()}
            </label>
          )}
        </>
      </ErrorAndDescriptionWrapper>
    </div>
  );
};

const CMSTitle = (props) => {
  const { number, elementName, title, subtitle, allowWrap, centeredTitle } =
    props;
  const numberLabel = number ? number + ". " : "";
  const subtitleLabel = subtitle ? subtitle : "";
  return (
    <label
      htmlFor={elementName}
      className={
        centeredTitle ? "text label cellLabel center" : "text label cellLabel"
      }
    >
      <span className={allowWrap ? "" : "nowrap"}>
        {numberLabel}
        {title ? title.toUpperCase() : ""}
      </span>
      {" " + subtitleLabel}
    </label>
  );
};

const CMSInputWrapper = (props) => {
  const { wrapperId, children, spaceBetween, justifyCenter, doubleHeight, inlineComponent, centeredTitle } = props;
  // if (wrapperId) console.log("Wrapper id: ", wrapperId)
  const wrapperProps = { id: wrapperId ? wrapperId : undefined}
  return (
    <div
      className={
        doubleHeight
          ? "colContainer spaceBetween inputContainer doubleHeight"
          : "colContainer spaceBetween inputContainer"
      }
      {...wrapperProps}
    >
      <div className={centeredTitle ? "rowContainer spaceAround" : "rowContainer spaceBetween"}>
        <CMSTitle {...props} />
        {inlineComponent}
      </div>
      <div
        className={spaceBetween ? "rowContainer spaceBetween" : justifyCenter ? "rowContainer justify-content-center" : "rowContainer"}
      >
        {children}
      </div>
    </div>
  );
};

const CMSTextInput = (props) => {
  const { name, size, prompt, maxLength, handleFocus, setFieldTouched, activeField, disabled, inputStyle} = props;
  return (
    <CMSInputWrapper {...props}>
      <BasicFieldWrapper name={name} activeField={activeField}>
        <input
          className="input"
          size={size}
          prompt={prompt}
          onFocus={(e) => handleFocus(e, setFieldTouched)}
          maxLength={maxLength}
          disabled={disabled}
          style={inputStyle ? inputStyle : undefined}
        />
      </BasicFieldWrapper>
    </CMSInputWrapper>
  );
};

// const CMSTextAreaInput = (props) => {
//   const { elementName, size, rows, handleFocus, setFieldTouched, activeField, disabled } = props;
//   return (
//     <CMSInputWrapper {...props}>
//       <BasicFieldWrapper name={elementName} activeField={activeField}>
//         <textarea
//           onFocus={(e) => handleFocus(e, setFieldTouched)}
//           disabled={disabled}
//           className="input"
//           size={size}
//           rows={rows}
//         />
//       </BasicFieldWrapper>
//     </CMSInputWrapper>
//   );
// };

const getDatepickerTextColor = (date) => {
  if (!date)
    return "rgba(0,0,0,0)"
  else return ""
}

// const CMSDateInput = (props) => {
//   const { handleFocus, setFieldTouched, meta, field, activeField, disabled } = props;
//   // console.log("Date: " + Date.parse(field.value));
//   return (
//     <CMSInputWrapper {...props}>
//       <ErrorAndDescriptionWrapper
//         name={field.name}
//         error={meta.error}
//         activeField={activeField}
//       >
//         <>
//           <input
//             type="date"
//             id={field.name}
//             onFocus={(e) => handleFocus(e, setFieldTouched)}
//             disabled={disabled}
//             value={Date.parse(field.value)}
//             {...field}
//             className={"datepicker"}
//             style={{ color: getDatepickerTextColor(field.value) }}
//           />
//           {meta.error && (meta.touched || field.name === activeField) && (
//             <ExclamationTriangleFill color={"var(--optum-error-state-red)"} />
//           )}
//         </>
//       </ErrorAndDescriptionWrapper>
//     </CMSInputWrapper>
//   );
// };

const CMSCurrencyInput = (props) => {
  const { elementName, showDollarSign, showDashedLine, activeField, handleFocus, setFieldTouched, disabled, isBlank } = props;
  return (
    <CMSInputWrapper {...props}>
      <div className="colContainer">
        <label className="text label middleAlign ms-1">
          {showDollarSign && `$`}
        </label>
      </div>
      <div className="colContainer">
        <BasicFieldWrapper name={`${elementName}Dollars`} activeField={activeField} isBlank={isBlank}>
          <input
            type="text"
            id={`${elementName}Dollars`}
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            disabled={disabled}
            className="input text-end"
            // onChange={validateCurrency.bind(this)}
            onInput={(e) => validateCurrency(e)}
            style={{ width: showDashedLine ? 60:36 }}
          />
        </BasicFieldWrapper>
      </div>
      <div className="colContainer">
        <BasicFieldWrapper name={`${elementName}Cents`} /*cent name placeholder*/ activeField={activeField} isBlank={isBlank}> 
          <input
            type="text"
            id={`${elementName}Cents`}
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            disabled={disabled}
            className="input text-end"
            // onChange={validateCurrency.bind(this)}
            onInput={(e) => validateCurrency(e)}
            style={{ width: 20 }}
          />
        </BasicFieldWrapper>
      </div>
      {showDashedLine && (
        <div className="relativePositioningPlaceholder">
          <div className="verticalRule dashed" style={{ height: 20,position: "relative",  left:-17 }} />
        </div>
      )}  
          
    </CMSInputWrapper>
  );
};

const CMSOptionList = (props) => {
  const {
    options,
    additionalLabel,
    elementName,
    labelPosition,
    handleFocus,
    activeField,
    // setFieldValue,
    setFieldTouched,
    disabled,
    readOnly
  } = props;
  if (labelPosition === "top") {
    return options.map((option, index) => (
      <div id={`${elementName}${option}`} key={`${elementName}${option}`} style={{ width: 20 }} >
        <Field name={elementName} id={`${elementName}${index + 1}`} key={`${elementName}${index + 1}`}>
          {({ field, form, meta }) => (
            <ErrorAndDescriptionWrapper
              key={`error - ${elementName}${index + 1}`}
              name={field.name}
              error={meta.error}
              activeField={activeField}
            >
                <input
                  key={`input - ${elementName}${index + 1}`}
                  type="radio"
                  className={"checkboxTop cmsRadio"}
                  checked={
                    field.value?.toString()?.toLowerCase() ===
                    option?.toLowerCase()
                  }
                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                  // disabled={disabled}
                  readOnly={readOnly}
                  {...field}
                />
            </ErrorAndDescriptionWrapper>
          )}
        </Field>
        <label
          key={`label - ${elementName}${index + 1}`}
          className="text topLabel"
        >
          <span key={`span - ${elementName}${index + 1}`} className="checkboxListLabelTop topLabel">{option}</span>
        </label>
      </div>
    ));
  }
  return (
    <CMSInputWrapper {...props}>
      {options.map((option, index) => (
        <div id={`${elementName}${option}`} key={`div - ${elementName}${option}`} className={labelPosition === "left" ? "" : "checkboxListContainer"}>
          <Field key={`field - ${elementName}${index + 1}`} name={elementName} id={`${elementName}${index + 1}`}>
            {({ field, form, meta }) => (
              <ErrorAndDescriptionWrapper
              key={`error - ${elementName}${index + 1}`}
                name={field.name}
                error={meta.error}
                activeField={activeField}
              >
                <input
                  key={`input - ${elementName}${index + 1}`}
                  type="radio"
                  className={labelPosition === "left" ? "checkbox cmsRadioLeftLabel" : "checkbox cmsRadio"}
                  checked={
                    field.value?.toString()?.toLowerCase() ===
                    option?.toLowerCase()
                  }
                 onFocus={(e) => handleFocus(e, setFieldTouched)}
                  disabled={disabled}
                  {...field}
                />
              </ErrorAndDescriptionWrapper>
            )}
          </Field>
          <label
            key={`label - ${elementName}${index + 1}`}
            className={labelPosition === "left" ? "text checkboxListLabelLeft" : "text checkboxListLabel"}
          >
            <span key={`span - ${elementName}${index + 1}`} className={labelPosition === "left" ? "text checkboxListLabelLeft" : "text checkboxListLabel"}>{option}</span>
          </label>
        </div>
      ))}
      {additionalLabel && additionalLabel}
    </CMSInputWrapper>
  );
};

const CMSPhoneInput = (props) => {
  const { elementName1, elementName2, activeField, handleFocus, setFieldTouched, disabled } = props;
  return (
    <CMSInputWrapper {...props}>
      <span className="text">{`(`}</span>
      <BasicFieldWrapper
        name={elementName2}
        id={elementName2}
        activeField={activeField}
      >
        <input
          className="input"
          maxLength={3}
          style={{ width: 25 }}
          onFocus={(e) => handleFocus(e, setFieldTouched)}
          disabled={disabled}
        />
      </BasicFieldWrapper>
      <span className="text">{`)`}</span>
      <BasicFieldWrapper
        name={elementName1}
        id={elementName1}
        activeField={activeField}
      >
        <input
          className="input"
          maxLength={10}
          style={{ width: 70 }}
          onFocus={(e) => handleFocus(e, setFieldTouched)}
          disabled={disabled}
        />
      </BasicFieldWrapper>
    </CMSInputWrapper>
  );
};

// const icdDescriptions = [
//   { 
//     title: "description 1",
//     long: "Dislocation of C0/C1 cervical vertebrae, initial encounter"
//   },
//   { 
//     title: "description 2",
//     long: "Contusion of face, scalp, and neck except eye(s)"
//   },
//   { 
//     title: "description 3",
//     long: "Laceration with foreign body of larynx, initial encounter"
//   },
//   { 
//     title: "description 4",
//     long: "Malignant neoplasm of prostate"
//   },
//   { 
//     title: "description 5",
//     long: "Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, initial encounter for closed fracture"
//   },
// ];

// const icdDescriptions = [
//   { 
//     title: "description 1",
//     long: props.description
//   }
// ]

const upperCaseInput = (e) => {
  e.target.value = e.target.value.toUpperCase()
}

const disablecheck = (e,arr) => {
  let bool = true
  if(arr) {
   if(arr.includes(e)) {
     bool = false
   }
     
  }
// console.log(bool)
  // console.log(arr.includes(e))
  return bool
}

const CMSInputTable = (props) => {
  const { fields, name, handleFocus, setFieldTouched, activeField, icdInfo, editenabled, showErrorMessage, disabledInputs } = props;
  //added by Geo for the hover
  const hoverIcdFunction =(e)=>{
    if (/^icd/.test(e.target.name) && e.target.value) {
      // console.log(e.target)
      let diag=_.findWhere(icdInfo,{diag_cd:e.target.value} )
      if (diag) {
        // console.log(diag)
        e.target.title=`${diag.diag_cd}\n${diag.short_desc}\n${diag.full_desc}`
      }
      else {
        console.log('Not Found!')
        console.log(icdInfo)
      }
    }
    else { // Blank diagnosis:
      e.target.title=''
    }
  }
  var cells = [];
  // console.log("incmsinput",editenabled)
  for (var i = 0; i < fields; i++) {
    cells.push(
      <Grid item xs={3}>
        <div className="rowContainer">
          <label for={name + `[${i}]`} className="text label inputTableLabel">
            {(i + 10).toString(36).toUpperCase()}.
          </label>
          <div className="relativePositioningPlaceholder">
            <div  
              style={{ width: 3, height: 12, position: "relative", top: -4, background: "#fff" }}
            />
          </div>
          <BasicFieldWrapper2 name={`${name}[${i}]`} activeField={activeField} showErrorMessage={showErrorMessage}>
            <input
              //key={i}
              className="inputTableField"
              onFocus={(e) => handleFocus(e, setFieldTouched)}
              onInput={(e) => upperCaseInput(e)}
              onMouseOver = {(e)=>hoverIcdFunction(e)}
              // onBlur={(e)=>console.log('onBlur')}
              disabled={editenabled ?  (disabledInputs ? disablecheck(i, disabledInputs) : false) : true}
            />
          </BasicFieldWrapper2>
        </div>
      </Grid>
    );
  }
  return (
    <Grid container spacing={1}>
      {cells}
    </Grid>
  );
};


const CMSInnerTableHeaders = (props) => {
  const { number, title, subtitle, name, children } = props;
  return (
    <div className="colContainer spaceBetween inputContainer">
      <CMSTitle
        elementName={ name ? name : "header"}
        number={number}
        title={title}
        subtitle={subtitle}
        centeredTitle
      />
      <div className="rowContainer spaceAround">{children}</div>
    </div>
  );
};

const CharacterInput = (props) => {
  return <input className="characterInput"></input>;
};

const validateCurrency = (e) => {
  var currencyText = e.target.value
    .replace(/(?!\.)\D/g, "")
    .replace(/(?:\..*)\./g, "")
    .replace(/(?:\.\d\d).*/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  e.target.value = currencyText;
};

const hoverFunction2=async (e, fieldType, cptInfo={})=>{
  // console.log(e)
  if (e.target.value) {
    switch(fieldType) {
      case "cpt":
        let cpt_diag=_.findWhere(cptInfo,{prcd_cd:e.target.value} )
        if (cpt_diag) {
          e.target.title=`${cpt_diag.prcd_cd}\n${cpt_diag.short_desc}\n${cpt_diag.full_desc}`
        }
        break;
      case "pos":
        const posDescription = posDescr(e.target.value)
        if (posDescription.length > 0) {
          e.target.title=`${posDescription}`
        }
        break;
      case "mod":
        const modifierDescription = modifierDescr(e.target.value)
        if (modifierDescription.length > 0) {
          e.target.title=`${modifierDescription}`
        }
        break;
      case "tc":
        const taxonomyCodeDescription = await taxonomyDescr(e.target.value)
        if (taxonomyCodeDescription.length > 0) {
          e.target.title=`${taxonomyCodeDescription}`
        }
        else
          e.target.title=`No description available`
        break;
      default:
        return undefined;
    }
  } else {
    e.target.title=''
  }
}

const DividedCell = (props) => {
  const { colSpan, noPadding, children } = props;
  return (
    <td colSpan={colSpan} className={ noPadding ? "dividedCell ps-0 pe-0" : "dividedCell"} >
      <div className="colContainer">
        <div className="rowContainer spaceBetween">{children}</div>
      </div>
    </td>
  );
};

const DividedRow = (props) => {
  const {  values, index, handleFocus, activeField, setFieldTouched, disabled, editenabled, validateCharge, cptInfo, original, showErrorMessage} = props;
  // console.log(index)
  const row = index % 6 || 6;
  // console.log(row)

  return (
    <tr className="dividedRow">
      <DividedCell colSpan={3}>
        <div className="relativePositioningPlaceholder">
          <label className="text rowNumber">{row}</label>
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].dosFromMM`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 19 }}
            //type="date"
            //className="datepicker small" 
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].dosFromDD`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 19 }}
            //type="date"
            //className="datepicker small" 
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].dosFromYY`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={4}
            type="text"
            className="input center"
            style={{ width: 19 }}
            //type="date"
            //className="datepicker small" 
            disabled={disabled}
          />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={3}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].dosToMM`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
           <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 19 }}
            disabled={disabled}
            //style={{ marginTop: 16 }}
            //type="date"
            //className="datepicker small"
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].dosToDD`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 19 }}
            //type="date"
            //className="datepicker small" 
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].dosToYY`}
          activeField={activeField}
          wrapperStyles={{width: 27, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            maxLength={4}
            type="text"
            className="input center"
            style={{ width: 19 }}
            //type="date"
            //className="datepicker small" 
            disabled={disabled}
          />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={2}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].placeOfService`}
          activeField={activeField}
          validate={validatePlaceOfService}
          wrapperStyles={{width: 53, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input 
            style={{ width: 45 }} 
            disabled={disabled} 
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            type="text" 
            className="input center" 
            onMouseOver={(e)=>hoverFunction2(e, "pos")}
          />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={1}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].emg`}
          activeField={activeField}
          wrapperStyles={{width: 25, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input style={{ width: 22 }} disabled={disabled} onFocus={(e) => handleFocus(e, setFieldTouched)} type="text" className="input center" />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={2}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 8 : 17, position: "relative", top: row - 1 === 0 ? 4 : 0, left: -6, background: "#fce8de" }}
          />
        </div>
        <OverlayTrigger trigger="click"
          placement="right"
          overlay={
            // <Popover id="popover-basic">
            //   <Popover.Title as="h3">CPT Code Definition</Popover.Title>
            //   <Popover.Content>
            //     {data.cptDescription && data.cptDescription > ''
            //       ? data.cptDescription
            //       : "No definition found."}
            //   </Popover.Content>
            // </Popover>
            <Tooltip>{'test'}</Tooltip>
          }
        >
          <BasicFieldWrapper
            name={`services[${index - 1}].cpt`}
            activeField={activeField}
            wrapperStyles={{width: 53, marginTop: 16}}
            isBlank={index > values.services.length}
          >            
            <input 
              style={{ width: 45 }} 
              disabled={true} 
              type="text" 
              onFocus={(e) => handleFocus(e, setFieldTouched)} 
              onMouseOver ={(e)=>hoverFunction2(e, "cpt", cptInfo)} 
              className="input center"
            /> 
          </BasicFieldWrapper>
        </OverlayTrigger>
      </DividedCell>
      <DividedCell colSpan={4}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: 17, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].modifier1`}
          activeField={activeField}
          validate={validateModifier}
          wrapperStyles={{width: 28, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            onMouseOver={(e)=>hoverFunction2(e, "mod")}
            maxLength={2}
            type="text"
            className="input center"
            style={{  width: 20 }}
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].modifier2`}
          activeField={activeField}
          validate={validateModifier}
          wrapperStyles={{width: 28, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            onMouseOver={(e)=>hoverFunction2(e, "mod")}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 20 }}
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].modifier3`}
          activeField={activeField}
          validate={validateModifier}
          wrapperStyles={{width: 28, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            onMouseOver={(e)=>hoverFunction2(e, "mod")}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 20 }}
            disabled={disabled}
          />
        </BasicFieldWrapper>
        <div className="verticalRule dotted" style={{ marginTop: 14, height: 16, position: "relative", top: 2 }} />
        <BasicFieldWrapper
          name={`services[${index - 1}].modifier4`}
          activeField={activeField}
          validate={validateModifier}
          wrapperStyles={{width: 28, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            onMouseOver={(e)=>hoverFunction2(e, "mod")}
            maxLength={2}
            type="text"
            className="input center"
            style={{ width: 20 }}
            disabled={disabled}
          />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={2}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper2
          name={original?`services[${index - 1}].pointerOrig`:`services[${index - 1}].pointer`}
          activeField={activeField}
          validate={validateDiagnosisPointer}
          wrapperStyles={{width: 53, marginTop: 16}}
          showErrorMessage={showErrorMessage}
          isBlank={index > values.services.length}
        >
          <input 
            style={{ width: 45 }} 
            disabled={!editenabled || values?.services?.[index -1]?.cpt?.length === 0} 
            onInput={(e) => upperCaseInput(e)} 
            onFocus={(e) => handleFocus(e, setFieldTouched)} 
            type="text" 
            className="input center" 
          />
        </BasicFieldWrapper2>
      </DividedCell>
      <DividedCell colSpan={2}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: 17, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].chargesDollars`}
          activeField={activeField}
          validate={validateCharge}
          wrapperStyles={ {width: 53, marginTop: 16 }}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            type="text"
            className="input text-end"
            style={{ width: 40 }}
            disabled={true}
          />
        </BasicFieldWrapper>
        <BasicFieldWrapper
          name={`services[${index - 1}].chargesCents`}
          activeField={activeField}
          // validate={validateCharge}
          wrapperStyles={ {width: 53, marginTop: 16 }}
          isBlank={index > values.services.length}
        >
          <input
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            type="text"
            className="input text-start"
            maxLength={2}
            style={{ width: 18 }}
            disabled={true}
          />
        </BasicFieldWrapper>
        <div className="relativePositioningPlaceholder">
          <div className="verticalRule dashed" style={{ position: "relative", left: -18, top: 14, height: 18 }} />
        </div>
      </DividedCell>
      <DividedCell colSpan={1}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].daysUnits`}
          activeField={activeField}
          wrapperStyles={{width: 22, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input style={{ width: 15 }} disabled={disabled} onFocus={(e) => handleFocus(e, setFieldTouched)} type="text" className="input center" />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={1}>
        <div className="relativePositioningPlaceholder">
          <div  
            style={{ width: 3, height: row - 1 === 0 ? 0 : 18, position: "relative", left: -6, background: "#fce8de" }}
          />
        </div>
        <BasicFieldWrapper
          name={`services[${index - 1}].epsdtFamilyPlan`}
          activeField={activeField}
          wrapperStyles={{width: 23, marginTop: 16}}
          isBlank={index > values.services.length}
        >
          <input style={{ width: 20 }} disabled={disabled} onFocus={(e) => handleFocus(e, setFieldTouched)} type="text" className="input center" />
        </BasicFieldWrapper>
      </DividedCell>
      <DividedCell colSpan={1} noPadding>
        <div className="colContainer spaceBetween inputContainer w-100">
          <BasicFieldWrapper
            name={`serviceLines[${index - 1}].idQual`} // Please fill this in with the appropriate field name, I don't know which name it should be
            activeField={activeField}
            wrapperStyles={{width: 30}}
            isBlank={index > values.services.length}
          >
            <input
              type="text"
              className="inputInline shadedBg ps-1 pe-1"
              style={{ width: 22 }}
              disabled={disabled}
              onFocus={(e) => handleFocus(e, setFieldTouched)}
            />
          </BasicFieldWrapper>
          <div className="hr" />
          <div className="center w-100 rowContainer">
            <label id="npiLabel" className="text label center w-100 pt-2">
              {"NPI".toUpperCase()}
            </label>  
          </div>
        </div>
      </DividedCell>
      <DividedCell colSpan={4} noPadding>
        <div className="colContainer spaceBetween inputContainer w-100">
          <div>
            <BasicFieldWrapper
              name={"services[" + (index - 1) + "].renderingProviderId"} // Please fill this in with the appropriate field name, I don't know which name it should be
              activeField={activeField}
              wrapperStyles={{width: 118}}
              isBlank={index > values.services.length}
            >
              <input
                type="text"
                className="inputInline center shadedBg"
                style={{ width: 105 }}
                disabled={disabled}
                onFocus={(e) => handleFocus(e, setFieldTouched)}
                onMouseOver={(e)=>hoverFunction2(e, "tc")}
              />
            </BasicFieldWrapper>
          </div>
          <div className="hr" />
          <div className="rowContainer">
            <BasicFieldWrapper
              name={"services[" + (index - 1) + "].renderingProviderNpi"}
              activeField={activeField}
              wrapperStyles={{width: 118}}
              isBlank={index > values.services.length}
            >
              <input
                type="text"
                    className="inputInline center"
                maxLength={10}
                disabled={disabled}
                onFocus={(e) => handleFocus(e, setFieldTouched)}
                style={{ width: 100 }}
              />
            </BasicFieldWrapper>
          </div>
        </div>
      </DividedCell>
    </tr>
  );
};

const tableWidth = 820;

const validatePlaceOfService = (value) => {
  let error;
 
  // if (value?.length > 0 && !placeOfServiceCodes.includes(value.toString())) {
  //   error = "Invalid place of service code";
  // }

  return error;
};

const validateModifier = (value) => {
  let error;

  // if (value?.length > 0 && !modifiers.includes(value.toString())) {
  //   error = "Invalid modifier";
  // }

  return error;
};

const validateDiagnosisPointer = (value) => {
  let error;

  let dupeCheck = isIsogram(value)
 
  if (value?.length > 4) {
    // error = "Only Accept Four Diagnosis Pointers";
    error = 'Diagnosis Pointer is Missing or Invalid'
  }

  if (value?.length > 0 && !/^[A-L]+$/.test(value)) {
    // error = "Only A thru L are valid.";
    error = 'Diagnosis Pointer is Missing or Invalid'
  }

  if (value?.length > 0 && !/^[A-Z]+$/.test(value)) {
    // error = "Does Not Accept Special Characters";
    error = 'Diagnosis Pointer is Missing or Invalid'
  }

  if(value?.length > 0 && dupeCheck === false) {
    // error = 'Diagnosis Pointer Cannot Be Repeated'
     error = 'Diagnosis Pointer is Missing or Invalid'
  }

  return error;
};

const isIsogram = (str) => {
  return !/(.).*\1/.test(str);
}

// const validateNpiWithIndex = (index, value) => {
//   let error;

//   // if (index === 0 && value?.length === 0) {
//   //   error = "E206 - National Provider Identifier Missing";
//   // } else {
//   //   error = validateNpi(value);
//   // }

//   return error;
// };

const validateNpi = (value) => {
  let error;
  return error;

  /*if (/\b(\d+)\1+\b/.test(value) || /(\d{4})\1\d/.test(value)) {
    // repeating value or sequence
    error = "E207 - National Provider Identifier Invalid Format";
  } else 
   if (value?.length > 0 && !/^\d+$/.test(value)) {
    //  special characters
     error = "E207 - National Provider Identifier Invalid Format";
   } else if (value?.length < 10 && value?.length > 0) {
     error = "E207 - National Provider Identifier Invalid Format";
   } else if (
     value !== undefined &&
     value !== null &&
     value.length > 0 &&
     !isValidNpi(value)
   ) {
    //  Luhn check
     error = "E207 - National Provider Identifier Invalid Format";
   } else if (!/^[0-9]+$/.test(value)) {
    //  only digits permitted
     error = "E207 - National Provider Identifier Invalid Format";
   }
   return error;*/
};

const validateCharge = (value) => {
  let error;

  // if (value?.length > 0 && !/^[0-9 ]+$/.test(value)) {
  //   error =
  //     "Only numbers or spaces are permitted";
  // }
  return error;
};

// const validateFederalTaxId = (value) => {
//   let error;

//   if (/\b(\d+)\1+\b/.test(value) || /(\d{4})\1\d/.test(value)) {
//     // repeating value or sequence
//     error =
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)";
//   } else if (value?.length > 0 && !/^\d+$/.test(value)) {
//     // special characters
//     error =
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)";
//   } else if (!/^[0-9]+$/.test(value)) {
//     // only digits permitted
//     error =
//       "N209 - Missing/Incomplete/Invalid Taxpayer Identification Number (TIN)";
//   }
//   return error;
// };

const OnFormChangeComponent = ({ isValid, isValidHandler, errors, values, isValidatingICDs }) => {
  console.log({ isValid, isValidHandler, errors, values, isValidatingICDs })
  if (isValidatingICDs) {
    // Validation api was still running, so set a validating error
    isValid = false;
    errors.validatingICDs = true;
  } else {
    // If validatingICDs key persists on the errors object, delete it
    if (errors.validatingICDs) delete errors.validatingICDs;
    // we need to set isValid back to true when validating is finished
    if(JSON.stringify(errors) === '{}') {
      isValid = true;
    }
  }
  isValidHandler(isValid, errors, values)
  // console.log("Errors: ", errors)
  // console.log("Values: ", values)
  return null
}

const splitOnDecimal = (value) => {
  return value.split(".");
}

const createDollarAndCentFields = (parentObject, fieldName) => {
  if(parentObject[fieldName] === 0) {
    parentObject[`${fieldName}Dollars`] = '0'
    parentObject[`${fieldName}Cents`] = '00'
  } else {
  if(parentObject[fieldName]){
    const currencyArray = splitOnDecimal(parentObject[fieldName].toFixed(2))
    parentObject[`${fieldName}Dollars`] = currencyArray[0]
    parentObject[`${fieldName}Cents`] = currencyArray[1]
  }
  else {
    parentObject[`${fieldName}Dollars`] = ''
    parentObject[`${fieldName}Cents`] = ''
  } 
}
}

const createDollarAndCentFieldsTotalCharge = (parentObject, fieldName, multiPage, currentPage, maxPage) => {
  // let serviceLines = parentObject.services
  // let totalCharge = 0;
  // for(let x= 0; x < serviceLines.length; x++) {
  //   let charges = serviceLines[x].charges
  //   // console.log('here is your service charge', charges)
  //   if(charges) {
  //     totalCharge = totalCharge + charges
  //   }
  // }
  // console.log('Here is your currency Array', currencyArray)
  // console.log('Here is your total charge', totalCharge)

  // if (multiPage && currentPage < maxPage) { 
  //     parentObject[`${fieldName}Dollars`] = ''
  //     parentObject[`${fieldName}Cents`] = ''
  // } else {
    if (parentObject[fieldName] === 0) {
      parentObject[`${fieldName}Dollars`] = '0'
      parentObject[`${fieldName}Cents`] = '00'
    } else {
      if (parentObject[fieldName]) {
        const currencyArray = splitOnDecimal(parentObject[fieldName].toFixed(2))
        // const currencyArray = splitOnDecimal(totalCharge.toFixed(2))
        parentObject[`${fieldName}Dollars`] = currencyArray[0]
        parentObject[`${fieldName}Cents`] = currencyArray[1]
      } else {
        parentObject[`${fieldName}Dollars`] = ''
        parentObject[`${fieldName}Cents`] = ''
      } 
    }
  // }
}

const CMSForm = (props) => {
  const [mode, setMode] = useState(props.mode||'locked');//"locked"
  const [activeField, setActiveField] = useState(null);
  const [isValidatingICDs, setIsValidatingICDs] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  // const [rejectionReasonDescription, setRejectionReasonDescription] =
  //   useState(null);
  // const { firstName, lastName, title } = useSelector((state) => state.user);
  // const { firstName, lastName, title } = {firstName:'Geo', lastName:'Martinez', title:'Dr.'}
  const { rejectionOptions, handleSubmitCms, original, disabled , editenabled, isValidHandler } = props;//adjustedClaim,
  const [showErrorMessage, setShowErrorMessage] = useState(props.showErrorMessage);
  const [disabledInputs, setDisableICDInputs] = useState(null);
  // const [icdCodeArr, setICDCodes] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // console.log(currentPage)
  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage])
  
  useEffect(() => {
    setShowErrorMessage(props.showErrorMessage)
    }, [props.showErrorMessage]);
    // console.log(showErrorMessage)
    // console.log(props.showErrorMessage)

  let preClaim={...props.adjustedClaim}
  // console.log(preClaim)
  //console.log(preClaim.member.phone.replace('(','').replace(')','').replace('-','').substring(3))
  // console.log(preClaim.member.phone)

  //if(preClaim.dos) {preClaim.dos = moment(preClaim.dos).format('YYYY-MM-DD')} else {preClaim.dos = ''}
  //preClaim.hpName= preClaim.hpName //`UnitedHealthcare Community Plan`
  //preClaim.hpAddress= preClaim.hpAddress //`P.O. Box 5240`
  preClaim.hpAddress2=null
  preClaim.hpCityStateZip=  preClaim.hpCity + ", " + preClaim.hpState + " " + preClaim.hpZip         //`Kingston, New York 12402-5240`

  let membername1 = ''
  if(preClaim.member.firstName && preClaim.member.lastName && preClaim.member.middleName){membername1 = preClaim.member.lastName + ", " + preClaim.member.firstName + ", " + preClaim.member.middleName}
  if(preClaim.member.firstName && !preClaim.member.lastName && !preClaim.member.middleName){membername1 = preClaim.member.firstName}
  if(!preClaim.member.firstName && preClaim.member.lastName && !preClaim.member.middleName){membername1 = preClaim.member.lastName}
  if(!preClaim.member.firstName && !preClaim.member.lastName && preClaim.member.middleName){membername1 = preClaim.member.middleName}

  if(preClaim.member.firstName && preClaim.member.lastName && !preClaim.member.middleName){membername1 = preClaim.member.lastName + ", " + preClaim.member.firstName}
  if(!preClaim.member.firstName && preClaim.member.lastName && preClaim.member.middleName){membername1 = preClaim.member.lastName + ", " + preClaim.member.middleName}
  if(preClaim.member.firstName && !preClaim.member.lastName && preClaim.member.middleName){membername1 = preClaim.member.firstName + ", " + preClaim.member.middleName}
  
  let patientname1 = ''
  if(preClaim.patient.firstName && preClaim.patient.lastName && preClaim.patient.middleName){patientname1 = preClaim.patient.lastName + ", " + preClaim.patient.firstName + ", " + preClaim.patient.middleName}
  if(preClaim.patient.firstName && !preClaim.patient.lastName && !preClaim.patient.middleName){patientname1 = preClaim.patient.firstName}
  if(!preClaim.patient.firstName && preClaim.patient.lastName && !preClaim.patient.middleName){patientname1 = preClaim.patient.lastName}
  if(!preClaim.patient.firstName && !preClaim.patient.lastName && preClaim.patient.middleName){patientname1 = preClaim.patient.middleName}

  if(preClaim.patient.firstName && preClaim.patient.lastName && !preClaim.patient.middleName){patientname1 = preClaim.patient.lastName + ", " + preClaim.patient.firstName}
  if(!preClaim.patient.firstName && preClaim.patient.lastName && preClaim.patient.middleName){patientname1 = preClaim.patient.lastName + ", " + preClaim.patient.middleName}
  if(preClaim.patient.firstName && !preClaim.patient.lastName && preClaim.patient.middleName){patientname1 = preClaim.patient.firstName + ", " + preClaim.patient.middleName}

 /* if (preClaim.relationshipToInsured)
  {
      if (preClaim.relationshipToInsured.toUpperCase() === 'SELF')
      {
        
        preClaim.member_name= membername1                
      }
      else
      {
        preClaim.member_name= patientname1  
      }
  }
  else
  {
    preClaim.member_name= membername1
  }*/
  if(patientname1===null){patientname1=''}
  preClaim.member_name= patientname1.toUpperCase()
  
  if(membername1===null){membername1=''}
  preClaim.insured_name= membername1.toUpperCase()
  

  if(preClaim.patient.phone) {
    preClaim.member_phone=preClaim.patient.phone.replace('(','').replace(')','').replace('-','').substring(3)
  }
  else {
    preClaim.member_phone=''
  }
  if(preClaim.patient.phone) {
    preClaim.member_areaCode=preClaim.patient.phone.replace('(','').replace(')','').replace('-','').substring(0,3)
  }
  else {
    preClaim.member_areaCode=''
  }

  if(preClaim.member.phone) {
    preClaim.insured_phone=preClaim.member.phone.replace('(','').replace(')','').replace('-','').substring(3)
  }
  else {
    preClaim.insured_phone=''
  }
  if(preClaim.member.phone) {
    preClaim.insured_areaCode=preClaim.member.phone.replace('(','').replace(')','').replace('-','').substring(0,3)
  }
  else {
    preClaim.insured_areaCode=''
  }

  //preClaim.illnessInjuryPregnancyDate = moment(preClaim.illnessInjuryPregnancyDate).format('YYYY-MM-DD')
  if(preClaim.illnessInjuryPregnancyDate){preClaim.illnessInjuryPregnancyDateMM = moment(preClaim.illnessInjuryPregnancyDate).format('MM')}else{preClaim.illnessInjuryPregnancyDateMM=''}
  if(preClaim.illnessInjuryPregnancyDate){preClaim.illnessInjuryPregnancyDateDD = moment(preClaim.illnessInjuryPregnancyDate).format('DD')}else{preClaim.illnessInjuryPregnancyDateDD=''}
  if(preClaim.illnessInjuryPregnancyDate){preClaim.illnessInjuryPregnancyDateYY = moment(preClaim.illnessInjuryPregnancyDate).format('YY')}else{preClaim.illnessInjuryPregnancyDateYY =''}
  // preClaim.serviceProviderName=preClaim.serviceProviderName.toUpperCase()
  // preClaim.serviceProviderAddress=preClaim.serviceProviderAddress.toUpperCase()
  // preClaim.serviceProviderCity=preClaim.serviceProviderCity.toUpperCase()
  // preClaim.billingProviderName=preClaim.billingProviderName.toUpperCase()
  // preClaim.billingProviderAddress=preClaim.billingProviderAddress.toUpperCase()
  // preClaim.billingProviderCity=preClaim.billingProviderCity.toUpperCase()
  if (preClaim.serviceProviderName) {
  preClaim.serviceProviderName=preClaim.serviceProviderName.toUpperCase()}
  if (preClaim.serviceProviderAddress){
  preClaim.serviceProviderAddress=preClaim.serviceProviderAddress.toUpperCase()}
  if (preClaim.serviceProviderCity){
  preClaim.serviceProviderCity= preClaim.serviceProviderCity.toUpperCase()}
  if(preClaim.serviceProviderState){
  preClaim.serviceProviderState= preClaim.serviceProviderState.toUpperCase()}
  //if(preClaim.serviceProviderZip){
  //preClaim.serviceProviderZip= preClaim.serviceProviderZip}
  //if(preClaim.serviceProviderNpi){
  //preClaim.serviceProviderNpi= preClaim.serviceProviderNpi}
  //if(preClaim.serviceProviderOtherId){
  //preClaim.serviceProviderOtherId=preClaim.serviceProviderOtherId}
  if(preClaim.billingProviderName){
  preClaim.billingProviderName=preClaim.billingProviderName.toUpperCase()}
  if(preClaim.billingProviderAddress){
  preClaim.billingProviderAddress=preClaim.billingProviderAddress.toUpperCase()}
  if(preClaim.billingProviderCity){
  preClaim.billingProviderCity=preClaim.billingProviderCity.toUpperCase()}
  if(preClaim.billingProviderState){
  preClaim.billingProviderState= preClaim.billingProviderState.toUpperCase()}
  //preClaim.billingProviderZip= preClaim.billingProviderZip
  if(preClaim.billingProviderOtherId){
  preClaim.billingProviderOtherId=preClaim.billingProviderOtherId.toUpperCase()}
  if(preClaim.billingProviderNpi){
  preClaim.billingProviderNpi= preClaim.billingProviderNpi.toUpperCase()}
 
  //if(preClaim.billingProviderPhone && !(preClaim.billingProviderPhone==="") && !(!_.isEmpty(preClaim.billingProviderPhone)))
  if(preClaim.billingProviderPhone)
  {
  preClaim.billingProviderAreaCode=preClaim.billingProviderPhone.substring(0,3)
  preClaim.billingProviderPhone=preClaim.billingProviderPhone.substring(3)
  }
  if(preClaim.billingProviderPhone===null) {preClaim.billingProviderAreaCode=''}


  if(preClaim.hospitalizationFromDate){preClaim.hospitalizationFromDateMM = moment(preClaim.hospitalizationFromDate).format('MM')}else{preClaim.hospitalizationFromDateMM=''}
  if(preClaim.hospitalizationFromDate){preClaim.hospitalizationFromDateDD = moment(preClaim.hospitalizationFromDate).format('DD')}else{preClaim.hospitalizationFromDateDD=''}
  if(preClaim.hospitalizationFromDate){preClaim.hospitalizationFromDateYY = moment(preClaim.hospitalizationFromDate).format('YY')}else{preClaim.hospitalizationFromDateYY=''}

  if(preClaim.hospitalizationToDate){preClaim.hospitalizationToDateMM = moment(preClaim.hospitalizationToDate).format('MM')}else{preClaim.hospitalizationToDateMM=''}
  if(preClaim.hospitalizationToDate){preClaim.hospitalizationToDateDD = moment(preClaim.hospitalizationToDate).format('DD')}else{preClaim.hospitalizationToDateDD=''}
  if(preClaim.hospitalizationToDate){preClaim.hospitalizationToDateYY = moment(preClaim.hospitalizationToDate).format('YY')}else{preClaim.hospitalizationToDateYY=''}

  if(preClaim.unableToWorkFromDate){preClaim.unableToWorkFromDateMM = moment(preClaim.unableToWorkFromDate).format('MM')}else{preClaim.unableToWorkFromDateMM=''}
  if(preClaim.unableToWorkFromDate){preClaim.unableToWorkFromDateDD = moment(preClaim.unableToWorkFromDate).format('DD')}else{preClaim.unableToWorkFromDateDD=''}
  if(preClaim.unableToWorkFromDate){preClaim.unableToWorkFromDateYY = moment(preClaim.unableToWorkFromDate).format('YY')}else{preClaim.unableToWorkFromDateYY=''}

  if(preClaim.unableToWorkToDate){preClaim.unableToWorkToDateMM = moment(preClaim.unableToWorkToDate).format('MM')}else{preClaim.unableToWorkToDateMM=''}
  if(preClaim.unableToWorkToDate){preClaim.unableToWorkToDateDD = moment(preClaim.unableToWorkToDate).format('DD')}else{preClaim.unableToWorkToDateDD=''}
  if(preClaim.unableToWorkToDate){preClaim.unableToWorkToDateYY = moment(preClaim.unableToWorkToDate).format('YY')}else{preClaim.unableToWorkToDateYY=''}

  if(preClaim.otherDate){preClaim.otherDateMM = moment(preClaim.otherDate).format('MM')}else{preClaim.otherDateMM=''}
  if(preClaim.otherDate){preClaim.otherDateDD = moment(preClaim.otherDate).format('DD')}else{preClaim.otherDateDD=''}
  if(preClaim.otherDate){preClaim.otherDateYY = moment(preClaim.otherDate).format('YY')}else{preClaim.otherDateYY=''}

  if(preClaim.patient.address===null) {preClaim.patient.address=''}
  preClaim.member_address= preClaim.patient.address.toUpperCase()

  if(preClaim.patient.city===null) {preClaim.patient.city=''}
  preClaim.member_city=preClaim.patient.city.toUpperCase()

  if(preClaim.patient.state===null) {preClaim.patient.state=''}
  preClaim.member_state=preClaim.patient.state.toUpperCase()
  preClaim.member_zip=preClaim.patient.zip

  if(preClaim.member.address===null) {preClaim.member.address=''}
  preClaim.insured_address= preClaim.member.address.toUpperCase()

  if(preClaim.member.city===null) {preClaim.member.city=''}
  preClaim.insured_city=preClaim.member.city.toUpperCase()

  if(preClaim.member.state===null) {preClaim.member.state=''}
  preClaim.insured_state=preClaim.member.state.toUpperCase()
  preClaim.insured_zip=preClaim.member.zip


  /*if (preClaim.relationshipToInsured)
  {
  if (preClaim.relationshipToInsured.toUpperCase() === 'SELF')
    {preClaim.member_dob=preClaim.member.dob}
  else
    {preClaim.member_dob=preClaim.patient.dob}
 }
 else
 {
  preClaim.member_dob=preClaim.member.dob
 }*/


  if(preClaim.patient.dob){preClaim.member_dobMM = moment(preClaim.patient.dob).format('MM')} else{preClaim.member_dobMM=''}
  if(preClaim.patient.dob){preClaim.member_dobDD = moment(preClaim.patient.dob).format('DD')}else{preClaim.member_dobDD=''}
  if(preClaim.patient.dob){preClaim.member_dobYY = moment(preClaim.patient.dob).format('YY')}else{preClaim.member_dobYY=''}

  if(preClaim.member.dob){preClaim.insured_dobMM = moment(preClaim.member.dob).format('MM')} else{preClaim.insured_dobMM=''}
  if(preClaim.member.dob){preClaim.insured_dobDD = moment(preClaim.member.dob).format('DD')}else{preClaim.insured_dobDD=''}
  if(preClaim.member.dob){preClaim.insured_dobYY = moment(preClaim.member.dob).format('YY')}else{preClaim.insured_dobYY=''}

  if(preClaim.patient.gender===null) {preClaim.patient.gender=''}
  preClaim.member_gender=preClaim.patient.gender.toUpperCase()
  if(preClaim.member.gender===null) {preClaim.member.gender=''}
  preClaim.insured_gender=preClaim.member.gender.toUpperCase()
 /* if (preClaim.member.address) {  //(preClaim.mbr_address) {
    /*let memberAddress=preClaim.member.address.split(',')  //preClaim.mbr_address.split(',')
    let memberStateZip=memberAddress[memberAddress.length-1].trim().split('  ')
    //preClaim.member_address=memberAddress[0].trim() ||`address post transform`
    preClaim.member_city=memberAddress[1].trim() ||`city post transform`
    preClaim.member_state=memberStateZip[0].trim() ||`state post transform`
    preClaim.member_zip=memberStateZip[memberStateZip.length-1].trim() ||`zip post transform`*/
   

  //} else {
  //  preClaim.member_address=`address post transform`
    //preClaim.member_city=`member city`
    //preClaim.member_state=`state post transform`
    //preClaim.member_zip=`zip post transform`
  //}*/
  
  // preClaim.insured_signature=`SIGNATURE ON FILE`
  // preClaim.member_signature=`SIGNATURE ON FILE`
  preClaim.insured_signature=(preClaim.member.signature?preClaim.member.signature:'').toUpperCase()
  preClaim.member_signature=(preClaim.patient.signature?preClaim.patient.signature:'').toUpperCase()
  if(preClaim.dos) {preClaim.dos = moment(preClaim.dos).format('YYYY-MM-DD')} else {preClaim.dos = ''}
  if(preClaim.patient.signatureDate) {preClaim.member_signature_date = moment(preClaim.patient.signatureDate).format('YYYY-MM-DD')} else {preClaim.member_signature_date = ''}
  preClaim.insuranceType=  `Group Health Plan` //preClaim.healthPlan  //
  preClaim.referringProvider=(preClaim.referringProvider?preClaim.referringProvider:'').toUpperCase()
  //preClaim.physicianSignature=preClaim.provider.firstname + ' ' + preClaim.provider.lastname  -- physicianSignature default blank
  preClaim.physicianSignature=(preClaim.physicianSignature?preClaim.physicianSignature:'').toUpperCase()
  preClaim.physicianSignatureDate=preClaim.physicianSignatureDate? moment(new Date(preClaim.physicianSignatureDate)).format('MM/DD/YYYY hh:mm:ss A z'):''
  preClaim.taxIdType=`EIN`
  //preClaim.employment=preClaim.employment?preClaim.employment:'NO'
  if (preClaim.employment){
    if(preClaim.employment===true){
      preClaim.employment='Yes'
    } else {preClaim.employment='No'}
  } else {preClaim.employment='No'}

  switch (preClaim.relationshipToInsured) {
    case '01':
      preClaim.patientRelationship = 'self'
      break;
    case '02':
      preClaim.patientRelationship ='spouse'
      break;
    case '03':
      preClaim.patientRelationship = 'child'
      break;
  
    default:
      preClaim.patientRelationship = 'self'
      break;
  }
  //preClaim.autoAccident=preClaim.autoAccident?preClaim.autoAccident:'false'
  if (preClaim.autoAccident){
    if(preClaim.autoAccident===true){
      preClaim.autoAccident='Yes'
    } else {preClaim.autoAccident='No'}
  } else {preClaim.autoAccident='No'}

  //preClaim.otherAccident=preClaim.otherAccident?preClaim.otherAccident:'NO'
  if (preClaim.otherAccident){
    if(preClaim.otherAccident===true){
      preClaim.otherAccident='Yes'
    } else {preClaim.otherAccident='No'}
  } else {preClaim.otherAccident='No'}

  //preClaim.outsideLab=preClaim.outsideLab?preClaim.outsideLab:'false'
  if (preClaim.outsideLab){
    if(preClaim.outsideLab===true){
      preClaim.outsideLab='Yes'
    } else {preClaim.outsideLab='No'}
  } else {preClaim.outsideLab='No'}
  createDollarAndCentFields(preClaim, 'outsideLabCharges')

  //preClaim.acceptAssignment=preClaim.acceptAssignment?preClaim.acceptAssignment:'NO'
  if (preClaim.acceptAssignment){
    if(preClaim.acceptAssignment===true){
      preClaim.acceptAssignment='Yes'
    } else {preClaim.acceptAssignment='No'}
  } else {preClaim.acceptAssignment='No'}

  // preClaim.billingProviderPhone=preClaim.billingProviderPhone?preClaim.billingProviderPhone.substring(3):null
  // preClaim.billingProviderAreaCode=preClaim.billingProviderPhone?preClaim.billingProviderPhone.substring(0,3):null
  preClaim.services=[...preClaim.serviceLines]
  preClaim.services.forEach(row => {
    if(row.dosFrom){
      row.dosFromMM = moment(row.dosFrom).format('MM')
      row.dosFromDD = moment(row.dosFrom).format('DD')
      row.dosFromYY = moment(row.dosFrom).format('YY')
    }
    else {
      row.dosFromMM = ''
      row.dosFromDD = ''
      row.dosFromYY = ''
    }
    if(row.dosTo){
      row.dosToMM = moment(row.dosTo).format('MM')
      row.dosToDD = moment(row.dosTo).format('DD')
      row.dosToYY = moment(row.dosTo).format('YY')
    }
    else {
      row.dosToMM = ''
      row.dosToDD = ''
      row.dosToYY = ''
    }
    createDollarAndCentFields(row, 'charges')
  })


  preClaim.anotherHealthBenefitPlan=preClaim.anotherHealthBenefitPlan?preClaim.anotherHealthBenefitPlan:'NO'

  if (preClaim.anotherHealthBenefitPlan){
    if(preClaim.anotherHealthBenefitPlan===true){
      preClaim.anotherHealthBenefitPlan='Yes'
    } else {preClaim.anotherHealthBenefitPlan='No'}
  } else {preClaim.anotherHealthBenefitPlan='No'}

  // console.log(preClaim)
  // console.log(original)
  while(preClaim.serviceLines.length<6){
    preClaim.serviceLines.push({charges:'',cpt:'',daysUnits:'',dosFrom:'',dosFromDD:'',dosFromMM:'',dosFromYY:''
    ,dosTo:'',dosToDD:'',dosToMM:'',dosToYY:'',emg:'',epsdtFamilyPlan:'',idQual:'',modifier1:'',modifier2:'',modifier3:'',modifier4:''
    ,placeOfService:'',pointer:'',renderingProviderId:'',renderingProviderNpi:''})
  }
  //if(original){
  //   preClaim.services.forEach(row => {
  //        row.pointerO = ''       
  // })
    
 // }
  if(original) {
    while(preClaim.icdStds.length<12){
      preClaim.icdStds.push('')
      // console.log('inside2')
    }
  }
  else {
    while(preClaim.icdCodes.length<12){
      preClaim.icdCodes.push('')
    }  
  }
  // console.log(preClaim)
  const multiPage = preClaim.serviceLines ? preClaim.serviceLines.length > 5 ? true: false : false
  const serviceLineCount = preClaim.serviceLines ? preClaim.serviceLines.length : 1;
  const maxPage = Math.ceil(serviceLineCount / 6)
  createDollarAndCentFieldsTotalCharge(preClaim, 'totalCharge', multiPage, currentPage, maxPage)
  createDollarAndCentFields(preClaim, 'amountPaid')


  for (const prop in preClaim) {
    //console.log(`obj.${prop} = ${preClaim[prop]}`)
    if (preClaim[prop]===null) {preClaim[prop]=''}
  }

 
  for (let i=0; i<preClaim.serviceLines.length; i++) {
    for (const prop in preClaim.serviceLines[i]) {
      //console.log(`${prop} = ${preClaim.serviceLines[i][prop]}`)
      if (preClaim.serviceLines[i][prop]===null) {preClaim.serviceLines[i][prop]=''}
    }
  }


    for (const prop in preClaim.member) {
      //console.log(`${prop} = ${preClaim.member[prop]}`)
      if (preClaim.member[prop]===null) {preClaim.member[prop]=''}
    }

    for (const prop in preClaim.patient) {
      //console.log(`${prop} = ${preClaim.patient[prop]}`)
      if (preClaim.patient[prop]===null) {preClaim.patient[prop]=''}
    }

  preClaim.member.otherName=preClaim.member.otherName?preClaim.member.otherName.toUpperCase():''
  preClaim.member.otherPolicyGroup=preClaim.member.otherPolicyGroup?preClaim.member.otherPolicyGroup.toUpperCase():''
  preClaim.member.otherInsurancePlan=preClaim.member.otherInsurancePlan?preClaim.member.otherInsurancePlan.toUpperCase():''
  preClaim.member.policyGroup=preClaim.member.policyGroup?preClaim.member.policyGroup.toUpperCase():''
  preClaim.member.insurancePlan=preClaim.member.insurancePlan?preClaim.member.insurancePlan.toUpperCase():''
  preClaim.formInd=preClaim.formInd?preClaim.formInd.toUpperCase():''

  //When we have multi page claims, the last page should only display the total charge
  //We will display continued instead of blank value
  preClaim.totalChargeContinued = 'CONTINU'
  preClaim.totalCentsContinued = 'ED'

  let adjustedClaim={...preClaim} //reassign
  // console.log('CMSForm.AdjClm')
  // console.log(adjustedClaim)
  // useEffect(() => {
  //   console.log("refresh"); // refresh component when new claim drops
  
  //   // console.log(props)


  // }, [adjustedClaim]);

  const handleFocus = (e, setFieldTouched) => {
    setActiveField(e.target.name);
    if (setFieldTouched !== undefined) 
      setFieldTouched(e.target.name)
  };

  const objectHasNonEmptyProperty = (object) => {
    var isNonEmpty = false
    for (const property in object) {
      if (object[property]?.length > 0) {
        isNonEmpty = true
      }
    }
    return isNonEmpty
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // todo move to utils

  // const nppesMatch = async (npi, signature) => {
  //   let match;
  //   try {
  //     await fetch(
  //       `https://npiregistry.cms.hhs.gov/api/?number=${npi}&enumeration_type=&taxonomy_description=&first_name=&use_first_name_alias=&last_name=&organization_name=&address_purpose=&city=&state=&postal_code=&country_code=&limit=&skip=&version=2.1`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (
  //           signature
  //             .toUpperCase()
  //             .includes(data?.results?.[0]?.basic?.first_name?.toUpperCase()) &&
  //           signature
  //             .toUpperCase()
  //             .includes(data?.results?.[0]?.basic?.last_name?.toUpperCase())
  //         )
  //           match = true;
  //         else match = false;
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   return match;
  // };

  // const getDiagsChar = (index) => {
  //   let abcs = ['A','B','C','D','E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
  //   return abcs[index]
  // }

  const validateCms = async (values) => {
    var errors = {};

    // Validate all the ICD Diagnosis codes
    errors = await validateAllICDs(values,errors)

    console.log("Errors: ", errors)
    console.log("values: ", values)
    // Check that physician signature and rendering provider NPI match
    // const validationError = validateNpiWithIndex(
    //   0,
    //   values?.services?.[0]?.renderingProviderNpi
    // );
    // let isMatch;

    // if (!validationError) {
    //   isMatch = await nppesMatch(
    //     values?.services?.[0]?.renderingProviderNpi,
    //     values?.physicianSignature
    //   );
    // } else {
    //   errors.services[0].renderingProviderNpi = validationError;
    // }
    // if (isMatch === false) {
    //   errors.services[0].renderingProviderNpi = "Signature does not match";
    // }

    // Check that state is provided for auto accident
    if (
      values?.autoAccident === true 
      // ||
      // values?.autoAccident.toLowerCase() === "yes"
      //comment out above 2 lines to prevent the app break. 
    )
    if (values?.autoAccidentState.length < 2)
      errors.autoAccidentState = "Required";

    if (values?.services.length > 0)
      values.services.forEach((service, index) => {
        if (objectHasNonEmptyProperty(service) ) {
          if (service?.pointer) {
            const pointerError = validateDiagnosisPointer(service.pointer)
            if (pointerError === undefined) {
              const pointerArray = [...service.pointer].map(a => parseInt(a, 36) - 10).filter(a => a >= 0)
              pointerArray.forEach(pointer => {
                if (original) {
                  if (values?.icdStds?.[pointer] === undefined || values?.icdStds?.[pointer].length === 0) {
                    if (errors.services === undefined)
                      errors.services = []
                    if (errors.services[index] === undefined)
                      errors.services[index] = {};
                    // errors.services[index].pointer = "Diagnosis Pointer can not point to empty DX Field in 21"
                    errors.services[index].pointer = "Diagnosis Pointer is Missing or Invalid"
                  }
                }
                else
                  if (values?.icdCodes?.[pointer] === undefined || values?.icdCodes?.[pointer].length === 0) {
                    if (errors.services === undefined)
                      errors.services = []
                    if (errors.services[index] === undefined)
                      errors.services[index] = {};
                    // errors.services[index].pointer = "Diagnosis Pointer can not point to empty DX Field in 21"
                    errors.services[index].pointer = "Diagnosis Pointer is Missing or Invalid"
                  }
              })
            }
            else {
              if (errors.services === undefined)
                errors.services = []
              if (errors.services[index] === undefined)
                errors.services[index] = {};
              errors.services[index].pointer = pointerError
            }
          }
          else {
            if (errors.services === undefined)
              errors.services = []
            if (errors.services[index] === undefined)
              errors.services[index] = {};
            errors.services[index].pointer = "Required"
          }
          // const chargeError = validateCharge(service.chargesDollars)
          //   if (chargeError === undefined) {
          //         if (!service?.chargesDollars) {
          //           if (errors.services === undefined)
          //           errors.services = []
          //         if (errors.services[index] === undefined)
          //           errors.services[index] = {};
          //           errors.services[index].chargesDollars = "Required"
          //         }
          //   }
          //   else {
          //   if (errors.services === undefined)
          //     errors.services = []
          //   if (errors.services[index] === undefined)
          //     errors.services[index] = {};
          //     errors.services[index].chargesDollars = chargeError
          //   }

          let charges = service.charges === 0 ? true : !service.charges ? false : true

          if (!service.dosFromDD) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosFromDD = "Required"}
          if (!service.dosFromMM) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosFromMM = "Required"}
          if (!service.dosFromYY) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosFromYY = "Required"}
          if (!service.dosToDD) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosToDD = "Required"}
          if (!service.dosToMM) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosToMM = "Required"}
          if (!service.dosToYY) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].dosToYY = "Required"}
          if (!service.placeOfService) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].placeOfService = "Required"}
          if (!service.cpt) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].cpt = "Required"}
          if (charges === false) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].charges = "Required"}
          if (!service.daysUnits) { if (errors.services === undefined)
            errors.services = []
          if (errors.services[index] === undefined)
            errors.services[index] = {};
          errors.services[index].daysUnits = "Required"}
        }
      })

    return errors;
  };

  const validateAllICDs = async (values,errors) => {
    //console.log('Vic: Current values:')
    // console.log(values.icdCodes)
    //console.log('Vic: Old adjustedClaim.icdAllInfo:')
    // console.log(adjustedClaim.icdAllInfo)
    for (let i=adjustedClaim.icdAllInfo.length; i<12; i++) {
      //adjustedClaim.icdAllInfo.push({"isValid":true})
    }
    // console.log(adjustedClaim.icdAllInfo)

    // For each new ICD Diagnosis Code, validate it:
    //let newICD=0,newCode=''
    var tempErrors = errors
    if(original) {
      for (let i=0; i<values.icdStds.length; i++) {
        tempErrors = await checkIfNewICDDiag(values?.icdStds[i],i,errors, null, null)
      }
    }
    else {

      // for (let i=0; i<11; i++)
      // {
      //   if (replacer(values?.icdCodes[i]) === '' && replacer(values?.icdCodes[i+1]) != '' )
      //       {    
      //         errors.icdCodes = []
      //         errors.icdCodes[i]='Gaps found'
      //         return errors
      //       }
      // }

      // for (let i=0; i<11; i++)
      //   {
      //       if (repeatedICDs(values?.icdCodes[i], values?.icdCodes) > 1 && replacer(values?.icdCodes[i]) != '' && lastIndex(values?.icdCodes[i], values?.icdCodes) === i)
      //         {
      //           errors.icdCodes = []
      //           errors.icdCodes[i]='Repeated Diagnosis Code'
      //           return errors
      //         }
      //   }

      for (let i=0; i<values.icdCodes.length; i++) {
        tempErrors = await checkIfNewICDDiag(values?.icdCodes[i],i,errors, values?.icdCodes, values?.icdStds)
      }
    }

       return tempErrors
  }

  const replacer = (val)=>{
    if ( val === null ) 
    { 
       return "" // change null to empty string
    } else {
       return val // return unchanged
    }
   }

   const replacerV2 = (val, arr)=>{
    if(arr) {
    let index = val
    for(let x = index; x < 12; x++) {
      if(arr[x] !== '') {
        return true
      }
    }
  }
    return false
   }

   const repeatedICDs = (val, arr)=>{
    // console.log(val)
    // console.log(arr)
    let count = 0;
    arr.forEach(icd => {
      if(icd === val) {
        count += 1
      }
    })
    return count
   }

   const lastIndex = (val, arr)=>{
    return arr.indexOf(val)
   }

  const checkIfNewICDDiag = async (ICDcode,i,errors,arr,icdStds)=>{
    // console.log(i)
    // console.log(ICDcode)
    if(ICDcode===null || ICDcode==='') {
      ICDcode = ICDcode.replace(/ /g,'')
      // console.log('here')
      if(adjustedClaim.icdAllInfo && adjustedClaim.icdAllInfo[i]) {
        // console.log('there')
        //adjustedClaim.icdAllInfo[i].diag_cd=''
        adjustedClaim.icdAllInfo[i].short_desc=''
        adjustedClaim.icdAllInfo[i].long_desc=''
      }
      return errors}
    // console.log('Code:'+ICDcode+', i:'+i.toString())
    let found=0
    for (let j = 0; j < adjustedClaim.icdAllInfo.length; j++) {
      // console.log("with:"+adjustedClaim.icdAllInfo[j].diag_cd)
      if(ICDcode===adjustedClaim.icdAllInfo[j].diag_cd) {
        found=1
        // console.log('Found!')
        break
      }
    }
    if(found===0){
      //console.log('Vic3:'+ICDcode+' sub:'+i.toString()+' is new, let us validate it')
      let icdInd = 'ICD10'
      if (adjustedClaim.icdIndicator === '0') {icdInd = 'ICD10'} else {icdInd = 'ICD9'}
      let resj = await validateICDs(icdInd,ICDcode,adjustedClaim.serviceLines[0].dosFrom)
      // console.log('back from api')
      if (resj.rows && resj.rows.length>0) { 
        // console.log(adjustedClaim.serviceLines[0].dosFrom)
        // console.log(resj.rows[0].eff_dttm)
        if(adjustedClaim.serviceLines[0].dosFrom < resj.rows[0].eff_dttm) {
          // console.log('Not yet effective ICD:'+ICDcode+' sub:'+i.toString())
          //if(adjustedClaim.icdAllInfo[i]) //s && adjustedClaim.icdAllInfo[i].short_desc)
          //{
            // console.log('Code not yet effective until '+resj.rows[0].eff_dttm.substr(0,10))
            adjustedClaim.icdAllInfo[i].short_desc='Code not yet effective until '+resj.rows[0].eff_dttm.substr(0,10)
            adjustedClaim.icdAllInfo[i].long_desc=''
            adjustedClaim.icdAllInfo[i].diag_cd='.'
            adjustedClaim.icdAllInfo[i].isValid=false
            if(original) {
              if (errors.icdStds === undefined)
              errors.icdStds = []
              errors.icdStds[i]='Code not yet effective until '+resj.rows[0].eff_dttm.substr(0,10)
            }
            else {
              if (errors.icdCodes === undefined)
              errors.icdCodes = []
              errors.icdCodes[i]='Code not yet effective until '+resj.rows[0].eff_dttm.substr(0,10)  
            }
          //}
        }
        else {
          if(adjustedClaim.serviceLines[0].dosFrom > resj.rows[0].exp_dttm) {
            // console.log('Expired ICD:'+ICDcode+' sub:'+i.toString())
            //if(!adjustedClaim.icdAllInfo[i]) // && adjustedClaim.icdAllInfo[i].short_desc)
            //{
              console.log('Code was expired on '+resj.rows[0].exp_dttm.substr(0,10))
              adjustedClaim.icdAllInfo[i].short_desc='Code was expired on '+resj.rows[0].exp_dttm.substr(0,10)
              adjustedClaim.icdAllInfo[i].long_desc=''
              adjustedClaim.icdAllInfo[i].diag_cd='.'
              adjustedClaim.icdAllInfo[i].isValid=false
              if(original) {
                if (errors.icdStds === undefined)
                errors.icdStds = []
                errors.icdStds[i]='Code was expired on '+resj.rows[0].exp_dttm.substr(0,10)  
              }
              else {
                if (errors.icdCodes === undefined)
                errors.icdCodes = []
                errors.icdCodes[i]='Code was expired on '+resj.rows[0].exp_dttm.substr(0,10)
              }

            //}
          }
          else {
            if(ICDcode === resj.rows[0].orig_diag_cd && ICDcode.includes('.')) {
            if(original) {
              if (errors.icdStds === undefined)
              errors.icdStds = []
              errors.icdStds[i]='TR3: Do not transmit the decimal point for ICD Codes.'
            }
            else {
              if (errors.icdCodes === undefined)
              errors.icdCodes = []
              errors.icdCodes[i]='TR3: Do not transmit the decimal point for ICD Codes.'
            }
          } else {
            adjustedClaim.icdAllInfo.push(resj.rows[0])
          }
          }
        }
      }
      else
      {
          // console.log('Invalid ICD:'+ICDcode+' sub:'+i.toString())
          // console.log(errors)
          if(adjustedClaim.icdAllInfo[i] && adjustedClaim.icdAllInfo[i].short_desc)
          {
            adjustedClaim.icdAllInfo[i].short_desc=''
            adjustedClaim.icdAllInfo[i].long_desc=''
            adjustedClaim.icdAllInfo[i].diag_cd='.'
            adjustedClaim.icdAllInfo[i].isValid=false
          }
          //dialog.showMessageBoxSync({type:"info",buttons:["OK"],message:rc})
          if(original) {
            if (errors.icdStds === undefined)
            errors.icdStds = []
            errors.icdStds[i]='Invalid Diagnosis Code'
          }
          else {
            if (errors.icdCodes === undefined)
            errors.icdCodes = []
            errors.icdCodes[i]='Invalid Diagnosis Code'
          }
          // console.log('VIC errors:')
          // console.log(errors)
      }
    }
    if(arr) {
      arr.forEach((element, index) => {
        arr[index] = element.replace(/ /g,'')
      });

      for (let i=0; i<11; i++)
        {
            if (replacer(arr[i]) === '' && replacerV2(i, arr) === true )
              {
                //tempErrors = await checkIfNewICDDiag('GAPS',i,errors)
                // errors.icdCodes = []
                if (errors.icdCodes === undefined)  {
                  errors.icdCodes = []
                errors.icdCodes[i]='Gaps found'
                } else {
                  errors.icdCodes[i]='Gaps found'
                }
                // return errors
              }
              else {
                // console.log(arr[i])
                // console.log(arr[i+1])
              }
        }
  
        for (let i=0; i<12; i++)
        {
          // console.log(repeatedICDs(arr[i], arr) > 1)
          // console.log(replacer(arr[i]) != '')
            if (repeatedICDs(arr[i], arr) > 1 && replacer(arr[i]) !== '' && lastIndex(arr[i], arr) !== i)
              {
                if (errors.icdCodes === undefined)  {
                errors.icdCodes = []
                errors.icdCodes[i]='Repeated Diagnosis Code'
                } else {
                  errors.icdCodes[i]='Repeated Diagnosis Code'
                }
                // return errors
              }
        }

        let originalIcds = icdStds
        let adjustedIcds = arr
        let sortedOrg = originalIcds.slice().sort().filter(e => String(e).trim());
        let sortedAdj = adjustedIcds.slice().sort().filter(e => String(e).trim())
        let exact = exactMatch(sortedOrg, sortedAdj)
        // console.log(exactMatch)
      
        if(exact === true) {
          props.disabledAccept()
        } else {
          if(props.disabledAcceptButton === true) {
          props.enableAccept()
          }
        }

      }

      let handleDisable = arr.slice().sort().filter(e => String(e).trim());
         if((handleDisable.length - 1) === i) {
         if(errors.icdCodes) {
           let array = errors.icdCodes;
           let disableAfterIndex = disabledICDInputs(array)
           setDisableICDInputs(disableAfterIndex)
         } else {
           setDisableICDInputs(null)
    }
  }
    return errors
}
   

  const exactMatch = (sortedOrg, sortedAdj) => {
    // console.log(sortedOrg)
    // console.log(sortedAdj)
    for(var x = 0; x < sortedOrg.length; x++) {
      let org = sortedOrg[x]
        sortedOrg[x] = org.replace('.', '')
    }
        for(var y = 0; y < sortedAdj.length; y++ ) {
          let adjCode = sortedAdj[y]

          // console.log(adjCode)
          // console.log(sortedOrg.includes(adjCode))
          if(sortedOrg.includes(adjCode) === false) {
            return false
          }
      }
  return true
}

  const disabledICDInputs = (arr) => {
    let array = []
    for(let x = 0; x < 12; x++) {
      if(arr[x]) {
        array.push(x)
      }
  }
  return array
}
  
  const validateICDs= async (ICDType,ICDs,dos)=>{
    // console.log(ICDs)
    try {
        setIsValidatingICDs(true);
        let res = await fetch(localStorage.getItem('apiUrl') + '/getValidateIcdCds?icdType=ICD10&icdCd='+ICDs+'&dos='+dos);
        let resj = await res.json()
        setIsValidatingICDs(false);
        return resj
    } catch(err){
        setIsValidatingICDs(false);
        console.log(err)
    }    
}

  // If disabled state is "all", fields will all be disabled.
  // If disabled state is "none", no fields will be disabled.
  // If disabled state is "partial", fields will be disabled or not depending
  // on the value passed into this function. 
  const getDisabledState = (shouldDisableIfPartial) => {
    switch(disabled) {
      case 'all':
        return true
      case 'partial':
        return shouldDisableIfPartial
      case 'none':
        return false
      default:
        return false
    }
  }

  // const handleAccept = () => {
  //   setMode("acceptSignature");
  // };

  // const handleAutoSign = async () => {
  //   setMode("acceptPending");
  //   await delay(1000);
  //   setMode("acceptConfirm");
  //   await delay(3000);
  //   setMode("acceptPending");
  //   await delay(1000);
  //   setMode("locked");
  //   handleSubmitCms(); // todo: handle form submit
  // };


  const handleSetRejectionReason = async (reason, description) => {
    setRejectionReason(reason);
    //setRejectionReasonDescription(description);
    setMode("rejectConfirm");
    await delay(2000);
    setMode("rejectPending");
    await delay(2000);
    setMode("locked");
    setRejectionReason(null);
    handleSubmitCms(); // todo: handle form reject
  };

  // const getElectronicSignature = () => {
  //   const currentTime = new Date();
  //   const hours =
  //     (currentTime.getHours() < 10 ? "0" : "") + currentTime.getHours();
  //   const minutes =
  //     (currentTime.getMinutes() < 10 ? "0" : "") + currentTime.getMinutes();
  //   const seconds =
  //     (currentTime.getSeconds() < 10 ? "0" : "") + currentTime.getSeconds();
  //   const zone = currentTime
  //     .toLocaleTimeString("en-us", { timeZoneName: "short" })
  //     .split(" ")[2];
  //   const date = new Intl.DateTimeFormat("en-US").format(currentTime);
  //   return (
  //     firstName +
  //     " " +
  //     lastName +
  //     " " +
  //     title +
  //     " " +
  //     date +
  //     " " +
  //     hours +
  //     ":" +
  //     minutes +
  //     ":" +
  //     seconds +
  //     " " +
  //     zone
  //   );
  // };

  // const getButtonBar = (handleSubmit) => {
  //   switch (mode) {
  //     case "acceptSignature":
  //       return ( null
  //         // <div
  //         //   id="claimButtonMenu"
  //         //   className="d-flex flex-column align-items-center justify-content-between"
  //         // >
  //         //   <Button id="autoSignButton" onClick={handleAutoSign}>
  //         //     {"Auto Sign".toUpperCase()}
  //         //   </Button>
  //         //   <img style={{ width: 250 }} src={acceptedStamp} />
  //         //   <Button id="rejectButton" disabled>
  //         //     {"Reject".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="pendButton" disabled>
  //         //     {"Pend".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="editButton" disabled>
  //         //     {"Edit".toUpperCase()}
  //         //   </Button>
  //         // </div>
  //       );
  //     case "acceptConfirm":
  //       return ( null
  //         // <div
  //         //   id="claimButtonMenu"
  //         //   className="d-flex flex-column align-items-center justify-content-between"
  //         // >
  //         //   <Button id="autoSignButton" onClick={handleSubmit}>
  //         //     {"Complete".toUpperCase()}
  //         //   </Button>
  //         //   <div id="autoSignImageContainer">
  //         //     <img style={{ width: 250 }} src={acceptedStamp} />
  //         //     <h6 id="electronicSignatureHeader">Electronic Signature</h6>
  //         //     <label id="electronicSignature">{getElectronicSignature()}</label>
  //         //   </div>
  //         //   <Button id="rejectButton" disabled>
  //         //     {"Reject".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="pendButton" disabled>
  //         //     {"Pend".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="editButton" disabled>
  //         //     {"Edit".toUpperCase()}
  //         //   </Button>
  //         // </div>
  //       );
  //     case "acceptPending":
  //       return;
  //     case "reject":
  //       return ( null
  //         // <div
  //         //   id="claimButtonMenu"
  //         //   className="d-flex flex-column align-items-center justify-content-between"
  //         // >
  //         //   <Button id="rejectButton">{"Reject".toUpperCase()}</Button>
  //         //   <Button id="acceptButton" disabled>
  //         //     {"Accept".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="pendButton" disabled>
  //         //     {"Pend".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="editButton" disabled>
  //         //     {"Edit".toUpperCase()}
  //         //   </Button>
  //         // </div>
  //       );
  //     case "rejectPending":
  //     case "rejectConfirm":
  //       return (null
  //         // <div
  //         //   id="claimButtonMenu"
  //         //   className="d-flex flex-column align-items-center justify-content-between align-items-center"
  //         // >
  //         //   <Button id="rejectButton">{"Reject".toUpperCase()}</Button>
  //         //   <img style={{ width: 250 }} src={rejectionStamp} />
  //         //   <Button id="acceptButton" disabled>
  //         //     {"Accept".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="pendButton" disabled>
  //         //     {"Pend".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="editButton" disabled>
  //         //     {"Edit".toUpperCase()}
  //         //   </Button>
  //         // </div>
  //       );
  //     case "locked":
  //     default:
  //       return (null
  //         // <div
  //         //   id="claimButtonMenu"
  //         //   className="d-flex flex-column align-items-center justify-content-between"
  //         // >
  //         //   <Button id="acceptButton" onClick={handleAccept}>
  //         //     {"Accept".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="rejectButton" onClick={setMode.bind(null, "reject")}>
  //         //     {"Reject".toUpperCase()}
  //         //   </Button>
  //         //   <Button id="pendButton">{"Pend".toUpperCase()}</Button>
  //         //   <Button id="editButton">{"Edit".toUpperCase()}</Button>
  //         //   <Button id="compareButton">{"Compare".toUpperCase()}</Button>
  //         // </div>
  //       );
  //   }
  // };

  

  const handleFormSubmit = (cmsValues) => {
    // console.log("submit");
    handleSubmitCms();
    // console.log(cmsValues);
  };

  return (
    <Formik
      validationSchema={schema}
      validate={validateCms}
      validateOnChange={false}
      validateOnBlur={true}
      validateOnMount={original ? false : true}
      onSubmit={(values) => {
        handleFormSubmit(values);
      }}
      initialValues={adjustedClaim}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setFieldTouched,
        formikProps,
      }) => (
        <>
          <OnFormChangeComponent isValid={isValid} isValidHandler={isValidHandler} errors={errors} values={values} isValidatingICDs={isValidatingICDs} />
          {/* {getButtonBar(handleSubmit)} */}
          {mode === "reject" && rejectionReason === null && (
            <RejectionOptions
              options={rejectionOptions}
              setRejectionReason={handleSetRejectionReason}
            />
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            // style={{ marginBottom: 100, marginTop: 50 }}
            style={{paddingTop: 20}}
          >
            <form
              id={"adjustedCMSForm"}
              onSubmit={handleSubmit}
              style={{ width: tableWidth }}
            >
              <div className="rowContainer">
                <div className="colContainer">
                  <div className="rowContainer">
                    <div className="" style={{ width: 350 }}>
                      <img src={qrcode} alt="QR Code" style={{ height: 53 }} />
                      <h1 className="text header">
                        {"Health insurance claim form".toUpperCase()}
                      </h1>
                      <h2 className="text subheader">
                        {"Approved by National Uniform Claim Committee (NUCC) 02/12".toUpperCase()}
                      </h2>
                    </div>
                    <div className="colContainer">
                      <Field name="hpName">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390, height:20}}
                            {...field}
                          />
                        )}
                      </Field>
                      <Field name="hpAddress">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390, height:20 }}
                            {...field}
                          />
                        )}
                      </Field>
                      {/* <Field name="hpAddress2">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390 }}
                            {...field}
                          />
                        )}
                      </Field> */}
                      <Field name="hpCityStateZip">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390, height:20 }}
                            {...field}
                          />
                        )}
                      </Field>
                      <Field name="hpAddress2">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390, height:20 }}
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div
                    className="rowContainer spaceBetween"
                    style={{ width: tableWidth - 12 }}
                  >
                    <div className="rowContainer">
                      <CharacterInput />
                      <CharacterInput />
                      <CharacterInput />
                      <p className="text label">&nbsp;PICA</p>
                    </div>
                    <div className="rowContainer">
                      <p className="text label">PICA&nbsp;&nbsp;</p>
                      <CharacterInput />
                      <CharacterInput />
                      <CharacterInput />
                    </div>
                  </div>
                </div>
                <div className="colContainer">
                  <div className="rotatedTextContainer" style={{ height: 110 }}>
                    <div className="arrowLine" style={{ height: 110 }}></div>
                    <label
                      className="rotatedText text d-flex justify-content-between"
                      style={{
                        width: 110,
                        top: 55,
                        transform:
                          "translateX(-48%) translateY(-50%) rotate(-90deg)",
                      }}
                    >
                      <span>{`<`}</span>
                      <span className="bgWhite">&ensp;{`Carrier`.toUpperCase()}&ensp;</span>
                      <span>{`>`}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="rowContainer">
                <table className="formTable" style={{ width: tableWidth }}>
                  <tbody>
                    <tr id="row1">
                      <td colSpan="17">
                        <div className="rowContainer">
                          <Field name="insuranceType" id="insuranceType1">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number="1"
                                title="Medicare"
                                value="Medicare"
                                label="Medicare#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType2">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                title="Medicaid"
                                value="Medicaid"
                                label="Medicaid#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType3">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                id="tricare"
                                title="Tricare"
                                value="Tricare"
                                label="ID#/DoD#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType4">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                title="Champva"
                                value="Champva"
                                label="Member ID#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType5">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                title="Group"
                                title2="Health Plan"
                                value="Group Health Plan"
                                label="ID#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType6">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                title="FECA"
                                title2="Blk Lung"
                                value="FECA Blk Lung"
                                label="ID#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                          <Field name="insuranceType" id="insuranceType7">
                            {({ field, form, meta }) => (
                              <CMSRadio
                                setFieldValue={setFieldValue}
                                activeField={activeField}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                number=""
                                title="Other"
                                value="Other"
                                label="ID#"
                                field={field}
                                form={form}
                                meta={meta}
                                // disabled = {true}
                                readOnly={true}
                              />
                            )}
                          </Field>
                        </div>
                      </td>
                      <td colSpan="9">
                        <div className="rowContainer">
                          <CMSTextInput
                            name="member_id"
                            number="1a"
                            title="Insured's I.D. Number"
                            subtitle=""
                            maxLength={29}
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            disabled={true}
                          />
                          <label 
                            className="text label cellLabel" 
                            id="memberIdSubtitle"
                            style={{  width: 100  }}
                          >
                            {`(For Program in Item 1)`}
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr id="row2">
                      <td colSpan="10">
                        <CMSTextInput
                          name="member_name"
                          number="2"
                          title="Patient's Name"
                          subtitle="(Last Name, First Name, Middle Initial)"
                          maxLength={30}
                          size="30"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7">
                        <div className="rowContainer spaceBetween">
                          <div className="colContainer">
                            <CMSTitle
                              elementName="patient.dob"
                              number="3"
                              title="Patient's Birth Date"
                              subtitle=""
                            />
                            <div className="rowContainer">
                              <div className="colContainer ms-2">
                                <label className="text label cellLabel center">{`MM`}</label>
                                <BasicFieldWrapper
                                  name={`member_dobMM`} // DATE NAME CHANGED
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={2}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={true}
                                  />
                                </BasicFieldWrapper>
                              </div>
                              <div className="verticalRule dashed tall"/>
                              <div className="colContainer">
                                <label className="text label cellLabel center">{`DD`}</label>
                                <BasicFieldWrapper
                                  name={`member_dobDD`} // DATE NAME CHANGED
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={2}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={disabled}
                                  />
                                </BasicFieldWrapper>
                              </div>
                              <div className="verticalRule dashed tall"/>
                              <div className="colContainer">
                                <label className="text label cellLabel center">{`YY`}</label>
                                <BasicFieldWrapper
                                  name={`member_dobYY`} // DATE NAME CHANGED
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={4}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={disabled}
                                  />
                                </BasicFieldWrapper>
                              </div>
                            </div>
                          </div>
                          <CMSOptionList
                            elementName="patient.gender" //member_gender
                            number=""
                            title="Sex"
                            centeredTitle={true}
                            subtitle=""
                            options={["M", "F"]}
                            labelPosition={"left"}
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            // disabled={true}
                            readOnly={true}
                          />
                        </div>
                      </td>
                      <td colSpan="9">
                        <CMSTextInput
                          name="insured_name"
                          number="4"
                          title="Insured's Name"
                          subtitle="(Last Name, First Name, Middle Initial)"
                          maxLength={30}
                          size="30"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                          readOnly={true}
                        />
                      </td>
                    </tr>
                    <tr id="row3">
                      <td colSpan="10">
                        <CMSTextInput
                          name="member_address"//patientAddress //member_address
                          number="5"
                          title="Patient's Address"
                          subtitle="(No., Street)"
                          maxLength={45}
                          size="45"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7">
                        <div className="rowContainer spaceBetween">
                          <CMSOptionList
                            elementName="patientRelationship"
                            number="6"
                            title="Patient Relationship to Insured"
                            subtitle=""
                            options={["Self", "Spouse", "Child", "Other"]}
                            labelPosition={"left"}
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            // disabled={true}
                            readOnly={true}
                          />
                        </div>
                      </td>
                      <td colSpan="9">
                        <CMSTextInput
                          name="insured_address"//insured_address
                          number="7"
                          title="Insured's Address"
                          subtitle="(No., Street)"
                          maxLength={40}
                          size="40"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <CMSTextInput
                          name="member_city"//patient city
                          number=""
                          title="City"
                          subtitle=""
                          maxLength={24}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="2">
                        <CMSTextInput
                          name="member_state"//patient state
                          number=""
                          title="State"
                          subtitle=""
                          maxLength={3}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7" rowSpan="2">
                        <CMSInputWrapper
                          elementName="nucc8"
                          number="8"
                          title="Reserved for NUCC Use"
                          subtitle=""
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7">
                        <CMSTextInput
                          name="insured_city"//insured city
                          number=""
                          title="City"
                          subtitle=""
                          maxLength={24}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="2">
                        <CMSTextInput
                          name="insured_state"//insured State
                          number=""
                          title="State"
                          subtitle=""
                          maxLength={3}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <CMSTextInput
                          name="member_zip"//patient zip
                          number=""
                          title="Zip Code"
                          subtitle=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="6">
                        <CMSPhoneInput
                          elementName1="member_phone"//patient phone
                          elementName2="member_areaCode"//patientAreaCode
                          number=""
                          title="Telephone"
                          subtitle="(Include Area Code)"
                          value=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="4">
                        <CMSTextInput
                          name="insured_zip"//insuredZipCode
                          number=""
                          title="Zip Code"
                          subtitle=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="5">
                        <CMSPhoneInput
                          elementName1="insured_phone"//insuredPhone
                          elementName2="insured_areaCode"//insuredAreaCode
                          number=""
                          title="Telephone"
                          subtitle="(Include Area Code)"
                          value=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr id="row6">
                      <td colSpan="10">
                        <CMSTextInput
                          name="member.otherName"
                          number="9"
                          title="Other Insured's Name"
                          subtitle="(Last Name, First Name, Middle Initial)"
                          maxLength={30}
                          size="30"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7" rowSpan="4">
                        <div
                          className="colContainer spaceBetween"
                          style={{ height: 140 }}
                        >
                          <CMSTitle
                            elementName="conditionRelated"
                            number="10"
                            title="Is Patient's Condition Related to:"
                            subtitle=""
                          />
                          <CMSOptionList
                            elementName="employment"
                            number="a"
                            title="Employment?"
                            subtitle="(Current or Previous)"
                            options={["YES", "NO"]}
                            justifyCenter
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            disabled={true}
                          />
                          <CMSOptionList
                            elementName="autoAccident"
                            number="b"
                            title="Auto Accident?"
                            subtitle=""
                            options={["YES", "NO"]}
                            justifyCenter
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            disabled={true}
                          />
                          <CMSOptionList
                            elementName="otherAccident"
                            number="c"
                            title="Other Accident?"
                            subtitle=""
                            options={["YES", "NO"]}
                            justifyCenter
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            disabled={true}
                          />
                          <div className="relativePositioningPlaceholder">
                            <div id="autoAccidentStatePosition">
                              <CMSTextInput
                                name="autoAccidentState"
                                number=""
                                title="Place"
                                subtitle="(State)"
                                size="2"
                                maxLength={2}
                                handleFocus={handleFocus}
                                setFieldTouched={setFieldTouched}
                                activeField={activeField}
                                disabled={true}
                                inputStyle={{ 
                                  borderBottom: "1px solid var(--optum-red-2)", width: 30, marginLeft: 8,
                                  borderLeft: "1px solid var(--optum-red-2)", 
                                  borderRight: "1px solid var(--optum-red-2)"
                                }}
                              />
                              <div className="relativePositioningPlaceholder">
                                <div  
                                  style={{ width: 3, height: 12, position: "relative", top: -20, left: 6, background: "#fff" }}
                                />
                                <div  
                                  style={{ width: 3, height: 12, position: "relative", top: -32, left: 36, background: "#fff" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td colSpan="9">
                        <CMSTextInput
                          name="member.policyGroup"
                          number="11"
                          title="Insured's Policy Group or FECA Number"
                          subtitle=""
                          maxLength={29}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr id="row7">
                      <td colSpan="10">
                        <CMSTextInput
                          name="member.otherPolicyGroup"
                          number="a"
                          title="Other Insured's Policy or Group Number"
                          subtitle=""
                          maxLength={28}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="9">
                        <div className="rowContainer spaceBetween">
                          <div className="colContainer">
                            <CMSTitle
                              elementName="member.dob" /* insured_dob */
                              number="a"
                              title="Insured's Date of Birth"
                              subtitle=""
                            />
                            <div className="rowContainer">
                              <div className="colContainer" style={{ marginLeft: 26 }}>
                                <label className="text label cellLabel center">{`MM`}</label>
                                <BasicFieldWrapper
                                  name={`insured_dobMM`}  /* insured_dob */
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={2}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={true}
                                  />
                                </BasicFieldWrapper>
                              </div>
                              <div className="verticalRule dashed tall"/>
                              <div className="colContainer">
                                <label className="text label cellLabel center">{`DD`}</label>
                                <BasicFieldWrapper
                                  name={`insured_dobDD`}  /* insured_dob */
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={2}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={disabled}
                                  />
                                </BasicFieldWrapper>
                              </div>
                              <div className="verticalRule dashed tall"/>
                              <div className="colContainer">
                                <label className="text label cellLabel center">{`YY`}</label>
                                <BasicFieldWrapper
                                  name={`insured_dobYY`}  /* insured_dob */
                                  activeField={activeField}
                                >
                                  <input
                                    onFocus={(e) => handleFocus(e, setFieldTouched)}
                                    maxLength={4}
                                    type="text"
                                    className="input center dateInput"
                                    disabled={disabled}
                                  />
                                </BasicFieldWrapper>
                              </div>
                            </div>
                          </div>
                          <div className="rowContainer spaceAround" style={{width: "100%"}}>
                            <CMSOptionList
                              elementName="insured_gender"
                              number=""
                              title="Sex"
                              centeredTitle={true}
                              subtitle=""
                              options={["M", "F"]}
                              labelPosition={"left"}
                              handleFocus={handleFocus}
                              setFieldTouched={setFieldTouched}
                              activeField={activeField}
                              setFieldValue={setFieldValue}
                              // disabled={true}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr id="row8">
                      <td colSpan="10">
                        <CMSTextInput
                          name="nucc9b"
                          number="b"
                          title="Reserved for NUCC Use"
                          subtitle=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="9">
                        <CMSTitle
                          elementName="nuccOtherClaimId"
                          number="b"
                          title="Other Claim Id"
                          subtitle="(Designated by NUCC)"
                        />
                        <div className="rowContainer align-items-end">
                          <div className="verticalRule dashed" style={{width: 18}}/>
                          <BasicFieldWrapper
                            name="nuccOtherClaimId"  //serviceFacilityOtherId
                            activeField={activeField}
                          >
                            <input
                              type="text"
                              className="input center"
                              maxLength={10}
                              disabled={true}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                            />
                          </BasicFieldWrapper>
                        </div>
                      </td>
                    </tr>
                    <tr id="row9">
                      <td colSpan="10">
                        <CMSTextInput
                          name="nucc9c"
                          number="c"
                          title="Reserved for NUCC Use"
                          subtitle=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="9">
                        <CMSTextInput
                          name="member.insurancePlan" //11c
                          number="c"
                          title="Insurance Plan Name or Program Name"
                          subtitle=""
                          maxLength={28}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="10">
                        <CMSTextInput
                          name="member.otherInsurancePlan" //9d
                          number="d"
                          title="Insurance Plan Name or Program Name"
                          subtitle=""
                          maxLength={28}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="7">
                        <CMSTextInput
                          name="nuccClaimCodes"
                          number="10d"
                          title="Claim Codes"
                          subtitle="(Designated by NUCC)"
                          maxLength={19}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={true}
                        />
                      </td>
                      <td colSpan="9">
                        <CMSOptionList
                          elementName="anotherHealthBenefitPlan"
                          number="d"
                          title="Is There Another Health Benefit Plan?"
                          subtitle=""
                          options={["YES", "NO"]}
                          additionalLabel={
                            <p className="text label" style={{position:'relative',top:'5px'}}>
                              <span className="bold extraBold" style={{marginLeft:'10px'}}><i><b>If yes,</b></i> </span> 
                              complete items 9, 9a, and 9d.
                            </p>
                          }
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          setFieldValue={setFieldValue}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="17" rowSpan="2">
                        <p className="text label bold extraBold center topWarning">
                          {"Read Back of Form Before Completing & Signing This Form.".toUpperCase()}
                        </p>
                        <CMSTitle
                          elementName="patientSignature"
                          number="12"
                          title="Patient's or Authorized Person's Signature"
                          subtitle="I authorize the release of any medical or other information necessary"
                        />
                        <label className="text label" style={{ marginLeft: 13, lineHeight: '120%', position: "relative", top: -3 }}>
                          {`to process this claim.  I also request payment of government benefits either to myself
                            or to the party who accepts assignment`}
                          <br />
                          {`below.`}
                        </label>
                        <div className="rowContainer spaceBetween" style={{ marginLeft: 13, marginTop: 18 }}>
                          <div className="rowContainer align-items-end">
                            <label className="text label">
                              {"Signed".toUpperCase()}
                            </label>
                            <BasicFieldWrapper
                              name="member_signature"
                              activeField={activeField}
                              
                            >
                              <input
                                style={{ width: 268 }}
                                className="inputBottomBorder center"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="rowContainer align-items-end">
                            <label className="text label">
                              {"Date".toUpperCase()}
                            </label>
                            <BasicFieldWrapper
                              name="member_signature_date"
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                id="member_signature_date"
                                className="datepickerBottomBorder"
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                style={{ color: getDatepickerTextColor(values.dos), width: 150 }}
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                      </td>
                      <td colSpan="9" rowSpan="2">
                        <div
                          className="colContainer spaceBetween"
                          style={{ height: 78 }}
                        >
                          <div>
                            <CMSTitle
                              elementName="insured_signature"
                              number="13"
                              title="Insured's or Authorized Person's Signature"
                              subtitle="I authorize"
                            />
                            <label className="text label" style={{ marginLeft: 13, lineHeight: '120%', position: "relative", top: -3 }}>
                              {`payment of medical benefits to the undersigned physician or`}
                              <br />
                              {`supplier for services described below.`}
                            </label>
                          </div>
                          <div className="rowContainer align-items-end">
                            <label className="text label" style={{ marginLeft: 13 }}>
                              {"Signed".toUpperCase()}
                            </label>
                            <BasicFieldWrapper
                              name="insured_signature" //"insuredSignature"
                              activeField={activeField}
                            >
                              <input
                                style={{ width: 200 }}
                                className="inputBottomBorder center"
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="rotatedTextContainer">
                  <div className="arrowLine"></div>
                  <label className="rotatedText text d-flex justify-content-between">
                    <span>{`<`}</span>
                    <span className="bgWhite">
                      &ensp;&ensp;{`Patient and Insured Information`.toUpperCase()}&ensp;&ensp;
                    </span>
                    <span>{`>`}</span>
                  </label>
                </div>
              </div>
              <div className="rowContainer">
                <table className="formTable">
                  <tbody>
                  <tr id="row12">
                    <td colSpan="10">
                      <CMSInputWrapper
                        elementName="illnessInjuryPregnancyDate"
                        number="14"
                        title=""
                        subtitle="DATE OF CURRENT ILLNESS, INJURY or PREGNANCY (LMP)"
                        spaceBetween
                      >
                        <div className="rowContainer">
                          <div className="colContainer ms-2">
                            <label className="text label cellLabel center">{`MM`}</label>
                            <BasicFieldWrapper
                              name={`illnessInjuryPregnancyDateMM`}  // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`DD`}</label>
                            <BasicFieldWrapper
                              name={`illnessInjuryPregnancyDateDD`} // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`YY`}</label>
                            <BasicFieldWrapper
                              name={`illnessInjuryPregnancyDateYY`}  // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={4}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                        <div className="rowContainer align-items-end" style={{ position: "relative", left: -48 }}>
                          <label className="text label">
                            {"Qual.".toUpperCase()}
                          </label>
                          <div className="verticalRule dashed tall" style={{ width: 3, marginRight: 5 }}/>
                          <BasicFieldWrapper
                            name="illnessInjuryPregnancyQual" //"dateOfIllnessQualifier"
                            activeField={activeField}
                          >
                            <input
                              className="input"
                              style={{ width: 110 }}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled={true}
                            />
                          </BasicFieldWrapper>
                        </div>
                      </CMSInputWrapper>
                    </td>
                    <td colSpan="7">
                      <CMSInputWrapper
                        elementName="otherDate"
                        number="15"
                        title="Other Date"
                        subtitle=""
                        spaceBetween
                      >
                        <div className="rowContainer align-items-center">
                          <label className="text label">
                            {"Qual.".toUpperCase()}
                          </label>
                          <div className="verticalRule dashed tall" style={{ width: 3, marginRight: 5 }}/>
                          <BasicFieldWrapper
                            name="otherQual" //"otherDateQualifier"
                            activeField={activeField}
                          >
                            <input
                              className="input"
                              style={{ width: 35 }}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled={true}
                            />
                          </BasicFieldWrapper>
                          <div className="verticalRule dashed tall" style={{ width: 1 }}/>
                        </div>
                        <div className="rowContainer justify-content-center" style={{ width: "100%" }}>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`MM`}</label>
                            <BasicFieldWrapper
                              name="otherDateMM" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`DD`}</label>
                            <BasicFieldWrapper
                              name="otherDateDD" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`YY`}</label>
                            <BasicFieldWrapper
                              name="otherDateYY" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={4}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                      </CMSInputWrapper>
                    </td>
                    <td colSpan="9" style={{ overflow: "hidden", paddingRight: 30 }}>
                      <CMSInputWrapper
                        elementName="datesUnableToWork"
                        number="16"
                        title="Dates Patient Unable to Work in Current Occupation"
                        subtitle=""
                        spaceBetween
                      >
                        <div className="rowContainer align-items-end" style={{marginLeft:10,}}>
                          <label className="text label">{"From".toUpperCase()}</label>
                          <div className="rowContainer justify-content-center" style={{ width: "100%" }}>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`MM`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkFromDateMM" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  style={{ color: getDatepickerTextColor(values.otherDate) }}
                                  maxLength={2}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={true}
                                />
                              </BasicFieldWrapper>
                            </div>
                            <div className="verticalRule dashed tall"/>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`DD`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkFromDateDD" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  maxLength={2}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={disabled}
                                />
                              </BasicFieldWrapper>
                            </div>
                            <div className="verticalRule dashed tall"/>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`YY`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkFromDateYY" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  maxLength={4}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={disabled}
                                />
                              </BasicFieldWrapper>
                            </div>
                          </div>
                        </div>
                        <div className="rowContainer align-items-end" style={{marginLeft:"10px"}}>
                          <label className="text label">{"To".toUpperCase()}</label>
                          <div className="rowContainer justify-content-center" style={{ width: "100%" }}>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`MM`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkToDateMM" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  style={{ color: getDatepickerTextColor(values.otherDate) }}
                                  maxLength={2}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={true}
                                />
                              </BasicFieldWrapper>
                            </div>
                            <div className="verticalRule dashed tall"/>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`DD`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkToDateDD" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  maxLength={2}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={disabled}
                                />
                              </BasicFieldWrapper>
                            </div>
                            <div className="verticalRule dashed tall"/>
                            <div className="colContainer">
                              <label className="text label cellLabel center">{`YY`}</label>
                              <BasicFieldWrapper
                                name="unableToWorkToDateYY" // DATE NAME CHANGED
                                activeField={activeField}
                              >
                                <input
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                  maxLength={4}
                                  type="text"
                                  className="input center dateInput"
                                  disabled={disabled}
                                />
                              </BasicFieldWrapper>
                            </div>
                          </div>
                        </div>
                      </CMSInputWrapper>
                    </td>
                  </tr>
                  <tr id="row13">
                    <td colSpan="10">
                      <CMSInputWrapper
                        elementName="referringProvider"
                        number="17"
                        title="Name of Referring Provider or Other Source"
                        subtitle=""
                      >
                        <div className="verticalRule dashed tall" style={{width: 25}}/>
                        <BasicFieldWrapper
                          name="referringProvider" //"otherProviderQualifier"
                          activeField={activeField}
                        >
                          <input
                            type="text"
                            id="referringProvider"
                            className="input"
                            onFocus={(e) => handleFocus(e, setFieldTouched)}
                            disabled={true}
                          />
                        </BasicFieldWrapper>
                      </CMSInputWrapper>
                    </td>
                    <td colSpan="7" className="dividedInputs">
                      <div className="colContainer spaceBetween inputContainer">
                        <div className="rowContainer">
                          <CMSTitle
                            elementName="17a"
                            number="17a"
                            title=""
                            subtitle=""
                          />
                          <div className="relativePositioningPlaceholder" style={{ marginRight: 5 }}>
                            <div 
                              className="verticalRule solid" 
                              style={{ width: 3, height: 42, position: "relative", top: -4 }}
                            />
                          </div>
                          <BasicFieldWrapper
                            name="referringProviderQual" //"otherProviderQualifier"
                            activeField={activeField}
                          >
                            <input
                              type="text"
                              id="qualifer17a"
                              className="inputInline shadedBg"
                              style={{ width: 30 }}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled={true}
                            />
                          </BasicFieldWrapper>
                          <div className="relativePositioningPlaceholder" style={{ marginRight: 5 }}>
                            <div 
                              className="verticalRule solid" 
                              style={{ width: 3, height: 42, position: "relative", top: -4 }}
                            />
                          </div>
                          <BasicFieldWrapper
                            name="referringProviderOtherId"    //otherProviderId not exist
                            activeField={activeField}
                          >
                            <input
                              type="text"
                              id="full17a"
                              className="inputInline shadedBg"
                              style={{ width: 115 }}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled={true}
                            />
                          </BasicFieldWrapper>
                        </div>
                        <div className="relativePositioningPlaceholder" style={{ width: '100%', height: 1 }}>
                          <div
                            className="hr"
                            style={{ width: "100%", height: 1, position: "relative", left: -5 }}
                          />
                        </div>
                        <div className="relativePositioningPlaceholder" style={{ width: '100%' }}>
                          <div
                            className="hr"
                            style={{ width: 10, height: 1, position: "relative", left: 203, top: -1 }}
                          />
                        </div>
                        <div className="rowContainer" style={{ paddingTop: 4 }}>
                          <CMSTitle
                            elementName="NPI"
                            number="17b"
                            subtitle=""
                          />
                          <label className="text label" style={{ position: "relative", top: 5, left: 5, marginLeft: 5 }}>{`NPI`}</label>
                          <BasicFieldWrapper
                            name="referringProviderNpi" //"npi"
                            activeField={activeField}
                            validate={validateNpi}                            
                          >
                            <input
                              type="text"
                              id="npiInput"
                              className="inputInline"
                              maxLength={10}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled={true}
                              style={{ marginLeft: 20 }}
                            />
                          </BasicFieldWrapper>
                        </div>
                      </div>
                    </td>
                    <td colSpan="9" style={{ paddingRight: 30 }}>
                      <CMSInputWrapper
                        elementName="hospitalizationDates"
                        number="18"
                        title="Hospitalization Dates Related to Current Services"
                        subtitle=""
                        spaceBetween
                      >
                      <div className="rowContainer align-items-end" style={{marginLeft:10,}}>
                        <label className="text label">{"From".toUpperCase()}</label>
                        <div className="rowContainer justify-content-center" style={{ width: "100%" }}>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`MM`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationFromDateMM" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                style={{ color: getDatepickerTextColor(values.otherDate) }}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`DD`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationFromDateDD" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`YY`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationFromDateYY" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={4}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                      </div>
                      <div className="rowContainer align-items-end" style={{marginLeft:"10px"}}>
                        <label className="text label">{"To".toUpperCase()}</label>
                        <div className="rowContainer justify-content-center" style={{ width: "100%" }}>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`MM`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationToDateMM" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                style={{ color: getDatepickerTextColor(values.otherDate) }}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`DD`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationToDateDD" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={2}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                          <div className="verticalRule dashed tall"/>
                          <div className="colContainer">
                            <label className="text label cellLabel center">{`YY`}</label>
                            <BasicFieldWrapper
                              name="hospitalizationToDateYY" // DATE NAME CHANGED
                              activeField={activeField}
                            >
                              <input
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                maxLength={4}
                                type="text"
                                className="input center dateInput"
                                disabled={disabled}
                              />
                            </BasicFieldWrapper>
                          </div>
                        </div>
                      </div>
                      </CMSInputWrapper>
                    </td>
                  </tr>
                  <tr id="row14" >
                    <td colSpan="17">
                      <CMSTextInput
                        name="nuccAdditionalClaimInfo" //"additionalClaimInfo"
                        number="19"
                        title="Additional Claim Information"
                        subtitle="(Designated by NUCC)"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={true}
                      />
                    </td>
                    <td colSpan="9">
                      <div className="rowContainer spaceBetween" style={{ paddingRight: 60 }} >
                        <div className="rowContainer align-items-end">
                          <CMSOptionList
                            elementName="outsideLab" //same name
                            number="20"
                            title="Outside Lab?"
                            subtitle=""
                            options={["YES", "NO"]}
                            wrapperId={"outsideLabWrapper"}
                            justifyCenter
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            disabled={true}
                          />
                          <div 
                            className="verticalRule solid" 
                            style={{ width: 2, height: 23, position: "relative", top: 6 }}
                          />
                        </div>
                        <div className="rowContainer align-items-end">
                          <CMSCurrencyInput
                            isBlank={false}
                            elementName="outsideLabCharges" //"charges"
                            number=""
                            title="$ Charges"
                            subtitle=""
                            showDollarSign={false}
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            disabled = {true}
                          />
                          <div 
                            className="verticalRule solid" 
                            style={{  height: 23, position: "relative", top: 6 , left: -18}}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr id="row15">
                    <td colSpan="17" rowSpan="2" style={{paddingBottom: 1}}>
                      <div className="rowContainer justify-content-between">
                        <CMSTitle
                          elementName="diagnoses"
                          number="21"
                          title="Diagnosis or Nature of Illness or Injury"
                          subtitle="Relate A-L to service line below (24E)"
                        />
                        <div
                          className="rowContainer justify-content-start"
                          style={{ width: 133 }}
                        >
                          <CMSTitle
                            elementName="icdInd"
                            number=""
                            title=""
                            subtitle="ICD Ind."
                          />
                          <div className="verticalRule dashed" style={{ height: 14, position: "relative", top: -2 }} />
                          <BasicFieldWrapper
                                                    
                            name="icdIndicator" //same name
                            activeField={activeField}
                            
                          >
                            <input
                              type="text"
                              id="icdIndicator"
                              className="inputInline"
                              style={{marginLeft:'1px',width:20,position:'relative',bottom:'4px'}}
                              onFocus={(e) => handleFocus(e, setFieldTouched)}
                              disabled = {true}
                            />
                          </BasicFieldWrapper>
                          <div className="verticalRule dashed" style={{ height: 14, position: "relative", top: -2 }} />
                        </div>
                      </div>
                      <CMSInputTable
                        name={original?'icdStds':'icdCodes'} //same name
                        fields={12}
                        activeField={activeField}
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        icdInfo={adjustedClaim.icdAllInfo}
                        editenabled={editenabled}
                        disabled={true}
                        disabledInputs={disabledInputs}
                        showErrorMessage={showErrorMessage}
                      />
                    </td>
                    <td colSpan={9} >
                      <div className="rowContainer spaceBetween">
                        <div className="colContainer spaceBetween">
                          <CMSTitle
                            elementName="resubmissionCode"
                            number="22"
                            title="Resubmission"
                          />
                          <label className="text label" style={{ marginLeft: 13 }}>
                            {(`Code`).toUpperCase()}
                          </label>
                          <div className="rowContainer">
                            <BasicFieldWrapper                  
                              name="resubmissionCode"
                              activeField={activeField}
                              isBlank={original}
                            >
                              <input
                                type="text"
                                id="icdIndicator"
                                className="inputInline"
                                style={{ width:80 }}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                                disabled={true}
                              />
                            </BasicFieldWrapper>
                            <div 
                              className="verticalRule solid" 
                              style={{ width: 28, height: 23, alignSelf: "end" }}
                            />
                          </div>
                        </div>
                        <div style={{ alignSelf: "end" }}>
                          <CMSTextInput
                            name="claim_id_new"
                            number=""
                            title="Original Ref. No."
                            subtitle=""
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr id="row16">
                    <td colSpan={9}>
                      <CMSTextInput
                        name="priorAuthorizationNumber"
                        number="23"
                        title="Prior Authorization Number"
                        subtitle=""
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled = {true}
                      />
                    </td>
                  </tr>
                  <tr id="innerTableHeaders">
                    <td colSpan={6}>
                      <div className="colContainer spaceBetween inputContainer">
                        <div className="rowContainer">
                          <CMSTitle
                            elementName="header"
                            number="24. A"
                            title=""
                            subtitle=""
                          />
                          <div style={{ marginLeft: 28 }}>
                            <CMSTitle
                              elementName="header"
                              number=""
                              title="Date(s) of Service"
                              subtitle=""
                            />
                          </div>
                        </div>
                        <div className="rowContainer spaceAround">
                          <label className="text label">From</label>
                          <label className="text label">To</label>
                        </div>
                        <div className="rowContainer spaceBetween w-100">
                          <div className="rowContainer spaceBetween w-50 me-2 ms-1">
                            <label className="text label">MM</label>
                            <label className="text label">DD</label>
                            <label className="text label">YY</label>
                          </div>
                          <div className="rowContainer spaceBetween w-50 ms-2 me-1">
                            <label className="text label">MM</label>
                            <label className="text label">DD</label>
                            <label className="text label">YY</label>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <CMSInnerTableHeaders number="B" title="" subtitle="" name="place of service">
                        <div className="colContainer spaceBetween" style={{ height: 20 }}>
                          <label className="text label center">
                            {"Place of".toUpperCase()}
                          </label>
                          <label className="text label center">
                            {"Service".toUpperCase()}
                          </label>
                        </div>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={1}>
                      <CMSInnerTableHeaders number="C" title="" subtitle="">
                        <label className="text label center">
                          {"EMG".toUpperCase()}
                        </label>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={6}>
                      <div className="colContainer spaceBetween inputContainer">
                        <CMSTitle
                          elementName="header"
                          number="D"
                          title="Procedures, Services, or Supplies"
                        />
                        <label className="text label" style={{ marginLeft: 16 }}>{`(Explain Unusual Circumstances)`}</label>
                        <div className="rowContainer">
                          <label id="cptHeader" className="text label center">
                            CPT/HCPCS
                          </label>
                          <div className="relativePositioningPlaceholder">
                            <div 
                              className="verticalRule solid" 
                              style={{ width: 1, height: 14, position: "relative", top: 2 }}
                            />
                          </div>
                          <label
                            id="modifierHeader"
                            className="text label center"
                          >
                            MODIFIER
                          </label>
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <CMSInnerTableHeaders number="E" title="" subtitle="">
                        <div className="colContainer spaceBetween" style={{ height: 20 }}>
                          <label className="text label center">
                            {"Diagnosis".toUpperCase()}
                          </label>
                          <label className="text label center">
                            {"Pointer".toUpperCase()}
                          </label>
                        </div>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={2}>
                      <CMSInnerTableHeaders number="F" title="" subtitle="">
                        <label className="text label center">
                          {"$ Charges".toUpperCase()}
                        </label>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={1}>
                      <CMSInnerTableHeaders number="G" title="" subtitle="">
                        <label className="text label center">
                          {"Days or Units".toUpperCase()}
                        </label>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={1}>
                      <CMSInnerTableHeaders number="H" title="" subtitle="">
                        <label className="text label center">
                          {"EPSDT Family Plan"}
                        </label>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={1}>
                      <CMSInnerTableHeaders number="I" title="" subtitle="">
                        <div className="colContainer spaceBetween" style={{ height: 20 }}>
                          <label className="text label center">
                            {"ID.".toUpperCase()}
                          </label>
                          <label className="text label center">
                            {"Qual.".toUpperCase()}
                          </label>
                        </div>
                      </CMSInnerTableHeaders>
                    </td>
                    <td colSpan={4}>
                      <CMSInnerTableHeaders number="J" title="" subtitle="">
                        <div className="colContainer spaceBetween" style={{ height: 20 }}>
                          <label className="text label center">
                            {"Rendering".toUpperCase()}
                          </label>
                          <label className="text label center">
                            {"Provider ID. #".toUpperCase()}
                          </label>
                        </div>
                      </CMSInnerTableHeaders>
                    </td>
                  </tr>
                  <DividedRow
                    index={1 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription:
                        "Emergency department visit for the evaluation and management of a patient, which requires these 3 key components within the constraints imposed by the urgency of the patient's clinical condition and/or mental status: A comprehensive history; A comprehensive examination; and Medical decision making of high complexity.",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <DividedRow
                    index={2 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription:
                        "Inpatient hospital visits: Initial and subsequent initial hospital care, per day, for the evaluation and management of a patient, which requires these 3 key components: A comprehensive history; A comprehensive examination; and Medical decision making of moderate complexity. ",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <DividedRow
                    index={3 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription:
                        "Emergency department visit for the evaluation and management of a patient, which requires these 3 key components: A detailed history; A detailed examination; and Medical decision making of moderate complexity.",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <DividedRow
                    index={4 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription:
                        "Inpatient hospital visits: Initial and subsequent. Initial hospital care, per day, for the evaluation and management of a patient, which requires these 3 key components: A detailed or comprehensive history; A detailed or comprehensive examination; and Medical decision making that is straightforward or of low complexity.",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <DividedRow
                    index={5 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription:
                        "Office consultation for a new or established patient that requires these three key components: a comprehensive history; a comprehensive examination; medical decision-making of high complexity. ",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <DividedRow
                    index={6 + ((currentPage - 1) * 6)}
                    data={{
                      cptDescription: "",
                    }}
                    values={values}
                    cptInfo={adjustedClaim.cptsAllInfo}
                    handleFocus={handleFocus}
                    setFieldTouched={setFieldTouched}
                    activeField={activeField}
                    disabled={disabled}
                    validateCharge={validateCharge}
                    editenabled={editenabled}
                    original={original}
                    showErrorMessage={showErrorMessage}
                  />
                  <tr id="row24">
                    <td colSpan={7}>
                      <div className="rowContainer spaceBetween">
                        <CMSTextInput
                          name="tax_id"
                          number="25"
                          title="Federal Tax I.D. Number"
                          subtitle=""
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled = {true}
                        />
                        <div className="me-3 rowContainer">
                          <CMSOptionList
                            elementName="taxIdType" //ssnEid
                            number=""
                            title=""
                            subtitle=""
                            labelPosition="top"
                            options={["SSN", "EIN"]}
                            handleFocus={handleFocus}
                            setFieldTouched={setFieldTouched}
                            activeField={activeField}
                            setFieldValue={setFieldValue}
                            disabled = {true}
                          />
                        </div>
                      </div>
                    </td>
                    <td colSpan={5}>
                      <CMSTextInput
                        name="patientAccountNumber" //same name    
                        number="26"
                        title="Patient's Account No."
                        subtitle=""
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={true}
                      />
                    </td>
                    <td colSpan={5}>
                      <div className="me-3">
                        <CMSOptionList
                          elementName="acceptAssignment" //same name
                          number="27"
                          title="Accept Assignment?"
                          options={["YES", "NO"]}
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          setFieldValue={setFieldValue}
                          spaceBetween
                          disabled={true}
                        />
                      </div>
                      <div className="relativePositioningPlaceholder">
                        <p 
                          className="text label" 
                          style={{ position: "relative", top: -24, left: 13, width: 150, fontSize: 7 }}
                        >
                          {`(For govt. claims, see back)`}
                        </p>
                      </div>
                    </td>
                    <td colSpan={3}>
                      <CMSCurrencyInput
                        isBlank={currentPage < maxPage}
                        elementName="totalCharge" //same name
                        number="28"
                        title="Total Charge"
                        subtitle=""
                        showDollarSign
                        showDashedLine
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled = {true}
                      />
                      
                    </td>
                    <td colSpan={3}>
                      <CMSCurrencyInput
                        isBlank={false}
                        elementName="amountPaid" //same name
                        number="29"
                        title="Amount Paid"
                        subtitle=""
                        showDollarSign
                        showDashedLine
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={true}
                      />
                    </td>
                    <td colSpan={3}>
                      <CMSCurrencyInput
                        isBlank={false}
                        elementName="nucc30"  //reservedForNUCC
                        number="30"
                        title=""
                        subtitle="Rsvd for NUCC Use"
                        showDashedLine
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr id="row25">
                    <td colSpan={7} rowSpan={2}>
                      <div className="colContainer">
                        <CMSTitle
                          elementName="physicianSignature"
                          number="31"
                          title="Signature of Physician or Supplier"
                          allowWrap
                        />
                        <label className="text label" style={{ marginLeft: 14, lineHeight: '120%' }}>
                          {(`Including Degrees or Credentials`).toUpperCase()}
                          <br />
                          {`(I certify that the statements on the reverse`}
                          <br />
                          {`apply to this bill and are made a part thereof.)`}
                        </label>
                      </div>
                      <div className="rowContainer spaceBetween" style={{ position: "relative", top: 40 }} >
                        <label className="text label">
                          {"Signed".toUpperCase()}
                        </label>
                        <label className="text label" style={{ marginRight: 40 }}>
                          {"Date".toUpperCase()}
                        </label>
                      </div>
                      <BasicFieldWrapper
                        name="physicianSignature"  //same name
                        activeField={activeField}
                        
                      >
                        <input
                          onFocus={(e) => handleFocus(e, setFieldTouched)}
                          id="physicianSignature"
                          className="input text-uppercase"
                          style={{ width: 180, height: 16, position: "relative", top: 0 }}
                          disabled={true}
                        />
                      </BasicFieldWrapper>
                      <BasicFieldWrapper
                        name="physicianSignatureDate"  //same name
                        activeField={activeField}
                        
                      >
                       <input
                          onFocus={(e) => handleFocus(e, setFieldTouched)}
                          type="datetime"
                          id="physicianSignatureDate"
                          className="datepicker small"
                          style={{ 
                            color: getDatepickerTextColor(values.physicianSignatureDate), 
                            width: 120, 
                            position: "relative",
                            left: 80
                          }}
                          disabled={true}
                        />
                      </BasicFieldWrapper>
                    </td>
                    <td colSpan={10} rowSpan={1}>
                      {/* <CMSTextAreaInput
                        elementName="serviceFacilityLocation"
                        number="32"
                        title="Service Facility Location Information"
                        subtitle=""
                        rows={3}
                        doubleHeight={true}
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                      /> */}
                          <CMSTitle
                            elementName="serviceFacilityLocation"
                            number="32"
                            title="Service Facility Location Information"
                            subtitle=""
                            //doubleHeight={true}
                          />
                          <div className="colContainer">
                            <BasicFieldWrapper
                              //name="serviceProviderName + serviceProviderAddress + serviceProviderCity + serviceProviderState + serviceProviderZip"
                              name="serviceProviderName"
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                //id="serviceProviderName + serviceProviderAddress + serviceProviderCity + serviceProviderState + serviceProviderZip"
                                id="serviceProviderName"
                                style={{ marginTop:'4px',width: 300 }}
                                rows={1}
                                className="inputInline"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                            <BasicFieldWrapper
                              name="serviceProviderAddress"
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                id="serviceProviderAddress"
                                style={{ width: 300 }}
                                rows={1}
                                className="inputInline"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                            <div className="rowContainer">
                            <BasicFieldWrapper
                              name="serviceProviderCity" //Joseph will created a new field to concatenate city,state,zip
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                id="serviceProviderCity"
                                // style={{ width: 200 }}
                                rows={1}
                                className="inputInline"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>   
                            {/* <div className="rowContainer"> */}
                              <BasicFieldWrapper
                                name="serviceProviderState" 
                                activeField={activeField}
                                

                              >
                                <input
                                  type="text"
                                  id="serviceProviderState"
                                  style={{ width: 25 }}
                                  rows={1}
                                  className="inputInline"
                                  disabled = {true}
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                />
                              </BasicFieldWrapper> 
                              <BasicFieldWrapper
                                name="serviceProviderZip" 
                                activeField={activeField}
                                
                              >
                                <input
                                  type="text"
                                  id="serviceProviderZip"
                                  style={{ width: 50 }}
                                  rows={1}
                                  className="inputInline"
                                  disabled = {true}
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                />
                              </BasicFieldWrapper>     
                            </div>    
                          </div>
                    </td>
                    <td colSpan={9} rowSpan={1}>
                      <CMSInputWrapper
                        elementName="billingProviderInfo"
                        number="33"
                        title="Billing Provider Info &amp; PH #"
                        subtitle=""
                        doubleHeight={true}
                        inlineComponent={
                          <div className="rowContainer">
                            <span className="text">{`(`}</span>
                            <BasicFieldWrapper
                              name="billingProviderAreaCode"
                              activeField={activeField}
                              
                            >
                              <input
                                id={"billingProviderAreaCode"}
                                className="inputInline"
                                maxLength={3}
                                style={{ width: 25 }}
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                            <span className="text">{`)`}</span>
                            <BasicFieldWrapper
                              name="billingProviderPhone"
                              activeField={activeField}
                              
                            >
                              <input
                                id={"billingProviderPhone"}
                                className="inputInline"
                                maxLength={7}
                                style={{ width: 60 }}
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                          </div>
                        }
                      >
                        <div className="colContainer">
                            <BasicFieldWrapper
                              name="billingProviderName"
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                id="billingProviderName"
                                style={{ width: 300 }}
                                className="inputInline"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                            <BasicFieldWrapper
                              name="billingProviderAddress"
                              activeField={activeField}
                              
                            >
                              <input
                                type="text"
                                id="billingProviderAddress"
                                style={{ width: 300 }}
                                rows={1}
                                className="inputInline"
                                disabled = {true}
                                onFocus={(e) => handleFocus(e, setFieldTouched)}
                              />
                            </BasicFieldWrapper>
                            <div className="rowContainer">
                            <BasicFieldWrapper
                                name="billingProviderCity" //Joseph will created a new field to concatenate city,state,zip
                                activeField={activeField}
                                
                              >
                                <input
                                  type="text"
                                  id="billingProviderCity"
                                  // style={{ width: 100 }}
                                  rows={1}
                                  className="inputInline"
                                  disabled = {true}
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                />
                              </BasicFieldWrapper>
                            {/* <div className="rowContainer"> */}
                              <BasicFieldWrapper
                                name="billingProviderState" //Joseph will created a new field to concatenate city,state,zip
                                activeField={activeField}
                                
                              >
                                <input
                                  type="text"
                                  id="billingProviderState"
                                  style={{ width: 25 }}
                                  rows={1}
                                  className="inputInline"
                                  disabled = {true}
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                />
                              </BasicFieldWrapper>
                              <BasicFieldWrapper
                                name="billingProviderZip" 
                                activeField={activeField}
                                
                              >
                                <input
                                  type="text"
                                  id="billingProviderZip"
                                  style={{ width: 50 }}
                                  rows={1}
                                  className="inputInline"
                                  disabled = {true}
                                  onFocus={(e) => handleFocus(e, setFieldTouched)}
                                />
                              </BasicFieldWrapper>     
                            </div> 
                          </div>
                        </CMSInputWrapper>                               
                    </td>
                  </tr>
                  <tr id="row26">
                    <td colSpan={4} className="npiBackground">
                      <div className="rowContainer">
                        <CMSTitle
                          elementName="a"
                          number="a"
                          title=""
                          subtitle=""
                        />
                        <BasicFieldWrapper
                          name="serviceProviderNpi"  //serviceFacilityNpi
                          validate={validateNpi}
                          activeField={activeField}
                          
                        >
                          <input
                            type="text"
                            className="input center"
                            //placeholder="NPI"
                            maxLength={10}
                            disabled = {true}
                            onFocus={(e) => handleFocus(e, setFieldTouched)}
                          />
                        </BasicFieldWrapper>
                      </div>
                    </td>
                    <td colSpan={6} className="disabled">
                      <div className="rowContainer">
                        <CMSTitle
                          elementName="b"
                          number="b"
                          title=""
                          subtitle=""
                        />
                        <BasicFieldWrapper
                          name="serviceProviderOtherId"  //serviceFacilityOtherId
                          //validate={validateNpi}
                          activeField={activeField}
                          
                        >
                          <input
                            type="text"
                            className="input center disabled"
                            maxLength={10}
                            disabled = {true}
                            onFocus={(e) => handleFocus(e, setFieldTouched)}
                            onMouseOver={(e)=>hoverFunction2(e, "tc")}
                          />
                        </BasicFieldWrapper>
                      </div>
                    </td>
                    <td colSpan={4} className="npiBackground">
                      <div className="rowContainer">
                        <CMSTitle
                          elementName="a"
                          number="a"
                          title=""
                          subtitle=""
                        />
                        <BasicFieldWrapper
                          name="billingProviderNpi"  //same name
                          validate={validateNpi}
                          activeField={activeField}
                          
                        >
                          <input
                            type="text"
                            className="input center"
                            //placeholder="NPI"
                            maxLength={10}
                            disabled = {true}
                            onFocus={(e) => handleFocus(e, setFieldTouched)}
                          />
                        </BasicFieldWrapper>
                      </div>
                    </td>
                    <td colSpan={5} className="disabled">
                      <div className="rowContainer">
                        <CMSTitle
                          elementName="b"
                          number="b"
                          title=""
                          subtitle=""
                        />
                        <BasicFieldWrapper
                          name="billingProviderOtherId" //same name
                          activeField={activeField}
                        >
                          <input
                            type="text"
                            className="input center disabled"
                            onFocus={(e) => handleFocus(e, setFieldTouched)}
                            onMouseOver={(e)=>hoverFunction2(e, "tc")}
                            disabled = {true}
                          />
                        </BasicFieldWrapper>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="rotatedTextContainer" style={{ height: 577 }}>
                  <div className="arrowLine" style={{ height: 577 }}></div>
                  <label
                    className="rotatedText text d-flex justify-content-between"
                    style={{
                      width: 577,
                      top: 289,
                      transform:
                        "translateX(-49.6%) translateY(-50%) rotate(-90deg)",
                    }}
                  >
                    <span>{`<`}</span>
                    <span className="bgWhite">
                      &ensp;&ensp;{`Physician or Supplier Information`.toUpperCase()}&ensp;&ensp;
                    </span>
                    <span>{`>`}</span>
                  </label>
                </div>
              </div>
              <h2
                className="text subheader d-flex justify-content-between"
                style={{ textAlign: "right", width: 808 }}
              >
                <span
                  style={{ fontWeight: 400 }}
                >{`NUCC Instruction Manual available at: www.nucc.org`}</span>
                <span style={{ fontStyle: "italic", fontWeight: 800 }}>
                  {`Please Print or Type`.toUpperCase()}
                  <div >
                      <Field name="formInd">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 200, height:15}}
                            {...field}
                          />
                        )}
                      </Field>
                  </div>
                 </span>
                <span style={{ fontWeight: 400 }}>
                  {"Approved OMB-0938-1197 Form 1500 (02-12)".toUpperCase()}
                </span>
                </h2>
                {/* <h2
                className="text subheader d-flex justify-content-between"
                style={{ textAlign: "right" }}
                style={{ width: 808 }}
                >
                <tr>
                <td colSpan={10}
                style={{ textAlign: "right" }}>
                      <div className="rowContainer">
                      <Field name="formInd">
                        {({ field, form, meta }) => (
                          <input
                            disabled={getDisabledState(true)}
                            className="input"
                            style={{ width: 390, height:20}}
                            {...field}
                          />
                        )}
                      </Field>
                      </div>
                      </td>
                  </tr>
                  </h2> */}
             
            </form>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default CMSForm;
