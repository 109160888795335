import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class UserGuide extends Component {
    state = {  }
    render() { 
        return ( 
            <Container  fluid >
                <h4 style={{textAlign:'center'}}>Provider Claim Review<br/>User Guide</h4>
                <h6>Recommended Browser</h6>
                <p>Google Chrome<br/><a href='https://www.google.com/chrome/fast-and-secure/'>Download Chrome</a></p>
                <h6>Recommended monitor settings</h6>
                We recommend the use of the maximum resolution setting for the monitor you are using. Most modern monitors have a maximum resolution of 1920 x 1080 pixels. This resolutionn is most times called 1080p or 1080i.
            </Container>
         );
    }
}
 
export default UserGuide;