import React from 'react';
import {withRouter} from 'react-router'
import {Card, Button, Container} from 'react-bootstrap'

const Oops2 = () => {
    const closeHandler=()=>{
        localStorage.clear()
        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
    }
    return ( 
        <Container className='text-center fluid justify-content-center' style={{width:'1920px'}}>
        
        <Card className='justify-content-center' style={{width:'480px'}}>
            {/* <Card.Img variant="top" src={process.env.PUBLIC_URL + "/UHCCommunity.png"} height='80px' className='text-center'/> */}
            <Card.Header className='text-center'><h5>Oops!</h5></Card.Header>
            <Card.Body>
            <Card.Text className='text-center'>
            <p>You do not have any packages to review</p>
            <p>When packages need to be reviewed, you will receive an email notification.</p>
            <Button variant="warning" onClick={closeHandler}>Close</Button>
            </Card.Text>
            </Card.Body>
        </Card>
        </Container>
     );
}
 
export default withRouter(Oops2);