import React, { Component } from 'react';
import Pdf from '../components/optum-pcr-ref-sheet.pdf';
class FAQ extends Component {
    state = {  }

    // componentDidMount() {
    //     window.open(`${process.env.PUBLIC_URL}/optum-pcr-ref-sheet.pdf`, 'FAQViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    //   }

    render() { 
        return (   

        <a href={Pdf} without rel="noopener noreferrer" target="_blank">
           <button trailingIcon="picture_as_pdf" label="Resume">
             PDF
           </button>
        </a>)
    }
}
 
export default FAQ;