import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

const CountDown = (props) => {
  const [value, setValue] = useState(new Date());

  //   useEffect(() => {
  //     const interval = setInterval(() => setValue(new Date()), 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <div>
        <p style={{ padding: 0, margin: 0 }}>Days: {days}</p>
        <p style={{ padding: 0, margin: 0 }}>Hours: {hours}</p>
        <p style={{ padding: 0, margin: 0 }}>Minutes: {minutes}</p>
        <p style={{ padding: 0, margin: 0 }}>Seconds: {seconds}</p>
      </div>
    );
  };

  return (
    <div style={{ padding: 15, width: 150 }}>
      <Countdown date={Date.now() + props.duration} renderer={renderer} />
    </div>
  );
};

export default CountDown;
