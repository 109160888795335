import React, { Component } from "react";
import { Card, Container, Row, Col, InputGroup, Form } from "react-bootstrap";
//import constant from '../../../constants.json';
import pjson from "../package.json";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
// const {remote} = window.require('electron') //,  app
// const {app, dialog} = remote
// const path = require('path')
// const fs = window.require('fs')
// import {config} from 'dotenv'

class Login extends Component {
  state = {
    u: "",
    p: "",
    tin: "592292041,760459500,050368134,133727784,464690219,260834681,383193801,208457896,300791563", //"232837433,463318810,251876056,232176338,481088982,480125463",
    value: "Enter MS Logon",
    userDetails: {},
    auth: false,
    error: false,
    environment: process.env.REACT_APP_ENV||"Dev",
    showEnv: false,
  };

  componentDidMount() {
    // localStorage.clear()
    // let history=useHistory()
  }

  // getGroups=(str)=>{
  //     var grp
  //     for (const val of str) {
  //         grp=this.grabContent(val,'CN=',',')
  //         switch(grp){
  //         //case 'OIS_SQL_DBA':
  //         case 'O_OIS_DEV':
  //             if(this.state.environment!=='Prod')
  //             {
  //                 console.log('DBA-1,DEV')
  //                 localStorage.setItem('accessId',1)
  //                 return 1
  //             }
  //             break
  //         case 'O_OIS_OPS':
  //             if(this.state.environment!=='Prod')
  //             {
  //                 console.log('DBA-1,OPS')
  //                 localStorage.setItem('accessId',1)
  //                 return 1
  //             }
  //             break
  //         case 'JADE_PRD_MANAGER':
  //             if(this.state.environment==='Prod')
  //             {
  //                 console.log('MANAGER-2-Prod')
  //                 localStorage.setItem('accessId',2)
  //                 return 2
  //             }
  //             break
  //         case 'JADE_STG_MANAGER':
  //             if(this.state.environment==='Stg')
  //             {
  //                 console.log('MANAGER-2-Stg')
  //                 localStorage.setItem('accessId',2)
  //                 return 2
  //             }
  //             break
  //         case 'JADE_PRD_USER':
  //             if(this.state.environment==='Prod')
  //             {
  //                 console.log('USER-4-Prod')
  //                 localStorage.setItem('accessId',4)
  //                 return 4
  //             }
  //             break
  //         case 'JADE_STG_USER':
  //             if(this.state.environment==='Stg')
  //             {
  //                 console.log('USER-4-Stg')
  //                 localStorage.setItem('accessId',4)
  //                 return 4
  //             }
  //             break
  //         case 'JADE_PRD_QA':
  //             if(this.state.environment==='Prod')
  //             {
  //                 console.log('QA-5-Prod')
  //                 localStorage.setItem('accessId',5)
  //                 return 5
  //             }
  //             break
  //         case 'JADE_STG_QA':
  //             if(this.state.environment==='Stg')
  //             {
  //                 console.log('USER-5-Stg')
  //                 localStorage.setItem('accessId',5)
  //                 return 5
  //             }
  //             break
  //         default:
  //             //console.log('Default')
  //         }
  //     }
  //     localStorage.setItem('accessId',0)
  //     return 0
  // }

  //     grabContent=(str, start, end)=> {
  //         var startLen = start.length;
  //         var s = str.indexOf(start);
  //         var e = str.indexOf(end);
  //         var scrape = String(str).substring(s+startLen, e);
  //         return scrape;
  //    }

  submitHandler = (event) => {
    event.preventDefault();
    // this.cleanTempFolder()
    localStorage.setItem("environment", this.state.environment);
    let authUrl = "";
    let apiUrl=''
    switch (this.state.environment) {
      case "Prod":
        authUrl = process.env.REACT_APP_AUTH_URL;
        apiUrl=process.env.REACT_APP_API_URL;
        // localStorage.setItem("apiUrl", apiUrl);
        localStorage.setItem("nxUrl", process.env.REACT_APP_NX_URL);
        localStorage.setItem("navHeader", "");
        break;
      case "Stg":
        authUrl = process.env.REACT_APP_AUTH_URL;
        apiUrl=process.env.REACT_APP_API_URL
        // localStorage.setItem('apiUrl','http://apsrp07916.uhc.com:8088/mpa')
        // localStorage.setItem(
        //   "apiUrl",
        //   apiUrl
        // );
        localStorage.setItem("nxUrl", process.env.REACT_APP_NX_URL);
        localStorage.setItem("navHeader", "Stg");
        break;
      case "Dev":
        authUrl = process.env.REACT_APP_AUTH_URL;
        apiUrl=process.env.REACT_APP_API_URL
        // localStorage.setItem(
        //   "apiUrl",
        //   apiUrl
        // );
        // localStorage.setItem('apiUrl','http://apsrp07918.uhc.com:8088/mpa')
        localStorage.setItem("nxUrl", process.env.REACT_APP_NX_URL);
        localStorage.setItem("navHeader", "Dev");
        break;
      default:
        authUrl = process.env.REACT_APP_AUTH_URL;
        apiUrl=process.env.REACT_APP_API_URL
        // localStorage.setItem(
        //   "apiUrl",
        //   apiUrl
        // );
        // localStorage.setItem('apiUrl','http://apsrp07918.uhc.com:8088/mpa')
        localStorage.setItem("nxUrl", process.env.REACT_APP_NX_URL);
        localStorage.setItem("navHeader", "Dev");
    }
    localStorage.setItem("authUrl", authUrl);
    localStorage.setItem("apiUrl", apiUrl);
    let data = {};
    console.log(this.state);
    data.u = this.state.u;
    data.p = this.state.p;
    data.tin = this.state.tin;
    data.app = `${pjson.description} v${pjson.version}`;
    fetch(`${authUrl}/dms/aaa`, {
      method: "post",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(async(res) => {
        //do a few checking on this one.
        //console.log(JSON.stringify(res))
        if (!res.authenticated) {
          // dialog.showMessageBoxSync({type:"info",buttons:["OK"],message:"Login Failure: You may have typed the wrong password or you may not belong to the group authorized to use the application. Please go to https://secure.optum.com Exiting application"})
          localStorage.clear();
          // app.quit()
        } else {
          localStorage.setItem("auth", res.authenticated);
          data.auth = res.authenticated;
          localStorage.setItem("displayName", res.displayName);
          data.displayName = res.displayName;
          localStorage.setItem("u", res.cn);
          data.u = res.cn;
          localStorage.setItem("userkey", res.cn);
          data.userkey = res.cn;
          data.userEmail = res.mail;
          localStorage.setItem("userEmail", res.mail);
          localStorage.setItem("memberOf", res.memberOf);
          localStorage.setItem("tin", this.state.tin);
          // localStorage.setItem('auth',data.auth)
          // localStorage.setItem('displayName',data.displayName)
          // localStorage.setItem('u',data.u)
          // localStorage.setItem('userkey', data.u)
          // localStorage.setItem('tin', data.tin)
          localStorage.setItem(
            "loginExpiration",
            moment().add(8, "hours")
          );
          localStorage.setItem("filter", 'adjusted')
          localStorage.setItem('qIndex', 0)
          localStorage.setItem('active', true)
          console.log(pjson.description);
          console.log(pjson.version);
          localStorage.setItem("appDescription", pjson.description);
          localStorage.setItem("appVersion", pjson.version);

          let r = await fetch(localStorage.getItem("apiUrl") + `/getRatings?user=${localStorage.getItem("u")}&appName=Jade External PCR`);
          let rj = await r.json();
          if (rj && rj.rows && rj.rows.length > 0) {
              //already has entry in ratings
              console.log("already has entry in ratings");
              localStorage.setItem("appRating", JSON.stringify(rj.rows[0].rating));
          }
          
          this.props.history.push({pathname:"/dashboard", filter:'all'}); //
          this.postRemoveUserOk()
          window.location.reload()
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postRemoveUserOk = () => {
    let forPosting = {
        "u" : localStorage.getItem("u")
    }

    fetch(
        `${localStorage.getItem("apiUrl")}/removeUserOk`,//|| "http://apsrp07890.uhc.com:8888"
        {
            method: "post",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(forPosting),
        }
    )
    .then((res) => {
    })
    .catch(console.log);
}

  // cleanTempFolder=()=>{
  //     let tempFolder=path.join(`${app.getPath('userData')}`,'temp')
  //     if (!fs.existsSync(tempFolder)) {
  //         //console.log('CTF-1:'+tempFolder)
  //         fs.mkdirSync(tempFolder,{recursive:true})
  //     }
  //     if (fs.existsSync(tempFolder)) {
  //         //console.log('CTF-2:'+tempFolder)
  //         fs.readdir(tempFolder, (err, files) => {
  //             if (err) console.log(err)
  //             for (const file of files) {
  //                 //console.log('CTF-3:'+file)
  //                 fs.unlink(path.join(tempFolder, file), err => {
  //                     if (err) console.log(err)
  //                 })
  //             }
  //         })
  //     }
  // }

  logoffHandler = () => {
    let forPosting = {
      product: "JadeXPlus",
      class: "SECURITY_AUDIT",
      sourceUserMSID:
        !this.state.u || this.state.u === "" ? "User unknown" : this.state.u,
      message: "TerminateUserSession:SUCCESS",
      severity: "INFO",
    };
    // console.log('starting the post')
    // we have no authUrl or user yet at this point so we have to go to default.
    fetch(
      `${
        localStorage.getItem("authUrl")
      }/dms/post6cLog`,
      {
        method: "post",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(forPosting),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        localStorage.clear();
        // window.close()
        // app.quit()
        this.history.push({pathname:"/"});
        window.location.reload()
      })
      .catch(console.log);
  };

  handleChange = (event) => {
    let k = event.target.name;
    this.setState({ [k]: event.target.value });
  };

  handleEnvChange = (e) => {
    this.setState({ environment: e.target.value });
    // console.log(e.target.value)
  };

  handleMSIDChange = (e) => {
    let i1 = e.target.value.indexOf(".");
    if (i1 >= 0) {
      if (this.state.showEnv) {
        this.setState({ showEnv: false });
      } else {
        this.setState({ showEnv: true });
      }
    } else {
      this.setState({ u: e.target.value });
    }
  };

  handleTINChange = (e) => {
    this.setState({ tin: e.target.value });
  };

  render() {
    return (
      <>
        <Container style={{ paddingTop: "200px" }}>
          <Row>
            <Col lg={4}></Col>
            <Col lg={4}>
              <Card>
                {/* <Card.Img variant="top" src={process.env.PUBLIC_URL + '/optum_2x.png'} /> */}
                {/* <Card.Img variant="top" height='115px' src={process.env.PUBLIC_URL + '/jadeMPA.png'} /> */}
                {/* <img src={process.env.PUBLIC_URL + '/jadeMPA.png'} align="middle" height='50px' alt='Optumlogo'/> */}
                <Card.Header
                  as="h2"
                  style={{
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                    color: "Gray",
                  }}
                >
                  Optum PCR
                </Card.Header>
                <Card.Body>
                  <form onSubmit={this.submitHandler.bind(this)}>
                    <div className="form-group">
                      <label htmlFor="u">MS ID</label>
                      <input
                        type="text"
                        autoFocus
                        className="form-control"
                        id="u"
                        name="u"
                        aria-describedby="emailHelp"
                        placeholder="Enter MS Logon"
                        onChange={this.handleMSIDChange.bind(this)}
                        value={this.state.u}
                      />
                    </div>
                    <div>&emsp;</div>
                    <div className="form-group">
                      <label htmlFor="p">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="p"
                        name="p"
                        placeholder="Your Password"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.p}
                      ></input>
                    </div>
                    <div>&emsp;</div>
                    <div style={{ textAlign: "center" }}>
                      <button
                        type="submit"
                        className="btn btn-warning"
                        id="login"
                        size="sm"
                      >
                        Logon
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-warning"
                        id="logoff"
                        onClick={this.logoffHandler}
                        size="sm"
                      >
                        Logoff
                      </button>
                    </div>

                    <div className="form-group">
                      <label htmlFor="tin">TIN(s)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tin"
                        name="tin"
                        aria-describedby="emailHelp"
                        placeholder="TIN,TIN,..."
                        onChange={this.handleTINChange.bind(this)}
                        value={this.state.tin}
                      />
                    </div>

                    <div>&emsp;</div>
                  </form>
                </Card.Body>
                <Card.Footer className="text-center">
                  <img
                    src={process.env.PUBLIC_URL + "/optum_2x.png"}
                    alt="optum logo"
                    height="30px"
                  ></img>{" "}
                </Card.Footer>
              </Card>
            </Col>
            <Col lg={4}></Col>
          </Row>
        </Container>
        <div style={{ display: this.state.showEnv ? "block" : "none" }}>
          <Row style={{ width: "100%" }}>
            <Col sm={5}></Col>
            <Col sm={2}>
              <InputGroup className="mb-2" fluid="true">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{ height: "25px", fontWeight: "bold" }}
                  >
                    Environment:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={this.state.environment}
                  style={{ height: "25px", padding: "0px", margin: "0px" }}
                  onChange={this.handleEnvChange}
                >
                  <option value="Prod">Prod</option>
                  <option value="Dev">Dev</option>
                  <option value="Stg">Stg</option>
                </Form.Control>
              </InputGroup>
            </Col>
            <Col sm={5}></Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(Login);
