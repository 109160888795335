import React, { useState, useEffect } from "react";
import "./UB04.css";
import thickWhiteArrow from "./images/thickWhiteArrow.png";
import greyRectangle from "./images/greyRectangle.png";
import NUBClogo from "./images/NUBCLogo.png";
import InvoiceLine from "./InvoiceLine";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import { Formik, Field } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import { textAlign, width } from "@mui/system";
import { last } from "underscore";

const schema = yup.object().shape({
  services: yup.array().of(
    yup.object().shape({
      val1: yup.string(),
      val2: yup.mixed(),
    })
  ),
  patientControlNumber: yup.string(),
  val4: yup.string().nullable(),
});

// const MakeRow0Cols = () => {
//   return (
//     <>
//       {Array.from({ length: 84 * 3 }, (value, key) => (
//         <td />
//       ))}
//     </>
//   );
// };

const TextInput = (props) => {
  const {
    name,
    size,
    prompt,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    placeHolder,
    placeHolderId,
  } = props;

  return (
    <BasicFieldWrapper name={name} activeField={activeField}>
      <input
        className="input"
        size={size}
        prompt={prompt}
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
        placeholder={placeHolder ? placeHolder : undefined}
        id={placeHolderId ? placeHolderId : undefined}
      />
    </BasicFieldWrapper>
  );
};

const TextAreaInput = (props) => {
  const {
    elementName,
    size,
    rows,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    cols,
  } = props;
  return (
    <BasicFieldWrapper name={elementName} activeField={activeField}>
      <textarea
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        disabled={disabled}
        className="input"
        size={size}
        rows={rows}
        cols={cols ? cols : undefined}
        style={inputStyle ? inputStyle : undefined}
      />
    </BasicFieldWrapper>
  );
};

const DateInput = (props) => {
  const {
    name,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
  } = props;
  return (
    <BasicFieldWrapper
      name={name} // DATE NAME CHANGED
      activeField={activeField}
    >
      <input
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        type="text"
        className="input center dateInput"
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
      />
    </BasicFieldWrapper>
  );
};

const BasicFieldWrapper = (props) => {
  const {
    name,
    activeField,
    validate,
    description,
    id,
    children,
    wrapperStyles,
    isBlank,
  } = props;

  return (
    <Field
      name={name}
      validate={validate ? validate : undefined}
      id={id ? id : undefined}
    >
      {({ field, form, meta }) => (
        <ErrorAndDescriptionWrapper
          name={field.name}
          error={meta.error}
          activeField={activeField}
          description={description}
        >
          <div className="rowContainer" style={wrapperStyles}>
            {React.cloneElement(children, { ...field })}
            {meta.error && (
              <ExclamationTriangleFill
                color={"var(--optum-error-state-red)"}
                size={12}
              />
            )}
          </div>
        </ErrorAndDescriptionWrapper>
      )}
    </Field>
  );
};

const ErrorAndDescriptionWrapper = (props) => {
  const { error, description, name, activeField, children } = props;
  return (
    <ConditionalWrapper
      condition={error || description?.title}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          show={activeField === name}
          overlay={getPopoverStyling(error, description)}
        >
          {children}
        </OverlayTrigger>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children, descriptions }) =>
  condition ? wrapper(children, descriptions) : children;

const getPopoverStyling = (error, description) => {
  if (error)
    return (
      <Popover className="popoverError">
        <Popover.Content>{error}</Popover.Content>
      </Popover>
    );
  else if (description?.title)
    return (
      <Popover>
        <Popover.Title as="h3">{description.title}</Popover.Title>
        <Popover.Content>{description.long}</Popover.Content>
      </Popover>
    );
};

const Make22Lines = (props) => {
  const {
    handleFocus,
    setFieldTouched,
    activeField,
    adjustedClaim,
    currentPage,
  } = props;

  return (
    <>
      {Array.from({ length: 22 }, (value, key) => (
        <InvoiceLine
          rowNo={key + 1 + (currentPage - 1) * 22}
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          adjustedClaim={adjustedClaim}
          isBlank={
            key + 1 + (currentPage - 1) * 22 > adjustedClaim.serviceLines.length
          }
          currentPage={currentPage}
        />
      ))}
    </>
  );
};

const UB04 = (props) => {
  const [activeField, setActiveField] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  // console.log(currentPage)
  useEffect(() => {
    setCurrentPage(props.currentPage);
    setMaxPage(props.maxPage);
  }, [props.currentPage]);

  const validateUB04 = async (values) => {
    var errors = {};
    errors = {};
    console.log("Errors: ", errors);
    console.log("values: ", values);
    return errors;
  };

  const handleFocus = (e, setFieldTouched) => {
    setActiveField(e.target.name);
    if (setFieldTouched !== undefined) setFieldTouched(e.target.name);
  };

  const initialClaim = {
    _id: "6699f32b5ab23e9d12ee5091",
    claimskey: "481965",
    lob: "MC",
    clientid: "UHC",
    projectid: "TEST_PCR_UHC_CA_2024_GRP1",
    market: "CA",
    tax_id: "777777777",
    chartid: "13e2894cd693e6c5e0631c9e330ab825",
    inventorykey: "154897",
    claims_status: null,
    assigneduser: null,
    newclaim: false,
    loadDate: "2024-07-30T15:39:10.096Z",
    project: {
      _id: "6699d43c5ab23e9d12ee5088",
      projectkey: "223",
      lob: "MC",
      clientid: "UHC",
      program: "PCR",
      projectid: "TEST_PCR_UHC_CA_2024_GRP1",
      projectname: "MCAIDCR_ACCA_07/01/2023-06/30/2024",
      projectstartdate: "2024-07-18T00:00:00",
      projectenddate: "2024-12-13T00:00:00",
      audityear: 2024,
      audittype: null,
      userkey: null,
      active: true,
      projectstatus: null,
      market: "CA",
      reportingstartdate: "2024-01-01T00:00:00",
      reportingenddate: "2024-12-31T00:00:00",
      csapprovedtargetfiledate: null,
      csfinalresultsdate: "2024-12-16T00:00:00",
      csencountersubmissiondate: "2024-12-31T00:00:00",
      portal: true,
      dos_start: null,
      dos_end: null,
    },
    provider: {
      _id: "6699d75a5ab23e9d12ee508b",
      providerkey: "203747",
      clientid: "UHC",
      siteid: null,
      providerid: "TEST_1234567",
      groupid: "777777777",
      lastname: "WAYNE",
      firstname: "BRUCE",
      address1: "1007 MOUNTAIN DRIVE",
      address2: null,
      city: "GOTHAM",
      state: "CA",
      zip: "12345",
      phone: "1112223333",
      phoneextension: null,
      altphone: null,
      altcontactkey1: null,
      altcontactkey2: null,
      fax: null,
      email: null,
      npi: null,
      lastupdateby: null,
      lastupdatedate: null,
      contactconfirmationlink: null,
      contactconfirmationsavedate: null,
      group_score: 1.234,
      address_score: 2.345,
      groupname: "WAYNE HEALTH",
      nameconversion: "CSPCR_CA34_JAN24DEC24_WAYNEHEALTH_WAYNEBRUCE_777777777_",
      providertin: null,
      lob: "MC",
      maincontact_nxuid: null,
      maincontact_nxpath: null,
      program: "PCR",
      altContact1: null,
      altContact2: null,
    },
    inventory: {
      _id: "6699d9c45ab23e9d12ee508e",
      inventorykey: "154897",
      inventorystatus: null,
      userkey: null,
      inventoryproviderkey: "203747",
      lastfollowupdate: null,
      newclaimscount: 0,
      adjclaimscount: 1,
      totalclaimscount: 1,
      lob: "MC",
      clientid: "UHC",
      projectkey: "223",
      queue: "CA Priority",
      project: {
        _id: "6699d43c5ab23e9d12ee5088",
        projectkey: "223",
        lob: "MC",
        clientid: "UHC",
        program: "PCR",
        projectid: "TEST_PCR_UHC_CA_2024_GRP1",
        projectname: "MCAIDCR_ACCA_07/01/2023-06/30/2024",
        projectstartdate: "2024-07-18T00:00:00",
        projectenddate: "2024-12-13T00:00:00",
        audityear: 2024,
        audittype: null,
        userkey: null,
        active: true,
        projectstatus: null,
        market: "CA",
        reportingstartdate: "2024-01-01T00:00:00",
        reportingenddate: "2024-12-31T00:00:00",
        csapprovedtargetfiledate: null,
        csfinalresultsdate: "2024-12-16T00:00:00",
        csencountersubmissiondate: "2024-12-31T00:00:00",
        portal: true,
        dos_start: null,
        dos_end: null,
      },
      provider: {
        _id: "6699d75a5ab23e9d12ee508b",
        providerkey: "203747",
        clientid: "UHC",
        siteid: null,
        providerid: "TEST_1234567",
        groupid: "777777777",
        lastname: "WAYNE",
        firstname: "BRUCE",
        address1: "1007 MOUNTAIN DRIVE",
        address2: "APT 1A",
        city: "GOTHAM",
        state: "CA",
        zip: "12345",
        phone: "1112223333",
        phoneextension: null,
        altphone: null,
        altcontactkey1: null,
        altcontactkey2: null,
        fax: null,
        email: null,
        npi: null,
        lastupdateby: null,
        lastupdatedate: null,
        contactconfirmationlink: null,
        contactconfirmationsavedate: null,
        group_score: 1.234,
        address_score: 2.345,
        groupname: "WAYNE HEALTH",
        nameconversion:
          "CSPCR_CA34_JAN24DEC24_WAYNEHEALTH_WAYNEBRUCE_777777777_",
        providertin: null,
        lob: "MC",
        maincontact_nxuid: null,
        maincontact_nxpath: null,
        program: "PCR",
        altContact1: null,
        altContact2: null,
      },
      inventoryHistory: [],
    },
    claimType: "I",
    payer: {
      name: "MEDICAID NE UHC COMMUNITY PLAN",
      idQualifier: "PI",
      id: "87726",
      address: "PO BOX 31365",
      address2: "APT 3B",
      city: "SALT LAKE CITY",
      state: "UT",
      zip: "841310365",
    },
    payerClaimControlNumber: "23R326478200",
    accidentState: null,
    admitDate: "20230801",
    admitTime: "2000",
    admittingDiagnosisCode: "R45851",
    anotherHealthBenefitPlan: "Y",
    assignmentorPlanParticipationCode: "A",
    attendingProvider: {
      entityIdentifierCode: "71",
      entityTypeQualifier: "1",
      npi: "1942314604",
      idQualifier: "G1",
      id: "123456",
      lastName: "SATTAR",
      firstName: "S PIRZADA",
      middleName: null,
      providerCode: "AT",
      taxQualifier: "PXC",
      taxonomyCode: ["2084P0800X", "12365147623"],
    },
    billingProvider: {
      lastNameOrOrganization: "JENNIE EDMUNDSON HOSPITAL",
      idQualifier: null,
      id: null,
      npi: "1457448755",
      address: "933 E PIERCE ST",
      address2: "APT 2D",
      city: "COUNCIL BLUFFS",
      state: "IA",
      zip: "515034626",
      referenceIdQualifier: "EI",
      tin: "420680355",
      contactFunctionCode: "IC",
      contactName: "BUSINESS OFFICE",
      communicationNumberQualifier: "TE",
      communicationNumber: "4023544230",
      phone: "4023544230",
      payToName: "Optum",
      payToAddress: "PO BOX 2797",
      payToAddress2: "APT 1B",
      payToCity: "OMAHA",
      payToState: "NE",
      payToZip: "681032797",
      payToPhone: "1234567891",
    },
    claimFilingIndicatorCode: "HM",
    claimFrequencyCode: "1",
    conditionCodes: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
    ],
    accidentState: "IL",
    dataSource: "837",
    icdCodeOrig: [
      "F332",
      "R45851",
      "F1024",
      "Z91128",
      "F419",
      "E785",
      "I252",
      "F17290",
      "F4312",
      "Z7389",
      "Z7982",
      "T50916A",
      "T43226A",
    ],
    icdCodeAdj: [
      "I119",
      "I719",
      "E669",
      "F17210",
      "I340",
      "I361",
      "Z6843",
      "Z713",
      "Z7182",
    ],
    dischargeStatus: "01",
    dischargeDate: "20230801",
    dischargeTime: "1116",
    eci: ["Y905", "Y906", "Y907"],
    facilityCodeQualifier: "A",
    facilityTin: "420680355",
    insured: [
      {
        payerName: "MEDICAID NE UHC COMMUNITY PLAN 1",
        payerId: "87726-1",
        releaseOfInformation: "Y",
        assignmentOfBenefits: "Y",
        priorPayments: "4498.43",
        estimatedAmtDue: 0,
        otherProvider: "14574487550-1",
        lastName: "HANEY 1",
        firstName: "JENNIFER",
        middleName: "G",
        employerName: "EMPLOYER 1",
        patientRelationship: "18",
        uniqueId: "123149418-1",
        groupName: "GROUP 1",
        groupNumber: "NESHAD 1",
        treatmentAuthorizationCode: 0,
        documentControlNumber: "3690637;45490986;4255;1333;",
      },
      {
        payerName: "MEDICAID NE UHC COMMUNITY PLAN 2",
        payerId: "87726-2",
        releaseOfInformation: "N",
        assignmentOfBenefits: "N",
        priorPayments: "5000.00",
        estimatedAmtDue: "5000.00",
        otherProvider: "1457448755-2",
        lastName: "HANEY 2",
        firstName: "JENNIFER",
        middleName: "G",
        employerName: "EMPLOYER 2",
        patientRelationship: "9",
        uniqueId: "123149418-2",
        groupName: "GROUP 2",
        groupNumber: "NESHAD 2",
        treatmentAuthorizationCode: 1,
        documentControlNumber: "3690637;45490986;4255;1333;",
      },
      {
        payerName: "MEDICAID NE UHC COMMUNITY PLAN 3",
        payerId: "87726-3",
        releaseOfInformation: "Y",
        assignmentOfBenefits: "N",
        priorPayments: "5000.00",
        estimatedAmtDue: "5000.00",
        otherProvider: "1457448755-2",
        lastName: "HANEY 3",
        firstName: "JENNIFER",
        middleName: "G",
        employerName: "EMPLOYER 3",
        patientRelationship: "20",
        uniqueId: "123149418-3",
        groupName: "GROUP 3",
        groupNumber: "NESHAD 3",
        treatmentAuthorizationCode: 2,
        documentControlNumber: "3690637;45490986;4255;1333;",
      },
    ],
    medicalRecordNumber: "3690637;45490986;4255;1333;",
    occurrence: [
      {
        code: "11",
        fromDate: "20230801",
        thruDate: null,
      },
      {
        code: "12",
        fromDate: "20230901",
        thruDate: null,
      },
      {
        code: "13",
        fromDate: "20231001",
        thruDate: null,
      },
      {
        code: "14",
        fromDate: "20231101",
        thruDate: null,
      },
      {
        code: "15",
        fromDate: "20230401",
        thruDate: null,
      },
      {
        code: "16",
        fromDate: "20230201",
        thruDate: null,
      },
      {
        code: "17",
        fromDate: "20230201",
        thruDate: null,
      },
      {
        code: "18",
        fromDate: "20230101",
        thruDate: null,
      },
      {
        code: "11",
        fromDate: "20230801",
        thruDate: "20230802",
      },
      {
        code: "12",
        fromDate: "20230901",
        thruDate: "20230902",
      },
      {
        code: "13",
        fromDate: "20231001",
        thruDate: "20231002",
      },
      {
        code: "14",
        fromDate: "20231101",
        thruDate: "20231102",
      },
    ],
    operatingProvider: {
      entityIdentifierCode: "72",
      entityTypeQualifier: "1",
      npi: "1942314604",
      idQualifier: "G2",
      id: "54321",
      lastName: "SATTAR",
      firstName: "S PIRZADA",
      middleName: null,
      providerCode: null,
      taxQualifier: "12",
      taxonomyCode: ["ABCD", "12345"],
    },
    otherClaimIdQualifier: ["F8", "D9", "EA"],
    otherProvider: [
      {
        entityIdentifierCode: "1",
        entityTypeQualifier: "2",
        npi: "1234556789",
        idQualifier: "G3",
        id: "98765",
        lastName: "SATTAR",
        firstName: "S PIRZADA",
        middleName: null,
        providerCode: null,
        taxQualifier: "83",
        taxonomyCode: ["EFGH", "12345"],
      },
      {
        entityIdentifierCode: "3",
        entityTypeQualifier: "4",
        npi: "987654321",
        idQualifier: "G4",
        id: "56789",
        lastName: "SATTAR",
        firstName: "S PIRZADA",
        middleName: null,
        providerCode: null,
        taxQualifier: "21",
        taxonomyCode: ["IJKL", "456789"],
      },
    ],
    patient: {
      lastName: "HANEY",
      firstName: "JENNIFER",
      middleName: "G",
      address: "5641 S 22 ST",
      address2: "APT 4",
      city: "OMAHA",
      state: "NE",
      zip: "68107",
      dateTimePeriodFormatQualifier: "D8",
      birthDate: "19790508",
      gender: "F",
      individualRelationshipCode: "18",
      payerResponsibilityCode: "S",
      policyGroup: "NESHAD",
    },
    patientControlNumber: "509714745",
    patientReasonForVisit: ["123", "456", "789"],
    pointOfOrigin: "1",
    ppsCode: "885",
    procedureGroup: [
      {
        code: "HZ2ZZZZ",
        date: "20230801",
      },
      {
        code: "HZ2ZZZZ1",
        date: "20230802",
      },
      {
        code: "HZ2ZZZZ2",
        date: "20230803",
      },
      {
        code: "HZ2ZZZZ3",
        date: "20230804",
      },
      {
        code: "HZ2ZZZZ4",
        date: "20230805",
      },
      {
        code: "HZ2ZZZZ5",
        date: "20230806",
      },
    ],
    receiver: {
      name: "DATA MANAGEMENT SERVICES",
      idQualifier: "46",
      primaryIdentifier: "11172",
    },
    releaseofInformationCode: "Y",
    remarks: "PROCESSPROMPTLY",
    serviceFacilityLocation: {
      name: null,
      address: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      id: null,
      npi: null,
    },
    serviceLine: [
      {
        lineNo: "1",
        revenueCode: "0124",
        description: "line 1 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A1",
        procedureModifier: null,
        dosFrom: "2024-01-16",
        dosTo: "2024-01-19",
        unitCount: "4",
        charges: 6000,
        nonCoveredCharges: 12.34,
        unitOrBasisForMeasurementCode: "DA",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-1",
        drugIdentification: null,
      },
      {
        lineNo: "2",
        revenueCode: "0250",
        description: "line 2 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A2",
        procedureModifier: null,
        dosFrom: "2024-01-20",
        dosTo: "2024-01-20",
        unitCount: "1",
        charges: 266.4,
        nonCoveredCharges: 56.78,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-2",
        drugIdentification: null,
      },
      {
        lineNo: "3",
        revenueCode: "0300",
        description: "line 3 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A3",
        procedureModifier: null,
        dosFrom: "2024-01-16",
        dosTo: "2024-01-20",
        unitCount: "5",
        charges: 1425,
        nonCoveredCharges: 91.23,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-3",
        drugIdentification: null,
      },
      {
        lineNo: "4",
        revenueCode: "0450",
        description: "line 4 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A4",
        procedureModifier: null,
        dosFrom: "2024-01-17",
        dosTo: "2024-01-17",
        unitCount: "1",
        charges: 1555,
        nonCoveredCharges: 45.67,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-4",
        drugIdentification: null,
      },
      {
        lineNo: "5",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "6",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "7",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "8",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "9",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "10",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "11",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "12",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "13",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "14",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "15",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "16",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "17",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "18",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "19",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "20",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "21",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "22",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
      {
        lineNo: "23",
        revenueCode: "0730",
        description: "line 5 description",
        productOrServiceIdQualifier: null,
        hcpcsRateHippsCode: "A5",
        procedureModifier: null,
        dosFrom: "2024-01-18",
        dosTo: "2024-01-18",
        unitCount: "1",
        charges: 232,
        nonCoveredCharges: 0,
        unitOrBasisForMeasurementCode: "UN",
        dateTimeQualifier: null,
        dateTimePeriodFormatQualifier: null,
        referenceIdentificationQualifier: "6R",
        lineItemControlNumber: "509714745-5",
        drugIdentification: null,
      },
    ],
    statementFromDate: "20240101",
    statementThruDate: "20240201",
    submitter: {
      lastNameOrOrganization: "B00099999801",
      idQualifier: "46",
      id: "ENS",
      contactFunctionCode: "IC",
      contactName: "OPTUM MGD",
      communicationNumberQualifier: "TE",
      communicationNumber: "8775829188",
    },
    subscriber: {
      lastName: "HANEY",
      firstName: "JENNIFER",
      middleName: "G",
      idQualifier: "MI",
      primaryIdentifier: "123149418",
      address: "5641 S 22 ST",
      address2: "ROOM 1",
      city: "OMAHA",
      state: "NE",
      zip: "68107",
      dateTimePeriodFormatQualifier: "D8",
      birthDate: "19790508",
      gender: "F",
      payerResponsibilityCode: "S",
      policyGroup: "NESHAD",
    },
    totalClaimChargeAmount: 9478.4,
    totalNonCoveredCharges: 0,
    typeOfBill: "10",
    typeOfVisit: "1",
    valueCodes: [
      {
        code: "01",
        amount: 852,
      },
      {
        code: "02",
        amount: 1.01,
      },
      {
        code: "02",
        amount: 2.02,
      },
      {
        code: "03",
        amount: 3.03,
      },
      {
        code: "04",
        amount: 4.04,
      },
      {
        code: "05",
        amount: 5.05,
      },
      {
        code: "06",
        amount: 6.06,
      },
      {
        code: "07",
        amount: 7.07,
      },
      {
        code: "08",
        amount: 8.08,
      },
      {
        code: "09",
        amount: 9.09,
      },
      {
        code: "10",
        amount: 10.01,
      },
      {
        code: "80",
        amount: 4,
      },
    ],
    history: [
      {
        addeddate: {
          $date: "2024-07-30T15:39:10.096Z",
        },
        addedby: "SYSTEM",
        comment: "Initial load by system",
        actionitem: "Administrative",
        actionoutcome: "Loaded Data",
        timespent: null,
        important: false,
      },
    ],
    shared: null,
    icdIndicator: 0
  };

  let adjustedClaim = {
    billingProvider: {},
    patient: {},
    subscriber: {},
    payer: {},
    otherProvider: [],
    operatingProvider: {},
    attendingProvider: {},
  };

  //Section 1
  adjustedClaim.billingProvider.lastNameOrOrganization =
    initialClaim.billingProvider.lastNameOrOrganization || "";
  adjustedClaim.billingProvider.address =
    initialClaim.billingProvider.address || "";
  adjustedClaim.billingProvider.address2 =
    initialClaim.billingProvider.address2 || "";
  adjustedClaim.billingProvider.city = initialClaim.billingProvider.city || "";
  adjustedClaim.billingProvider.state =
    initialClaim.billingProvider.state || "";
  adjustedClaim.billingProvider.zip = initialClaim.billingProvider.zip || "";
  adjustedClaim.billingProvider.phone =
    initialClaim.billingProvider.phone || "";

  //Section 2
  adjustedClaim.billingProvider.payToName =
    initialClaim.billingProvider.payToName || "";
  adjustedClaim.billingProvider.payToAddress =
    initialClaim.billingProvider.payToAddress || "";
  adjustedClaim.billingProvider.payToAddress2 =
    initialClaim.billingProvider.payToAddress2 || "";
  adjustedClaim.billingProvider.payToCity =
    initialClaim.billingProvider.payToCity || "";
  adjustedClaim.billingProvider.payToState =
    initialClaim.billingProvider.payToState || "";
  adjustedClaim.billingProvider.payToZip =
    initialClaim.billingProvider.payToZip || "";
  adjustedClaim.billingProvider.payToPhone =
    initialClaim.billingProvider.payToPhone || "";

  //section 3
  adjustedClaim.patientControlNumber = initialClaim.patientControlNumber || "";
  adjustedClaim.medicalRecordNumber = initialClaim.medicalRecordNumber || "";

  //section 4
  adjustedClaim.typeOfBill = (initialClaim.typeOfBill || "") + (initialClaim.claimFrequencyCode || "");

  //section 5
  adjustedClaim.facilityTin = initialClaim.facilityTin || "";

  //section 6
  adjustedClaim.statementFromDate =
    moment(initialClaim.statementFromDate).format("MM DD YY") || "";
  adjustedClaim.statementThruDate =
    moment(initialClaim.statementThruDate).format("MM DD YY") || "";

  //section 7
  adjustedClaim.noOfAdminNecessaryDays =
    initialClaim.noOfAdminNecessaryDays || "";

  // Section 8
  adjustedClaim.subscriber.primaryIdentifier =
    initialClaim.subscriber.primaryIdentifier || "";
  adjustedClaim.patient.lastName = initialClaim.patient.lastName || "";
  adjustedClaim.patient.firstName = initialClaim.patient.firstName || "";
  adjustedClaim.patient.middleName = initialClaim.patient.middleName || "";

  //section 9
  adjustedClaim.patient.address = initialClaim.patient.address || "";
  adjustedClaim.patient.address2 = initialClaim.patient.address2 || "";
  adjustedClaim.patient.city = initialClaim.patient.city || "";
  adjustedClaim.patient.state = initialClaim.patient.state || "";
  adjustedClaim.patient.zip = initialClaim.patient.zip || "";
  adjustedClaim.patient.countryCode = initialClaim.patient.countryCode || "";

  //section 10
  adjustedClaim.patient.birthDate =
    moment(initialClaim.patient.birthDate).format("MM DD YYYY") || "";

  //section 11
  adjustedClaim.patient.gender = initialClaim.patient.gender || "";

  //section 12
  adjustedClaim.admitDate =
    moment(initialClaim.admitDate).format("MM DD YY") || "";

  //section 13
  adjustedClaim.admitTime = initialClaim.admitTime
    ? initialClaim.admitTime.substr(0, 2)
    : "";

  //section 14
  adjustedClaim.typeOfVisit = initialClaim.typeOfVisit || "";

  //section 15
  adjustedClaim.pointOfOrigin = initialClaim.pointOfOrigin || "";

  //section 16
  adjustedClaim.dischargeTime = initialClaim.dischargeTime
    ? initialClaim.dischargeTime.substr(0, 2)
    : "";
  //section 17
  adjustedClaim.dischargeStatus = initialClaim.dischargeStatus || "";

  //section 18-28
  adjustedClaim.conditionCodes = initialClaim.conditionCodes || [];

  //section 29
  adjustedClaim.accidentState = initialClaim.accidentState || "";

  //section 30
  adjustedClaim.accidentDate = moment(initialClaim.accidentDate).format(
    "MM DD YY"
  );

  //section 31-34 and 35-36
  let occurrences1 = [];
  let occurrences2 = [];
  for (let x = 0; x < initialClaim.occurrence.length; x++) {
    if (!initialClaim.occurrence[x].thruDate) {
      occurrences1.push({
        code: initialClaim.occurrence[x].code,
        fromDate: moment(initialClaim.occurrence[x].fromDate).format(
          "MM DD YY"
        ),
      });
    } else {
      occurrences2.push({
        code: initialClaim.occurrence[x].code,
        fromDate: moment(initialClaim.occurrence[x].fromDate).format(
          "MM DD YY"
        ),
        thruDate: moment(initialClaim.occurrence[x].thruDate).format(
          "MM DD YY"
        ),
      });
    }
  }
  adjustedClaim.occurrences1 = occurrences1;
  adjustedClaim.occurrences2 = occurrences2;

  //section 37
  //not in use

  //section 38
  adjustedClaim.payer = initialClaim.payer;

  //section 39-41
  let valueCodes = [];
  for (let x = 0; x < initialClaim.valueCodes.length; x++) {
    let dollars = initialClaim.valueCodes[x].amount.toString().split(".")[0];
    let cents = initialClaim.valueCodes[x].amount.toString().split(".")[1];
    valueCodes.push({
      code: initialClaim.valueCodes[x].code,
      dollars: dollars || "0",
      cents: cents || "00",
    });
  }
  adjustedClaim.valueCodes = valueCodes;

  //section 42-49
  let serviceLines = [];
  for (let x = 0; x < initialClaim.serviceLine.length; x++) {
    let line = initialClaim.serviceLine[x];
    let chargesDollars = line.charges.toFixed(2).toString().split(".")[0];
    let chargesCents = line.charges.toFixed(2).toString().split(".")[1];
    let nonCoveredChargesDollars = line.nonCoveredCharges
      .toFixed(2)
      .toString()
      .split(".")[0];
    let nonCoveredChargesCents = line.nonCoveredCharges
      .toFixed(2)
      .toString()
      .split(".")[1];
    serviceLines.push({
      revenueCode: line.revenueCode || "",
      description: line.description || "",
      hcpcsRateHippsCode: line.hcpcsRateHippsCode || "",
      serviceDate: line.dosFrom ? moment(line.dosFrom).format("MM DD YY") : "",
      unitCount: line.unitCount || "",
      chargesDollars: chargesDollars || "0",
      chargesCents: chargesCents || "00",
      nonCoveredChargesDollars: nonCoveredChargesDollars || "0",
      nonCoveredChargesCents: nonCoveredChargesCents || "00",
    });
  }
  adjustedClaim.serviceLines = serviceLines;
  adjustedClaim.totalClaimChargeAmountDollars =
    initialClaim.totalClaimChargeAmount.toFixed(2).toString().split(".")[0];
  adjustedClaim.totalClaimChargeAmountCents =
    initialClaim.totalClaimChargeAmount.toFixed(2).toString().split(".")[1];
  adjustedClaim.totalNonCoveredChargesDollars =
    initialClaim.totalNonCoveredCharges.toFixed(2).toString().split(".")[0];
  adjustedClaim.totalNonCoveredChargesCents =
    initialClaim.totalNonCoveredCharges.toFixed(2).toString().split(".")[1];

  adjustedClaim.creationDate = initialClaim.creationDate
    ? moment(initialClaim.creationDate).format("MM DD YY")
    : moment().format("MM DD YY");

  // Section 50-65
  let insured = [];
  for (let x = 0; x < initialClaim.insured.length; x++) {
    let insuredLine = initialClaim.insured[x];
    let priorPaymentsDollars = Number(insuredLine.priorPayments)
      .toFixed(2)
      .toString()
      .split(".")[0];
    let priorPaymentsCents = Number(insuredLine.priorPayments)
      .toFixed(2)
      .toString()
      .split(".")[1];
    let estimatedAmtDueDollars = Number(insuredLine.estimatedAmtDue)
      .toFixed(2)
      .toString()
      .split(".")[0];
    let estimatedAmtDueCents = Number(insuredLine.estimatedAmtDue)
      .toFixed(2)
      .toString()
      .split(".")[1];
    insured.push({
      payerName: insuredLine.payerName,
      payerId: insuredLine.payerId,
      releaseOfInformation: insuredLine.releaseOfInformation,
      assignmentOfBenefits: insuredLine.assignmentOfBenefits,
      priorPaymentsDollars: priorPaymentsDollars || "0",
      priorPaymentsCents: priorPaymentsCents || "00",
      estimatedAmtDueDollars: estimatedAmtDueDollars || "0",
      estimatedAmtDueCents: estimatedAmtDueCents || "00",
      otherProvider: insuredLine.otherProvider,
      lastName: insuredLine.lastName || "",
      firstName: insuredLine.firstName || "",
      middleName: insuredLine.middleName,
      patientRelationship: insuredLine.patientRelationship || "",
      uniqueId: insuredLine.uniqueId || "",
      groupName: insuredLine.groupName || "",
      groupNumber: insuredLine.groupNumber || "",
      treatmentAuthorizationCode:
        insuredLine.treatmentAuthorizationCode == 0
          ? insuredLine.treatmentAuthorizationCode.toString()
          : insuredLine.treatmentAuthorizationCode || "",
      documentControlNumber: insuredLine.documentControlNumber || "",
      employerName: insuredLine.employerName || "",
    });
  }

  adjustedClaim.insured = insured;
  adjustedClaim.billingProvider.npi = initialClaim.billingProvider.npi;

  // section 66
  adjustedClaim.icdCodeOrig = initialClaim.icdCodeOrig;
  adjustedClaim.icdIndicator = initialClaim.icdIndicator

  //section 67
  adjustedClaim.icdCodeAdj = initialClaim.icdCodeAdj;

  //section 69
  adjustedClaim.admittingDiagnosisCode = initialClaim.admittingDiagnosisCode;

  //section 70
  adjustedClaim.patientReasonForVisit = initialClaim.patientReasonForVisit;

  //section 71
  adjustedClaim.ppsCode = initialClaim.ppsCode;

  //section 72
  adjustedClaim.eci = initialClaim.eci;

  // section 74

  let procedureGroup = [];
  for (let x = 0; x < initialClaim.procedureGroup.length; x++) {
    let procedureGroupLine = initialClaim.procedureGroup[x];
    procedureGroup.push({
      code: procedureGroupLine.code,
      date: procedureGroupLine.date
        ? moment(procedureGroupLine.date).format("MM DD YY")
        : "",
    });
  }
  adjustedClaim.procedureGroup = procedureGroup;

  // section 76
  adjustedClaim.attendingProvider = initialClaim.attendingProvider;

  // section 77
  adjustedClaim.operatingProvider = initialClaim.operatingProvider;

  // section 78 and 79
  adjustedClaim.otherProvider = initialClaim.otherProvider;

  // section 80 -- have to do some trickery here since its seperated into 4 fields...
  let originalRemarks = initialClaim.remarks;
  let firstLine = originalRemarks.slice(0, 26);
  let secondLine = originalRemarks.slice(26, 61);
  let thirdLine = originalRemarks.slice(61, 96);
  let fourthLine = originalRemarks.slice(96, 1000);
  let remarks = [firstLine, secondLine, thirdLine, fourthLine];
  adjustedClaim.remarks = remarks;

  return (
    <Formik
      validationSchema={schema}
      validate={validateUB04}
      validateOnChange={false}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={(values) => {
        // handleFormSubmit(values);
      }}
      initialValues={adjustedClaim}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setFieldTouched,
        formikProps,
      }) => (
        <div style={{ backgroundColor: "white" }}>
          <form className="form">
            {/* Start Form */}
            <table
              style={{
                width: "840px",
                tableLayout: "fixed",
                marginTop: 10,
                marginLeft: 5,
              }}
            >
              <tbody>
                <tr id="ub04row1">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={75}
                    style={{
                      borderTop: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                    className="smallNumberLeftAligned"
                  >
                    <td colSpan={1}> &nbsp;1 </td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="billingProvider.lastNameOrOrganization"
                        number="1a"
                        title="Billing Provider Name"
                        subtitle="Billing Provider Name"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={76}
                    style={{
                      borderTop: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                    className="smallNumberLeftAligned"
                  >
                    <td colSpan={1}> &nbsp;2 </td>
                    <td colSpan={74} style={{ width: "100%" }}>
                      <TextInput
                        name="billingProvider.payToName"
                        number="2a"
                        title="Billing Provider Pay To Name"
                        subtitle="Billing Provider Pay To Name"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={9}
                    style={{
                      borderTop: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    className="smallText"
                  >
                    &nbsp;3a PAT.
                    <br />
                    &nbsp;CNTL #{" "}
                  </td>
                  <td
                    colSpan={71}
                    style={{
                      borderTop: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <TextInput
                      name="patientControlNumber"
                      number="3a"
                      title="Patient Control Number"
                      subtitle=""
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={15}
                    style={{
                      borderTop: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    id="billType"
                  >
                    &nbsp;4&emsp;&thinsp;TYPE
                    <br /> <div style={{ textAlign: "center" }}>OF BILL</div>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row2">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={75}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                  >
                    <td style={{ width: "70%" }}>
                      <TextInput
                        name="billingProvider.address"
                        number="1b"
                        title="Billing Provider Address"
                        subtitle="Billing Provider Address"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <TextInput
                        name="billingProvider.address2"
                        number="1b"
                        title="Billing Provider Address 2"
                        subtitle="Billing Provider Address 2"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={76}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                  >
                    <td style={{ width: "70%" }}>
                      <TextInput
                        name="billingProvider.payToAddress"
                        number="2b"
                        title="Billing Provider Pay To Address"
                        subtitle="Billing Provider Pay To Address"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <TextInput
                        name="billingProvider.payToAddress2"
                        number="2b"
                        title="Billing Provider Pay To Address 2"
                        subtitle="Billing Provider Pay To Address 2"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={9}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    className="smallText grayBackground"
                  >
                    &nbsp;b. MED.
                    <br />
                    &nbsp;REC. #{" "}
                  </td>
                  <td
                    colSpan={71}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    className="grayBackground"
                  >
                    <TextInput
                      name="medicalRecordNumber"
                      number="3b"
                      title="Med Rec #"
                      subtitle=""
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={15}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <TextInput
                      name="typeOfBill"
                      number="4"
                      title="Type of Bill"
                      subtitle=""
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row3">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={75}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                  >
                    <td style={{ width: "60%" }}>
                      <TextInput
                        name="billingProvider.city"
                        number="1c"
                        title="Billing Provider City"
                        subtitle="Billing Provider City"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <TextInput
                        name="billingProvider.state"
                        number="1c"
                        title="Billing Provider State"
                        subtitle="Billing Provider State"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <TextInput
                        name="billingProvider.zip"
                        number="1c"
                        title="Billing Provider Zip"
                        subtitle="Billing Provider Zip"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={76}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #D0D0D0",
                    }}
                  >
                    <td style={{ width: "60%" }}>
                      <TextInput
                        name="billingProvider.payToCity"
                        number="2c"
                        title="Billing Provider Pay To City"
                        subtitle="Billing Provider Pay To City"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <TextInput
                        name="billingProvider.payToState"
                        number="2c"
                        title="Billing Provider Pay To State"
                        subtitle="Billing Provider Pay To State"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <TextInput
                        name="billingProvider.payToZip"
                        number="2c"
                        title="Billing Provider Pay To Zip"
                        subtitle="Billing Provider Pay To Zip"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={30}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    className="middleText"
                  >
                    &nbsp;5 FED. TAX NO.
                  </td>
                  <td
                    colSpan={41}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      fontSize: "7px",
                      verticalAlign: "top",
                    }}
                    className="grayBackground"
                  >
                    &nbsp;6&ensp;&ensp;&ensp;&nbsp;STATEMENT
                    &thinsp;&thinsp;COVERS PERIOD
                    <br />
                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;FROM&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;&thinsp;THROUGH{" "}
                  </td>
                  <td
                    colSpan={24}
                    rowSpan={2}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                    className="smallNumberLeftAligned"
                  >
                    <td>&nbsp;7</td>
                    <td>
                      <TextAreaInput
                        elementName="noOfAdminNecessaryDays"
                        number="7"
                        title="Number Of Admin Necessary Days"
                        subtitle=""
                        rows={2}
                        doubleHeight={true}
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row4">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={75}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    {" "}
                    <TextInput
                      name="billingProvider.phone"
                      number="1d"
                      title="Billing Provider Phone"
                      subtitle=""
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={76}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <TextInput
                      name="billingProvider.payToPhone"
                      number="2d"
                      title="Billing Provider Pay To Phone"
                      subtitle="Billing Provider Pay To Phone"
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={30}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <TextInput
                      name="facilityTin"
                      number="5"
                      title="Federal Tax Number"
                      subtitle=""
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={21}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <DateInput
                      name="statementFromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td
                    colSpan={20}
                    style={{
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    <DateInput
                      name="statementThruDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row5">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={30} className="middleText grayBackground">
                    &nbsp;8 PATIENT &thinsp;NAME
                  </td>
                  <td colSpan={3} className="middleText grayBackground">
                    &thinsp;a
                  </td>
                  <td colSpan={58}>
                    {" "}
                    <TextInput
                      name="subscriber.primaryIdentifier"
                      number="8a"
                      title="Subscriber Primary Identifier"
                      subtitle="Subscriber Primary Identifier"
                      maxLength={30}
                      size="30"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={30} className="middleText grayBackground">
                    &nbsp;9 PATIENT &thinsp;ADDRESS
                  </td>
                  <td colSpan={3} className="middleText grayBackground">
                    &thinsp;a
                  </td>
                  <td colSpan={122}>
                    <td style={{ width: "70%" }}>
                      <TextInput
                        name="patient.address"
                        number="9a"
                        title="Patient Address"
                        subtitle="Patient Address"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "30%" }}>
                      <TextInput
                        name="patient.address2"
                        number="9a"
                        title="Patient Address 2"
                        subtitle="Patient Address 2"
                        maxLength={30}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row6">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={3} className="grayBackground middleText">
                    &thinsp;b
                  </td>
                  <td colSpan={88}>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="patient.firstName"
                        number="8b"
                        title="Patient First Name"
                        subtitle="Patient First Name"
                        maxLength={88}
                        size="88"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <TextInput
                        name="patient.middleName"
                        number="8b"
                        title="Patient Middle Name"
                        subtitle="Patient Middle Name"
                        maxLength={88}
                        size="88"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="patient.lastName"
                        number="8b"
                        title="Patient Last Name"
                        subtitle="Patient Last Name"
                        maxLength={88}
                        size="88"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td colSpan={3} className="grayBackground middleText">
                    &thinsp;b
                  </td>
                  <td colSpan={96}>
                    {" "}
                    <TextInput
                      name="patient.city"
                      number="9b"
                      title="Patient City"
                      subtitle="Patient City"
                      maxLength={96}
                      size="96"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground middleText">
                    &thinsp;c
                  </td>
                  <td colSpan={9}>
                    {" "}
                    <TextInput
                      name="patient.state"
                      number="9c"
                      title="Patient State"
                      subtitle="Patient State"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground middleText">
                    &thinsp;d
                  </td>
                  <td colSpan={29}>
                    {" "}
                    <TextInput
                      name="patient.zip"
                      number="9d"
                      title="Patient Zip"
                      subtitle="Patient Zip"
                      maxLength={29}
                      size="29"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground middleText">
                    &thinsp;e
                  </td>
                  <td colSpan={9}>
                    {" "}
                    <TextInput
                      name="patient.countryCode"
                      number="9e"
                      title="Patient Country Code"
                      subtitle="Patient Country Code"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row7">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={27}
                    rowSpan={2}
                    className="middleText leftRightBorder"
                  >
                    &nbsp;10 BIRTHDATE
                  </td>
                  <td
                    colSpan={9}
                    rowSpan={2}
                    className="middleText leftRightBorder"
                  >
                    11 &thinsp;SEX
                  </td>
                  <td
                    colSpan={46}
                    style={{ fontSize: "7px", textAlign: "center" }}
                    className="grayBackground leftRightBorder"
                  >
                    ADMISSION
                  </td>
                  <td
                    colSpan={9}
                    rowSpan={2}
                    className="middleText leftRightBorder"
                  >
                    16 DHR
                  </td>
                  <td
                    colSpan={9}
                    rowSpan={2}
                    className="middleText leftRightBorder"
                  >
                    17 STAT
                  </td>
                  <td
                    colSpan={99}
                    style={{ fontSize: "7px", textAlign: "center" }}
                    className="grayBackground leftRightBorder"
                  >
                    CONDITION CODES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td
                    colSpan={9}
                    rowSpan={2}
                    style={{ fontSize: "7px" }}
                    className="grayBackground leftRightBorder"
                  >
                    29 ACDT<div style={{ textAlign: "center" }}>STATE</div>
                  </td>
                  <td
                    colSpan={38}
                    rowSpan={2}
                    className="grayBackground smallNumberLeftAligned leftRightBorder"
                  >
                    &nbsp;30
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row8">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={18}
                    style={{ fontSize: "7px" }}
                    className="grayBackground"
                  >
                    12 &ensp;&ensp;&ensp;&thinsp;DATE
                  </td>
                  <td
                    colSpan={9}
                    style={{ fontSize: "7px", textAlign: "center" }}
                    className="grayBackground"
                  >
                    13 HR
                  </td>
                  <td
                    colSpan={9}
                    style={{ fontSize: "7px", textAlign: "center" }}
                    className="grayBackground"
                  >
                    14 TYPE
                  </td>
                  <td
                    colSpan={10}
                    style={{
                      fontSize: "7px",
                      textAlign: "center",
                      backgroundColor: "#e0e0e0",
                    }}
                    className="grayBackground"
                  >
                    15 SRC
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground "
                  >
                    18
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground "
                  >
                    19
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    20
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    21
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    22
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    23
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    24
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    25
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    26
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    27
                  </td>
                  <td
                    colSpan={9}
                    style={{ textAlign: "center", fontSize: "7px" }}
                    className="grayBackground"
                  >
                    28
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row9">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={27}>
                    {" "}
                    <DateInput
                      name="patient.birthDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="patient.gender"
                      number="11"
                      title="Gender"
                      subtitle="Patient Gender"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={18}>
                    <DateInput
                      name="admitDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="admitTime"
                      number="13"
                      title="Admit Time"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={8} style={{ borderRight: "none" }}>
                    {" "}
                    <TextInput
                      name="typeOfVisit"
                      number="14"
                      title="Type of Visit"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={1}
                    className="grayBackground RightBorder"
                    style={{
                      borderLeft: "none",
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "4px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <td colSpan={10}>
                    <td
                      colSpan={1}
                      className="grayBackground LeftBorder"
                      style={{
                        background: `url(${greyRectangle})`,
                        backgroundPosition: "left center",
                        backgroundSize: "4px 17px",
                        backgroundRepeat: "no-repeat",
                        // borderRight: 'none'
                      }}
                    ></td>
                    <td colSpan={9}>
                      <TextInput
                        name="pointOfOrigin"
                        number="15"
                        title="Point Of Origin"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td colSpan={9}>
                    {" "}
                    <TextInput
                      name="dischargeTime"
                      number="16"
                      title="Discharge Time"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    {" "}
                    <TextInput
                      name="dischargeStatus"
                      number="17"
                      title="Discharge Status"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[0]"
                      number="18"
                      title="Condition Code 1"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[1]"
                      number="19"
                      title="Condition Code 2"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[2]"
                      number="20"
                      title="Condition Code 3"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[3]"
                      number="21"
                      title="Condition Code 4"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[4]"
                      number="22"
                      title="Condition Code 5"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[5]"
                      number="23"
                      title="Condition Code 6"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[6]"
                      number="24"
                      title="Condition Code 7"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[7]"
                      number="25"
                      title="Condition Code 8"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[8]"
                      number="26"
                      title="Condition Code 9"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[9]"
                      number="27"
                      title="Condition Code 10"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="conditionCodes[10]"
                      number="28"
                      title="Condition Code 11"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9}>
                    <TextInput
                      name="accidentState"
                      number="29"
                      title="Accident State"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={38}>
                    <TextInput
                      name="accidentDate"
                      number="30"
                      title="Accident Date"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row10">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={30} className="grayBackground leftRightBorder">
                    &nbsp;31&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OCCURRENCE
                  </td>
                  <td
                    colSpan={30}
                    className="blackBackgroundWhiteText leftRightBorder"
                  >
                    &nbsp;32&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OCCURRENCE
                  </td>
                  <td colSpan={31} className="grayBackground leftRightBorder">
                    &nbsp;33&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OCCURRENCE
                  </td>
                  <td
                    colSpan={30}
                    className="blackBackgroundWhiteText leftRightBorder"
                  >
                    &nbsp;34&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OCCURRENCE
                  </td>
                  <td colSpan={8} className="grayBackground">
                    &nbsp;&nbsp;35
                  </td>
                  <td colSpan={43} className="grayBackground rightBorder">
                    &nbsp;&nbsp;&nbsp;&ensp;&ensp;&ensp;&ensp;&ensp;OCCURRENCE
                    SPAN
                  </td>
                  <td colSpan={9} className="grayBackground">
                    &nbsp;&nbsp;36
                  </td>
                  <td colSpan={41} className="grayBackground">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&thinsp;&thinsp;OCCURRENCE
                    SPAN
                  </td>
                  <td
                    colSpan={24}
                    rowSpan={2}
                    className="grayBackground leftRightBorder smallNumberLeftAligned"
                  >
                    &nbsp;37
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row11">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={9} className=" grayBackground leftBorder">
                    CODE
                  </td>
                  <td colSpan={21} className="grayBackground">
                    &nbsp;DATE
                  </td>
                  <td
                    colSpan={9}
                    className="blackBackgroundWhiteText leftBorder"
                  >
                    CODE
                  </td>
                  <td colSpan={21} className="blackBackgroundWhiteText">
                    &nbsp;DATE
                  </td>
                  <td colSpan={9} className="grayBackground leftBorder">
                    CODE
                  </td>
                  <td colSpan={22} className="grayBackground">
                    DATE
                  </td>
                  <td
                    colSpan={9}
                    className="blackBackgroundWhiteText leftBorder"
                  >
                    CODE
                  </td>
                  <td colSpan={21} className="blackBackgroundWhiteText">
                    &nbsp;&thinsp;DATE
                  </td>
                  <td colSpan={8} className="grayBackground leftBorder">
                    &thinsp;CODE
                  </td>
                  <td colSpan={21} className="grayBackground">
                    &nbsp;&ensp;&ensp;FROM
                  </td>
                  <td colSpan={22} className="grayBackground">
                    &ensp;&ensp;&nbsp;THROUGH
                  </td>
                  <td colSpan={9} className="grayBackground leftBorder">
                    CODE
                  </td>
                  <td colSpan={20} className="grayBackground">
                    &nbsp;&ensp;&thinsp;FROM
                  </td>
                  <td colSpan={21} className="grayBackground">
                    &ensp;&ensp;&ensp;&thinsp;THROUGH
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row12">
                  <td
                    colSpan={3}
                    style={{ border: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    a
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences1[0].code"
                      number="31codeA"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences1[0].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences1[2].code"
                      number="32codeA"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences1[2].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences1[4].code"
                      number="33codeA"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={22} className="leftRightBorder">
                    <DateInput
                      name="occurrences1[4].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences1[6].code"
                      number="34codeA"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences1[6].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences2[0].code"
                      number="35codeA"
                      title="Occurrence Span Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences2[0].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences2[0].thruDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder">
                    <TextInput
                      name="occurrences2[2].code"
                      number="36codeA"
                      title="Occurrence Span Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder">
                    <DateInput
                      name="occurrences2[2].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={20} className="leftRightBorder">
                    <DateInput
                      name="occurrences2[2].thruDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder">
                    <TextInput
                      name="37A"
                      number="37A"
                      title="37A"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    a
                  </td>
                </tr>
                <tr id="ub04row13">
                  <td
                    colSpan={3}
                    style={{ border: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    b
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences1[1].code"
                      number="31codeB"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences1[1].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences1[3].code"
                      number="32codeB"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences1[3].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences1[5].code"
                      number="33codeB"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={22} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences1[5].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences1[7].code"
                      number="34codeB"
                      title="Occurrence Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences1[7].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences2[1].code"
                      number="35codeB"
                      title="Occurrence Span Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences2[1].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences2[1].thruDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="occurrences2[3].code"
                      number="36codeB"
                      title="Occurrence Span Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={21} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences2[3].fromDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={20} className="leftRightBorder grayBackground">
                    <DateInput
                      name="occurrences2[3].thruDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0", width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder grayBackground">
                    <TextInput
                      name="37B"
                      number="37B"
                      title="37B"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    b
                  </td>
                </tr>
                <tr id="ub04row14">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={127}
                    rowSpan={6}
                    className="leftRightBorder smallNumberLeftAligned bottomBorder"
                  >
                    &nbsp;38
                    <td colSpan={127}>
                      <div style={{ display: "flex" }}>
                        <TextInput
                          name="payer.name"
                          number="38"
                          title="Payer Name"
                          subtitle=""
                          maxLength={50}
                          size="50"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextInput
                          name="payer.address"
                          number="38"
                          title="Payer Address"
                          subtitle=""
                          maxLength={30}
                          size="30"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />

                        <TextInput
                          name="payer.address2"
                          number="38"
                          title="Payer Address 2"
                          subtitle=""
                          maxLength={17}
                          size="17"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextInput
                          name="payer.city"
                          number="38"
                          title="Payer City"
                          subtitle=""
                          maxLength={30}
                          size="30"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />

                        <TextInput
                          name="payer.state"
                          number="38"
                          title="Payer State"
                          subtitle=""
                          maxLength={2}
                          size="5"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />

                        <TextInput
                          name="payer.zip"
                          number="38"
                          title="Payer Zip"
                          subtitle=""
                          maxLength={11}
                          size="11"
                          handleFocus={handleFocus}
                          setFieldTouched={setFieldTouched}
                          activeField={activeField}
                          disabled={false}
                          inputStyle={{ flex: 1 }}
                        />
                      </div>
                    </td>
                  </td>
                  <td colSpan={3} rowSpan={2} className="leftRightBorder" />
                  <td colSpan={9} className="leftBorder grayBackground">
                    &nbsp;39
                  </td>
                  <td
                    colSpan={30}
                    className="rightBorder grayBackground"
                    style={{ textAlign: "left" }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&ensp;VALUE CODES
                  </td>
                  <td
                    colSpan={9}
                    className="leftBorder blackBackgroundWhiteText"
                    style={{ textAlign: "left" }}
                  >
                    &nbsp;&nbsp;40
                  </td>
                  <td
                    colSpan={29}
                    className="rightBorder blackBackgroundWhiteText"
                  >
                    &emsp;&nbsp;&nbsp;&thinsp;VALUE CODES
                  </td>
                  <td colSpan={9} className="leftBorder grayBackground">
                    &nbsp;41
                  </td>
                  <td colSpan={30} className="rightBorder grayBackground">
                    &nbsp;&nbsp;&ensp;&ensp;&thinsp;VALUE CODES
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row15">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={9}
                    className="leftBorder grayBackground"
                    style={{ textAlign: "left" }}
                  >
                    &nbsp;CODE
                  </td>
                  <td
                    colSpan={24}
                    className=" grayBackground"
                    style={{ textAlign: "left" }}
                  >
                    &emsp;&emsp;&ensp;&ensp;&thinsp;&thinsp;AMOUNT
                  </td>
                  <td colSpan={6} className="rightBorder grayBackground"></td>
                  <td
                    colSpan={9}
                    className="leftBorder blackBackgroundWhiteText"
                    style={{ textAlign: "left" }}
                  >
                    &nbsp;&nbsp;CODE
                  </td>
                  <td
                    colSpan={23}
                    className="blackBackgroundWhiteText"
                    style={{ textAlign: "left" }}
                  >
                    &emsp;&emsp;&emsp;&thinsp;&thinsp;&nbsp;AMOUNT
                  </td>
                  <td
                    colSpan={6}
                    className="rightBorder blackBackgroundWhiteText"
                  ></td>
                  <td
                    colSpan={9}
                    className="leftBorder grayBackground"
                    style={{ textAlign: "left" }}
                  >
                    &nbsp;&nbsp;CODE
                  </td>
                  <td
                    colSpan={24}
                    className="grayBackground"
                    style={{ textAlign: "left" }}
                  >
                    &emsp;&emsp;&ensp;&ensp;&nbsp;&thinsp;AMOUNT
                  </td>
                  <td colSpan={6} className="rightBorder grayBackground"></td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row16">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder"
                    style={{
                      fontSize: "10px",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}
                  >
                    a
                  </td>
                  <td colSpan={9} className="leftRightBorder topBorder">
                    <TextInput
                      name="valueCodes[0].code"
                      number="39codeA"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder topBorder">
                    <TextInput
                      name="valueCodes[0].dollars"
                      number="39dollarA"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className="topBorder">
                    <TextInput
                      name="valueCodes[0].cents"
                      number="39centsA"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder topBorder">
                    <TextInput
                      name="valueCodes[4].code"
                      number="40codeA"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={23} className="rightDottedBorder topBorder">
                    <TextInput
                      name="valueCodes[4].dollars"
                      number="40dollarA"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className="topBorder">
                    <TextInput
                      name="valueCodes[4].cents"
                      number="40centsA"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder topBorder">
                    <TextInput
                      name="valueCodes[8].code"
                      number="41codeA"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder topBorder">
                    <TextInput
                      name="valueCodes[8].dollars"
                      number="41dollarA"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder topBorder">
                    <TextInput
                      name="valueCodes[8].cents"
                      number="41centsA"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row17">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder"
                    style={{
                      fontSize: "10px",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}
                  >
                    b
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[1].code"
                      number="39codeb"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[1].dollars"
                      number="39dollarB"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="grayBackground">
                    <TextInput
                      name="valueCodes[1].cents"
                      number="40centsB"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[5].code"
                      number="40codeb"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={23} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[5].dollars"
                      number="40dollarB"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="grayBackground">
                    <TextInput
                      name="valueCodes[5].cents"
                      number="40centsB"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>

                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[9].code"
                      number="41codeb"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                      }}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[9].dollars"
                      number="41dollarB"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder grayBackground">
                    <TextInput
                      name="valueCodes[9].cents"
                      number="41centsB"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row18">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder"
                    style={{
                      fontSize: "10px",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}
                  >
                    c
                  </td>
                  <td colSpan={9} className="leftRightBorder ">
                    <TextInput
                      name="valueCodes[2].code"
                      number="39codeC"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder ">
                    <TextInput
                      name="valueCodes[2].dollars"
                      number="39dollarC"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className>
                    <TextInput
                      name="valueCodes[2].cents"
                      number="39centsC"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder ">
                    <TextInput
                      name="valueCodes[6].code"
                      number="40codeC"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={23} className="rightDottedBorder ">
                    <TextInput
                      name="valueCodes[6].dollars"
                      number="40dollarC"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className>
                    <TextInput
                      name="valueCodes[6].cents"
                      number="40centsC"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder ">
                    <TextInput
                      name="valueCodes[10].code"
                      number="41codeC"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder ">
                    <TextInput
                      name="valueCodes[10].dollars"
                      number="41dollarC"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder ">
                    <TextInput
                      name="valueCodes[10].cents"
                      number="41centsC"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row19">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder"
                    style={{
                      fontSize: "10px",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}
                  >
                    d
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[3].code"
                      number="39codeD"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[3].dollars"
                      number="39dollarD"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="grayBackground">
                    <TextInput
                      name="valueCodes[3].cents"
                      number="39centsD"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[7].code"
                      number="40codeD"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={23} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[7].dollars"
                      number="40dollarD"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="grayBackground">
                    <TextInput
                      name="valueCodes[7].cents"
                      number="40centsD"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={9} className="leftRightBorder grayBackground">
                    <TextInput
                      name="valueCodes[11].code"
                      number="41codeD"
                      title="Value Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder grayBackground">
                    <TextInput
                      name="valueCodes[11].dollars"
                      number="41dollarD"
                      title="Dollar Amount"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder grayBackground">
                    <TextInput
                      name="valueCodes[11].cents"
                      number="41centsD"
                      title="Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row20">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={14}>&nbsp;42 REV. CD.</td>
                  <td colSpan={76}>&nbsp;43 DESCRIPTION</td>
                  <td colSpan={45}>&nbsp;44 HCPCS / RATE / HIPPS CODE</td>
                  <td colSpan={21}>&nbsp;45 SERV. DATE</td>
                  <td colSpan={24}>&nbsp;46 &thinsp;SERV. UNITS</td>
                  <td colSpan={29}>&nbsp;47 TOTAL CHARGES</td>
                  <td colSpan={29}>&nbsp;48 NON-COVERED CHARGES</td>
                  <td colSpan={8}>&nbsp;49</td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <Make22Lines
                  handleFocus={handleFocus}
                  setFieldTouched={setFieldTouched}
                  activeField={activeField}
                  adjustedClaim={adjustedClaim}
                  currentPage={currentPage}
                />
                <tr id="ub04row43">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    23&thinsp;
                  </td>
                  <td
                    colSpan={14}
                    className="leftRightBorder topBorder bottomBorder"
                  />
                  <td
                    colSpan={76}
                    className="boldLargeItalic topBorder bottomBorder"
                  >
                    &nbsp;&thinsp;PAGE{" "}
                    <u>
                      &nbsp;&nbsp;&nbsp;&nbsp;{currentPage}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </u>{" "}
                    OF{" "}
                    <u>
                      &nbsp;&nbsp;&nbsp;&nbsp;{maxPage}&nbsp;&nbsp;&nbsp;&nbsp;
                    </u>
                  </td>
                  <td
                    colSpan={45}
                    style={{ textAlign: "right" }}
                    className="boldLargeItalic topBorder bottomBorder"
                  >
                    CREATION &thinsp;DATE&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td
                    colSpan={21}
                    className="leftRightBorder topBorder bottomBorder"
                  >
                    <DateInput
                      name="creationDate"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td
                    colSpan={15}
                    className="boldLargeItalic leftRightBorder topBorder bottomBorder blackBackgroundWhiteText"
                  >
                    TOTALS
                  </td>
                  <td
                    colSpan={9}
                    className="boldLargeItalic leftRightBorder topBorder bottomBorder"
                    style={{
                      backgroundImage: `url(${thickWhiteArrow})`,
                      backgroundPosition: "right center",
                      backgroundSize: "29px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <td
                    colSpan={22}
                    className="leftRightBorder topBottomBoldBorder"
                    style={{ borderRight: "1px dotted #00000036" }}
                  >
                    <TextInput
                      name="totalClaimChargeAmountDollars"
                      number="23"
                      title="Total Claim Charge Amount Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td
                    colSpan={7}
                    className="leftRightBorder topBottomBoldBorder"
                    style={{ borderLeft: "none" }}
                  >
                    <TextInput
                      name="totalClaimChargeAmountCents"
                      number="23"
                      title="Total Claim Charge Amount Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={22}
                    className="leftRightBorder topBottomBoldBorder rightBorder"
                    style={{ borderRight: "1px dotted #00000036" }}
                  >
                    <TextInput
                      name="totalNonCoveredChargesDollars"
                      number="23"
                      title="Total Non Covered Charges Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td
                    colSpan={7}
                    className="leftRightBorder topBottomBoldBorder rightBorder"
                    style={{ borderLeft: "none" }}
                  >
                    <TextInput
                      name="totalNonCoveredChargesCents"
                      number="23"
                      title="Total Non Covered Charges Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={8} className="rightBorder bottomBorder">
                    <TextInput
                      name="total40"
                      number="total40"
                      title="total40"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    &thinsp;23
                  </td>
                </tr>
                <tr id="ub04row44">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  ></td>
                  <td colSpan={69} className="leftRightBorder">
                    &nbsp;50 PAYER &thinsp;NAME
                  </td>
                  <td colSpan={45}>
                    &nbsp;51 &thinsp;HEALTH &thinsp;PLAN &thinsp;ID
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    52 REL. INFO
                  </td>
                  <td colSpan={3} />
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    53 ASG. BEN.
                  </td>
                  <td colSpan={30} className="leftRightBorder">
                    &nbsp;&nbsp;54 &ensp;PRIOR PAYMENTS
                  </td>
                  <td colSpan={33} className="leftRightBorder">
                    &nbsp;&thinsp;55 &thinsp;EST. AMOUNT &thinsp;DUE
                  </td>
                  <td colSpan={10} className="leftRightBorder left">
                    &nbsp;&thinsp;56 NPI
                  </td>
                  <td
                    colSpan={44}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    <TextInput
                      name="billingProvider.npi"
                      number="56"
                      title="NPI"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  ></td>
                </tr>
                <tr id="ub04row45">
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLRightAlighned"
                  >
                    A
                  </td>
                  <td colSpan={69} className="leftRightBorder">
                    <TextInput
                      name="insured[0].payerName"
                      number="50A"
                      title="Payer Name"
                      subtitle="Payer Name"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[0].payerId"
                      number="51A"
                      title="Payer ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[0].releaseOfInformation"
                      number="52A"
                      title="Release Of Information"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground" />
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[0].assignmentOfBenefits"
                      number="53A"
                      title="Assignment Of Benefits"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder">
                    <TextInput
                      name="insured[0].priorPaymentsDollars"
                      number="54ADollers"
                      title="Prior Payments Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder">
                    <TextInput
                      name="insured[0].priorPaymentsCents"
                      number="54ACents"
                      title="Prior Payments Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={27} className="rightDottedBorder">
                    <TextInput
                      name="insured[0].estimatedAmtDueDollars"
                      number="55ADollers"
                      title="Estimated Amount Due Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6}>
                    <TextInput
                      name="insured[0].estimatedAmtDueCents"
                      number="55ACents"
                      title="Estimated Amount Due Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={10}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    &nbsp;&thinsp;57
                  </td>
                  <td
                    colSpan={44}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    <TextInput
                      name="insured[0].otherProvider"
                      number="57A"
                      title="Other Provider"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLeftAlighned"
                  >
                    A
                  </td>
                </tr>
                <tr id="ub04row46">
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLRightAlighned"
                  >
                    B
                  </td>
                  <td colSpan={69} className="leftRightBorder">
                    <TextInput
                      name="insured[1].payerName"
                      number="50B"
                      title="Payer Name"
                      subtitle="Payer Name"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[1].payerId"
                      number="51B"
                      title="Payer ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[1].releaseOfInformation"
                      number="52B"
                      title="Release Of Information"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground" />
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[1].assignmentOfBenefits"
                      number="53B"
                      title="Assignment Of Benefits"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder">
                    <TextInput
                      name="insured[1].priorPaymentsDollars"
                      number="54BDollers"
                      title="Prior Payments Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder">
                    <TextInput
                      name="insured[1].priorPaymentsCents"
                      number="54BCents"
                      title="Prior Payments Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={27} className="rightDottedBorder">
                    <TextInput
                      name="insured[1].estimatedAmtDueDollars"
                      number="55BDollers"
                      title="Estimated Amount Due Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{
                        backgroundColor: "#e0e0e0",
                        textAlign: "right",
                      }}
                    />
                  </td>
                  <td colSpan={6}>
                    <TextInput
                      name="insured[1].estimatedAmtDueCents"
                      number="55BCents"
                      title="Estimated Amount Due Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={10}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    &nbsp;&thinsp;OTHER
                  </td>
                  <td colSpan={44} className="leftRightBorder">
                    <TextInput
                      name="insured[1].otherProvider"
                      number="57B"
                      title="Other Provider"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLeftAlighned"
                  >
                    B
                  </td>
                </tr>
                <tr id="ub04row47">
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLRightAlighned"
                  >
                    C
                  </td>
                  <td colSpan={69} className="leftRightBorder">
                    <TextInput
                      name="insured[2].payerName"
                      number="50C"
                      title="Payer Name"
                      subtitle="Payer Name"
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[2].payerId"
                      number="51C"
                      title="Payer ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[2].releaseOfInformation"
                      number="52C"
                      title="Release Of Information"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={3} className="grayBackground" />
                  <td
                    colSpan={6}
                    className="leftRightBorder center"
                    style={{ fontSize: "5px" }}
                  >
                    <TextInput
                      name="insured[2].assignmentOfBenefits"
                      number="53C"
                      title="Assignment Of Benefits"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={24} className="rightDottedBorder">
                    <TextInput
                      name="insured[2].priorPaymentsDollars"
                      number="54CDollers"
                      title="Prior Payments Dollars"
                      inputStyle={{ textAlign: "right" }}
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={6} className="rightBorder">
                    <TextInput
                      name="insured[2].priorPaymentsCents"
                      number="54CCents"
                      title="Prior Payments Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={27} className="rightDottedBorder">
                    <TextInput
                      name="insured[2].estimatedAmtDueDollars"
                      number="55CDollers"
                      title="Estimated Amount Due Dollars"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ textAlign: "right" }}
                    />
                  </td>
                  <td colSpan={6}>
                    <TextInput
                      name="insured[2].estimatedAmtDueCents"
                      number="55CCents"
                      title="Estimated Amount Due Cents"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={10}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    &nbsp;&thinsp;PRV ID
                  </td>
                  <td
                    colSpan={44}
                    className="leftRightBorder"
                    style={{ background: "none" }}
                  >
                    <TextInput
                      name="insured[2].otherProvider"
                      number="57C"
                      title="Other Provider"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "6px",
                    }}
                    className="boldSmallLeftAlighned"
                  >
                    C
                  </td>
                </tr>
                <tr id="ub04row48">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  ></td>
                  <td colSpan={78}>&nbsp;58 INSURED'S &thinsp;NAME</td>
                  <td colSpan={10}>&thinsp;59 P.REL</td>
                  <td colSpan={59}>&thinsp;60 INSURED'S UNIQUE ID</td>
                  <td colSpan={45}>&nbsp;61 &thinsp;GROUP NAME</td>
                  <td colSpan={54}>
                    &nbsp;&nbsp;62 &thinsp;INSURANCE GROUP NO.
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  ></td>
                </tr>
                <tr id="ub04row49">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    A
                  </td>
                  <td colSpan={78}>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[0].firstName"
                        number="58A"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <TextInput
                        name="insured[0].middleName"
                        number="58A"
                        title="Middle Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[0].lastName"
                        number="58A"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td colSpan={10}>
                    <TextInput
                      name="insured[0].patientRelationship"
                      number="59A"
                      title="Patient RelationShip"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={59}>
                    <TextInput
                      name="insured[0].uniqueId"
                      number="60A"
                      title="Unique ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[0].groupName"
                      number="61A"
                      title="Group Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={54}>
                    <TextInput
                      name="insured[0].groupNumber"
                      number="62A"
                      title="Group Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    A
                  </td>
                </tr>
                <tr id="ub04row50">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    B
                  </td>
                  <td colSpan={78}>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[1].firstName"
                        number="58B"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ backgroundColor: "#e0e0e0" }}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <TextInput
                        name="insured[1].middleName"
                        number="58B"
                        title="Middle Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ backgroundColor: "#e0e0e0" }}
                      />
                    </td>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[1].lastName"
                        number="58B"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ backgroundColor: "#e0e0e0" }}
                      />
                    </td>
                  </td>
                  <td colSpan={10}>
                    <TextInput
                      name="insured[1].patientRelationship"
                      number="59B"
                      title="Patient RelationShip"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={59}>
                    <TextInput
                      name="insured[1].uniqueId"
                      number="60B"
                      title="Unique ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[1].groupName"
                      number="61b"
                      title="Group Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={54}>
                    <TextInput
                      name="insured[1].groupNumber"
                      number="62B"
                      title="Group Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    B
                  </td>
                </tr>
                <tr id="ub04row51">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    C
                  </td>
                  <td colSpan={78}>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[2].firstName"
                        number="58C"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <TextInput
                        name="insured[2].middleName"
                        number="58C"
                        title="Middle Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                    <td style={{ width: "40%" }}>
                      <TextInput
                        name="insured[2].lastName"
                        number="58C"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="9"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                      />
                    </td>
                  </td>
                  <td colSpan={10}>
                    <TextInput
                      name="insured[2].patientRelationship"
                      number="59C"
                      title="Patient RelationShip"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={59}>
                    <TextInput
                      name="insured[2].uniqueId"
                      number="60C"
                      title="Unique ID"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={45}>
                    <TextInput
                      name="insured[2].groupName"
                      number="61C"
                      title="Group Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={54}>
                    <TextInput
                      name="insured[2].groupNumber"
                      number="62C"
                      title="Group Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    C
                  </td>
                </tr>
                <tr id="ub04row52">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  ></td>
                  <td colSpan={93}>
                    &nbsp;63 TREATMENT &nbsp;AUTHORIZATION &nbsp;CODES
                  </td>
                  <td colSpan={78}>
                    &nbsp;64 DOCUMENT &thinsp;CONTROL &thinsp;NUMBER
                  </td>
                  <td colSpan={75}>&thinsp;&nbsp;65 &thinsp;EMPLOYER NAME</td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  ></td>
                </tr>
                <tr id="ub04row53">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    A
                  </td>
                  <td colSpan={93}>
                    <TextInput
                      name="insured[0].treatmentAuthorizationCode"
                      number="63A"
                      title="Treatment Authorization Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={78}>
                    <TextInput
                      name="insured[0].documentControlNumber"
                      number="64A"
                      title="Document Control Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={75}>
                    <TextInput
                      name="insured[0].employerName"
                      number="65A"
                      title="Employer Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    A
                  </td>
                </tr>
                <tr id="ub04row54">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    B
                  </td>
                  <td colSpan={93}>
                    <TextInput
                      name="insured[1].treatmentAuthorizationCode"
                      number="63B"
                      title="Treatment Authorization Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={78}>
                    <TextInput
                      name="insured[1].documentControlNumber"
                      number="64b"
                      title="Document Control Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={75}>
                    <TextInput
                      name="insured[1].employerName"
                      number="65B"
                      title="Employer Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    B
                  </td>
                </tr>
                <tr id="ub04row55">
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLRightAlighned"
                  >
                    C
                  </td>
                  <td colSpan={93}>
                    <TextInput
                      name="insured[2].treatmentAuthorizationCode"
                      number="63C"
                      title="Treatment Authorization Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={78}>
                    <TextInput
                      name="insured[2].documentControlNumber"
                      number="64c"
                      title="Document Control Number"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={75}>
                    <TextInput
                      name="insured[2].employerName"
                      number="65C"
                      title="Employer Name"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{ border: "none", background: "none" }}
                    className="boldSmallLeftAlighned"
                  >
                    C
                  </td>
                </tr>
                <tr id="ub04row56">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder center grayBackground"
                    style={{ fontSize: "7px" }}
                  >
                    66 DX
                  </td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[0]"
                      number="67"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"67"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[1]"
                      number="66A"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"A"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[2]"
                      number="66B"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"B"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[3]"
                      number="66C"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"C"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[4]"
                      number="66D"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"D"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[5]"
                      number="66E"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"E"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="rightBorder"
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[6]"
                      number="66F"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"F"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[7]"
                      number="66G"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"G"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeOrig[8]"
                      number="66H"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"H"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td
                    colSpan={27}
                    className="leftRightBorder smallNumberLeftAligned"
                  >
                    <td style={{ width: "15%" }}>&thinsp;68</td>
                    <td style={{ width: "85%" }}>
                      <TextInput
                        name="68"
                        number="68"
                        title="68"
                        subtitle=""
                        maxLength={30}
                        // size="11"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row57">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftRightBorder center"
                    style={{ fontSize: "7px" }}
                  >
                    <TextInput
                      name="icdIndicator"
                      number="66"
                      title="ICD Indicator"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[0]"
                      number="66I"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"I"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[1]"
                      number="66J"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"J"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[2]"
                      number="66K"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"K"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[3]"
                      number="66L"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"L"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[4]"
                      number="66M"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"M"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[5]"
                      number="66N"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"N"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="rightBorder"
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[6]"
                      number="66O"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"O"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[7]"
                      number="66P"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"P"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} className="boldLargeGrey">
                    <TextInput
                      name="icdCodeAdj[8]"
                      number="66Q"
                      title="ICD Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"Q"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td
                    colSpan={27}
                    className="leftRightBorder smallNumberLeftAligned"
                  >
                    <TextInput
                      name="68Other"
                      number="68Other"
                      title="68Other"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{}}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row58">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={11}
                    className="leftRightBorder center fontSmall grayBackground"
                  >
                    69 ADMIT
                  </td>
                  <td colSpan={22} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="admittingDiagnosisCode"
                      number="69"
                      title="Admitting Diagnosis Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={15}
                    className="leftRightBorder fontSmall grayBackground"
                  >
                    &nbsp;70 PATIENT
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="leftRightBorder boldLargeGrey"
                  >
                    <TextInput
                      name="patientReasonForVisit[0]"
                      number="70A"
                      title="Patient Reason For Visit"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"a"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="leftRightBorder boldLargeGrey"
                  >
                    <TextInput
                      name="patientReasonForVisit[1]"
                      number="70B"
                      title="Patient Reason For Visit"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"b"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="leftRightBorder boldLargeGrey"
                  >
                    <TextInput
                      name="patientReasonForVisit[2]"
                      number="70C"
                      title="Patient Reason For Visit"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"c"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={12}
                    className="leftRightBorder fontSmall grayBackground"
                  >
                    &nbsp;71 PPS
                  </td>
                  <td colSpan={15} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="ppsCode"
                      number="71"
                      title="PPS Code"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                    />
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder fontSmall grayBackground"
                  >
                    &nbsp;72
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="boldLargeGrey bottomBorder"
                    style={{ borderBottom: "1px solid #000000 !important" }}
                  >
                    <TextInput
                      name="eci[0]"
                      number="72A"
                      title="ECI"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"a"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    rowSpan={2}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} rowSpan={2} className="boldLargeGrey">
                    <TextInput
                      name="eci[1]"
                      number="72B"
                      title="ECI"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"b"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    rowSpan={2}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={21} rowSpan={2} className="boldLargeGrey">
                    <TextInput
                      name="eci[2]"
                      number="72C"
                      title="ECI"
                      subtitle=""
                      maxLength={9}
                      size="9"
                      placeHolder={"c"}
                      placeHolderId={"placeholderInput"}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ paddingTop: 2 }}
                    />
                  </td>
                  <td
                    colSpan={3}
                    rowSpan={2}
                    className="rightBorder"
                    style={{
                      backgroundImage: `url(${greyRectangle})`,
                      backgroundPosition: "right center",
                      backgroundSize: "10px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td
                    colSpan={30}
                    rowSpan={2}
                    className="rightBorder smallNumberLeftAligned"
                  >
                    <td style={{ width: "14%" }}>&thinsp;73</td>
                    <td style={{ width: "86%" }}>
                      <TextInput
                        name="73"
                        number="73"
                        title="73"
                        subtitle=""
                        maxLength={9}
                        // size="13"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row59">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={11}
                    className="leftRightBorder left fontSmall grayBackground"
                  >
                    &ensp;&ensp;&ensp;&thinsp;DX
                  </td>
                  <td
                    colSpan={15}
                    className="leftRightBorder fontSmall grayBackground"
                  >
                    &nbsp;REASON DX
                  </td>
                  <td
                    colSpan={12}
                    className="leftRightBorder center fontSmall grayBackground"
                  >
                    &ensp;CODE
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder fontSmall grayBackground"
                  >
                    &nbsp;ECI
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row60">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={3} className="leftBorder grayBackground">
                    &nbsp;74
                  </td>
                  <td colSpan={42} className="center grayBackground">
                    &thinsp;PRINCIPAL PROCEDURE
                  </td>
                  <td
                    colSpan={3}
                    className="leftBorder blackBackgroundWhiteText"
                  >
                    &nbsp;a.
                  </td>
                  <td colSpan={42} className="center blackBackgroundWhiteText">
                    OTHER PROCEDURE
                  </td>
                  <td colSpan={3} className="leftBorder grayBackground">
                    &nbsp;b.
                  </td>
                  <td colSpan={42} className="center grayBackground">
                    OTHER PROCEDURE&thinsp;
                  </td>
                  <td
                    colSpan={15}
                    rowSpan={6}
                    className="leftRightBorder smallNumberLeftAligned"
                    style={{
                      borderRightStyle: "double",
                      borderRightWidth: "3px",
                    }}
                  >
                    75
                    <TextAreaInput
                      elementName="75"
                      number="75"
                      title="75"
                      subtitle=""
                      rows={4}
                      doubleHeight={true}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                    />
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    &nbsp;76 ATTENDING
                  </td>
                  <td
                    colSpan={36}
                    rowSpan={2}
                    className="leftRightBorder middleText"
                  >
                    <td style={{ width: "14%" }}>NPI</td>
                    <td style={{ width: "86%" }}>
                      <TextInput
                        name="attendingProvider.npi"
                        number="76Npi"
                        title="NPI"
                        subtitle=""
                        maxLength={9}
                        // size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={6}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    QUAL
                  </td>
                  <td colSpan={6} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="attendingProvider.idQualifier"
                      number="76Qual"
                      title="Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={27} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="attendingProvider.id"
                      number="76Id"
                      title="ID"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row61">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={24} className="leftBorder center grayBackground">
                    CODE&thinsp;
                  </td>
                  <td colSpan={21} className="center grayBackground">
                    &nbsp;DATE
                  </td>
                  <td
                    colSpan={24}
                    className="leftBorder center blackBackgroundWhiteText"
                  >
                    CODE&thinsp;
                  </td>
                  <td colSpan={21} className="center blackBackgroundWhiteText">
                    &nbsp;&nbsp;&thinsp;DATE
                  </td>
                  <td colSpan={24} className="leftBorder center grayBackground">
                    CODE
                  </td>
                  <td colSpan={21} className="center grayBackground">
                    DATE&thinsp;
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row62">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[0].code"
                      number="74Code"
                      title="Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[0].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[1].code"
                      number="74aCode"
                      title="Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[1].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[2].code"
                      number="74bCode"
                      title="Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[2].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={54} className="leftRightBorder middleText">
                    <td>&nbsp;LAST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="attendingProvider.lastName"
                        number="76LastName"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={42} className="leftRightBorder middleText">
                    <td> &nbsp;FIRST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="attendingProvider.firstName"
                        number="76FirstName"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row63">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={3}
                    className="leftBorder blackBackgroundWhiteText"
                  >
                    &nbsp;c.
                  </td>
                  <td colSpan={42} className="center blackBackgroundWhiteText">
                    OTHER PROCEDURE
                  </td>
                  <td colSpan={3} className="leftBorder grayBackground">
                    &nbsp;d.
                  </td>
                  <td colSpan={42} className="center grayBackground">
                    OTHER PROCEDURE
                  </td>
                  <td
                    colSpan={3}
                    className="leftBorder blackBackgroundWhiteText"
                  >
                    &nbsp;e.
                  </td>
                  <td colSpan={42} className="center blackBackgroundWhiteText">
                    OTHER PROCEDURE&thinsp;
                  </td>
                  <td
                    colSpan={21}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    &nbsp;77 OPERATING
                  </td>
                  <td
                    colSpan={36}
                    rowSpan={2}
                    className="leftRightBorder middleText"
                  >
                    <td style={{ width: "14%" }}>NPI</td>
                    <td style={{ width: "86%" }}>
                      <TextInput
                        name="operatingProvider.npi"
                        number="77Npi"
                        title="NPI"
                        subtitle=""
                        maxLength={9}
                        // size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={6}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    QUAL
                  </td>
                  <td colSpan={6} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="operatingProvider.idQualifier"
                      number="77Qual"
                      title="Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={27} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="operatingProvider.id"
                      number="77Id"
                      title="ID"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row64">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={24}
                    className="leftBorder center blackBackgroundWhiteText"
                  >
                    CODE&thinsp;
                  </td>
                  <td colSpan={21} className="center blackBackgroundWhiteText">
                    &nbsp;DATE
                  </td>
                  <td colSpan={24} className="leftBorder center grayBackground">
                    CODE&thinsp;
                  </td>
                  <td colSpan={21} className="center grayBackground">
                    &nbsp;&thinsp;DATE
                  </td>
                  <td
                    colSpan={24}
                    className="leftBorder center blackBackgroundWhiteText"
                  >
                    CODE
                  </td>
                  <td colSpan={21} className="center blackBackgroundWhiteText">
                    DATE&thinsp;
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row65">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[3].code"
                      number="74cCode"
                      title="Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[3].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[4].code"
                      number="74dCode"
                      title="7Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[4].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={24} className="leftRightBorder center">
                    <TextInput
                      name="procedureGroup[5].code"
                      number="74eCode"
                      title="Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={21} className="center">
                    <DateInput
                      name="procedureGroup[5].date"
                      maxLength={10}
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ width: "100%" }}
                    ></DateInput>
                  </td>
                  <td colSpan={54} className="leftRightBorder middleText">
                    <td>&nbsp;LAST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="operatingProvider.lastName"
                        number="77LastName"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={42} className="leftRightBorder middleText">
                    <td> &nbsp;FIRST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="operatingProvider.firstName"
                        number="77FirstName"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row66">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={72} rowSpan={2}>
                    <td style={{ width: "25%" }}>&nbsp;80 REMARKS</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="remarks[0]"
                        number="80"
                        title="Remarks"
                        subtitle=""
                        maxLength={26}
                        size="30"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={6}>81CC</td>
                  <td colSpan={5} rowSpan={2}>
                    <TextInput
                      name="attendingProvider.taxQualifier"
                      number="81a1"
                      title="Tax Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={31} rowSpan={2}>
                    <TextInput
                      name="attendingProvider.taxonomyCode[0]"
                      number="81a2"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td
                    colSpan={36}
                    rowSpan={2}
                    style={{
                      borderRightStyle: "double",
                      borderRightWidth: "3px",
                    }}
                  >
                    <TextInput
                      name="attendingProvider.taxonomyCode[1]"
                      number="81a3"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td
                    colSpan={15}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    &nbsp;78 OTHER
                  </td>
                  <td
                    colSpan={6}
                    rowSpan={2}
                    className="leftRightBorder middleText"
                  >
                    <TextInput
                      name="78Other"
                      number="78Other"
                      title="78Other"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td
                    colSpan={36}
                    rowSpan={2}
                    className="leftRightBorder middleText"
                  >
                    <td style={{ width: "14%" }}>NPI</td>
                    <td style={{ width: "86%" }}>
                      <TextInput
                        name="otherProvider[0].npi"
                        number="78Npi"
                        title="NPI"
                        subtitle=""
                        maxLength={9}
                        // size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={6}
                    rowSpan={2}
                    className="leftRightBorder middleText grayBackground"
                  >
                    QUAL
                  </td>
                  <td colSpan={6} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="otherProvider[0].idQualifier"
                      number="78Qual"
                      title="Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={27} rowSpan={2} className="leftRightBorder">
                    <TextInput
                      name="otherProvider[0].id"
                      number="78Other2"
                      title="ID"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row67">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={6} className="right">
                    a&nbsp;&thinsp;
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row68">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={72}
                    className="middleText"
                    style={{ borderTop: "1px solid #D0D0D0" }}
                  >
                    <TextInput
                      name="remarks[1]"
                      number="80-1"
                      title="Remarks"
                      subtitle=""
                      maxLength={35}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={6} className="right middleText grayBackground">
                    b&nbsp;&thinsp;
                  </td>
                  <td colSpan={5} className="grayBackground">
                    <TextInput
                      name="operatingProvider.taxQualifier"
                      number="81b1"
                      title="Tax Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={31} className="grayBackground">
                    <TextInput
                      name="operatingProvider.taxonomyCode[0]"
                      number="81b2"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>

                  <td
                    colSpan={36}
                    className="grayBackground"
                    style={{
                      borderRightStyle: "double",
                      borderRightWidth: "3px",
                    }}
                  >
                    <TextInput
                      name="operatingProvider.taxonomyCode[1]"
                      number="81b3"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>

                  <td colSpan={54} className="leftRightBorder middleText">
                    <td> &nbsp;LAST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="otherProvider[0].lastName"
                        number="78LastName"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={42} className="leftRightBorder middleText">
                    <td> &nbsp;FIRST </td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="otherProvider[0].firstName"
                        number="78FirstName"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row69">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={72}
                    className="middleText"
                    style={{ borderTop: "1px solid #D0D0D0" }}
                  >
                    <TextInput
                      name="remarks[2]"
                      number="80-2"
                      title="Remarks"
                      subtitle=""
                      maxLength={35}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={6} className="right middleText">
                    c&nbsp;&thinsp;
                  </td>
                  <td colSpan={5}>
                    <TextInput
                      name="otherProvider[0].taxQualifier"
                      number="81c1"
                      title="Tax Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={31}>
                    <TextInput
                      name="otherProvider[0].taxonomyCode[0]"
                      number="81c2"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td
                    colSpan={36}
                    style={{
                      borderRightStyle: "double",
                      borderRightWidth: "3px",
                    }}
                  >
                    <TextInput
                      name="otherProvider[0].taxonomyCode[1]"
                      number="81c3"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td
                    colSpan={15}
                    className="leftRightBorder middleText grayBackground"
                  >
                    &nbsp;79 OTHER
                  </td>
                  <td colSpan={6} className="leftRightBorder middleText">
                    <TextInput
                      name="79Other"
                      number="79Other"
                      title="79Other"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={36} className="leftRightBorder middleText">
                    <td style={{ width: "14%" }}>NPI</td>
                    <td style={{ width: "86%" }}>
                      <TextInput
                        name="otherProvider[1].npi"
                        number="79Npi"
                        title="NPI"
                        subtitle=""
                        maxLength={9}
                        // size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td
                    colSpan={6}
                    className="leftRightBorder middleText grayBackground"
                  >
                    QUAL
                  </td>
                  <td colSpan={6} className="leftRightBorder">
                    <TextInput
                      name="otherProvider[1].idQualifier"
                      number="79Qual"
                      title="Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={27} className="leftRightBorder">
                    <TextInput
                      name="otherProvider[1].id"
                      number="79Other2"
                      title="ID"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row70">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td
                    colSpan={72}
                    className="middleText"
                    style={{ borderTop: "1px solid #D0D0D0" }}
                  >
                    <TextInput
                      name="remarks[3]"
                      number="80-3"
                      title="Remarks"
                      subtitle=""
                      maxLength={35}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0 }}
                    />
                  </td>
                  <td colSpan={6} className="right middleText grayBackground">
                    d&nbsp;&thinsp;
                  </td>
                  <td colSpan={5} className="grayBackground">
                    <TextInput
                      name="otherProvider[1].taxQualifier"
                      number="81d1"
                      title="Tax Qualifier"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={31} className="grayBackground">
                    <TextInput
                      name="otherProvider[1].taxonomyCode[0]"
                      number="81d2"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td
                    colSpan={36}
                    className="grayBackground"
                    style={{
                      borderRightStyle: "double",
                      borderRightWidth: "3px",
                    }}
                  >
                    <TextInput
                      name="otherProvider[1].taxonomyCode[1]"
                      number="81d3"
                      title="Taxonomy Code"
                      subtitle=""
                      maxLength={9}
                      size="15"
                      handleFocus={handleFocus}
                      setFieldTouched={setFieldTouched}
                      activeField={activeField}
                      disabled={false}
                      inputStyle={{ padding: 0, backgroundColor: "#e0e0e0" }}
                    />
                  </td>
                  <td colSpan={54} className="leftRightBorder middleText">
                    <td> &nbsp;LAST </td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="otherProvider[1].lastName"
                        number="79LastName"
                        title="Last Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={42} className="leftRightBorder middleText">
                    <td> &nbsp;FIRST</td>
                    <td style={{ width: "100%" }}>
                      <TextInput
                        name="otherProvider[1].firstName"
                        number="79FirstName"
                        title="First Name"
                        subtitle=""
                        maxLength={9}
                        size="15"
                        handleFocus={handleFocus}
                        setFieldTouched={setFieldTouched}
                        activeField={activeField}
                        disabled={false}
                        inputStyle={{ padding: 0 }}
                      />
                    </td>
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row71">
                  <td colSpan={3} style={{ border: "none" }} />
                  <td colSpan={36}>UB-04 CMS-1450</td>
                  <td colSpan={37} className="right">
                    APPROVED OMB NO. 0938-0997&nbsp;
                  </td>
                  <td colSpan={10}></td>
                  <td
                    colSpan={67}
                    style={{
                      backgroundImage: `url(${NUBClogo})`,
                      backgroundPosition: "center center",
                      backgroundSize: "80px 17px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></td>
                  <td colSpan={96} className="center">
                    THE CERTIFICATIONS ON THE REVERSE APPLY TO THIS BILL AND ARE
                    MADE A PART HEREOF.
                  </td>
                  <td colSpan={3} style={{ border: "none" }} />
                </tr>
                <tr id="ub04row72">
                  <td colSpan={252}></td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default UB04;
