import React, { Component } from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import CustomLoadingOverlay from "../components/customLoadingOverlay.jsx";
import CustomNoRowsOverlay from "../components/customNoRowsOverlay.jsx";
import _ from "underscore";
// import {nxGetDocById} from '../../utils/nxFunctions';
import moment from "moment-timezone";

class PackageResults2 extends Component {
  state = {
    // npiUrl:`https://npiregistry.cms.hhs.gov/api/?version=2.1&last_name=martinez*&pretty=true&limit=200&state=CA`,
    searchCriteria: this.props.searchCriteria || {},
    filter: localStorage.getItem('filter')?localStorage.getItem('filter'):this.props.filter,
    qOffset:this.props.qOffset,
    qIndex:localStorage.getItem('qIndex')?localStorage.getItem('qIndex'):0,
    active:localStorage.getItem('active') && localStorage.getItem('active')==='false'?false:localStorage.getItem('active') && localStorage.getItem('active')==='true'?true:this.props.active,
    rowData: null,
    columnDefs: [
      // {headerName:'Actions', children:[
      //     {headerName:'Actions', field:'actions', tooltipField:'Actions', headerTooltip:'Actions', width:60, cellRenderer:(params) => {return this.actionsRenderer(params)}},
      // ]},
      {
        headerName: "Provider",
        children: [
          {
            headerName: "ID",
            field: "provider_id",
            headerTooltip: "Provider ID",
            tooltipField: "provider_id",
            sortable: true,
            resizable: true,
            filter: true,
            width: 152,
          },
          // {
          //   headerName: "TIN",
          //   field: "tax_id",
          //   headerTooltip: "Provider TIN",
          //   tooltipField: "tax_id",
          //   sortable: true,
          //   resizable: true,
          //   filter: true,
          //   width: 91,
          // },
          {
            headerName: "Last Name",
            field: "provider_lastname",
            headerTooltip: "Provider Last Name",
            tooltipField: "provider_lastname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 120,
          },
          {
            headerName: "First Name",
            field: "provider_firstname",
            headerTooltip: "Provider First Name",
            tooltipField: "provider_firstname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 110,
          },
          // {headerName: "Group Name", field: "provider.groupname", sortable:true, resizable:true, filter:true, width:160},
        ],
      },
      {
        headerName: "Patient",
        children: [
          {
            headerName: "ID",
            field: "member_id",
            headerTooltip: "Member ID",
            sortable: true,
            tooltipField: "member_id",
            resizable: true,
            filter: true,
            width: 100,
          },
          {
            headerName: "Last Name",
            field: "member_lastname",
            headerTooltip: "Member Last Name",
            tooltipField: "member_lastname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 120,
          },
          // {headerName: "ID", field: "member_id", sortable:true, resizable:true, filter:true, width:95},
          // {headerName: "Last Name", field: "member_lastname", sortable:true, resizable:true, filter:true, width:120},
          {
            headerName: "First Name",
            field: "member_firstname",
            headerTooltip: "Member First Name",
            tooltipField: "member_firstname",
            sortable: true,
            resizable: true,
            filter: true,
            width: 100,
          },
          {
            headerName: "DOB",
            field: "member_dob",
            headerTooltip: "Member DOB",
            tooltipField: "member_dob",
            sortable: true,
            resizable: true,
            filter: true,
            width: 95,
            cellRenderer: (params) => {
              if (params.data.member_dob){
                return moment(params.data.member_dob).format("MM/DD/YYYY");
              }
              else {
                return ''
              }
            },
            filterParams: {
              valueFormatter: (params) => {
                if (params.value) {
                  return moment(params.value).format("MM/DD/YYYY");
                } else{
                  return ''
                }
              },
          },
          },
        ],
      },
      {
        headerName: "Claim",
        children: [
          {
            headerName: "Claim ID",
            field: "claim_id_new",
            headerTooltip: "Claim ID",
            tooltipField: "claim_id_new",
            sortable: true,
            resizable: true,
            filter: true,
          //  headerCheckboxSelectionFilteredOnly: true,
            width: 150,
          },
          // {
          //   headerName: "Type",
          //   field: "newclaim",
          //   headerTooltip: "Type",
          //   sortable: true,
          //   resizable: true,
          //   filter: true,
          //   width: 75,
          //   cellRenderer: (params) => {
          //     return params.data.newclaim ? "New" : "Adjusted";
          //   },
          // },
          // {headerName: "Date of Service", field: "dos", sortable:true, resizable:true, filter:true, width:90, cellRenderer:(params)=>{return moment(params.data.dos).format('MM/DD/YYYY')}},
          // {headerName: "Facility", field: "groupname_printed_on_claimsform", tooltipField:'groupname_printed_on_claimsform', sortable:true, resizable:true, filter:true, width:120},
          // {headerName: "System Status", field: "package.systemstatus", sortable:true, resizable:true, filter:true, width:85},
          // {headerName: "Days Age", field: "age", sortable:true, resizable:true, filter:true, width:72, cellRenderer:(params)=>{return moment().diff(moment(params.data.package.createdate),'days') }},
          {
            headerName: "DOS",
            field: "dos",
            headerTooltip: "DOS",
            tooltipField: "dos",
            sortable: true,
            resizable: true,
            filter: true,
            width: 95,
            cellRenderer: (params) => {
              return moment(params.data.dos).format("MM/DD/YYYY");
            },
            filterParams: {
              valueFormatter: (params) => {
                if (params.value) {
                  return moment(params.value).format("MM/DD/YYYY");
                } else{
                  return ''
                }
              },
          },
          },
          // {headerName: "Facility", field: "groupname_printed_on_claimsform", sortable:true, resizable:true, filter:true, width:150},
          {
            headerName: "System Status",
            field: "package.systemstatus",
            headerTooltip: "System Status",
            tooltipField: "package.systemstatus",
            sortable: true,
            resizable: true,
            filter: true,
            width: 105,
            valueFormatter:(params)=>params.value?params.value.toUpperCase():null
          },
          {
            headerName: "Created",
            field: "package.createdate",
            headerTooltip: "date created",
            sortable: true,
            // sort: "asc",
            hide: true,
            resizable: true,
            filter: true,
            width: 120,
          },
          {
            headerName: "Days Aging",
            field: "days_aging",
            headerTooltip: "Days Aging",
            sortable: true,
            resizable: true,
            sort:'desc',
            filter: true,
            width: 130
          },
          {
            headerName: "_id",
            field: "_id",
            headerTooltip: "_id",
            sortable: true,
            resizable: false,
            hide: true,
            filter: true,
            width: 0
          },
           //
          // {field: "member_id", headerName: "Member ID", sortable:true, resizable:true, filter:true, width:95},
          // {field: "inventorykey", headerName: "Inventory Key", sortable:true, resizable:true, filter:true, width:100},
          // {field: "assigneduser", headerName: "Assigned User", sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:100},
        ],
      },
      // {headerName: "Days Age", field: "age", sortable:true, resizable:true, filter:true, width:72, cellRenderer:(params)=>{return moment().diff(moment(params.data.package.createdate),'days') }}, //+ ' days',
      // {headerName:'Package Info', children:[
      //     {headerName: "System Status", field: "package.systemstatus", sortable:true, resizable:true, filter:true, width:85},
      //     {headerName: "File Status", field: "package.filestatus", sortable:true, resizable:true, filter:true, width:80},
      //     {headerName: "Created", field: "package.createdate", sortable:true, resizable:true, filter:true, width:95},
      //     {headerName: "Days Age", field: "age", sortable:true, resizable:true, filter:true, width:72, cellRenderer:(params)=>{return moment().diff(moment(params.data.package.createdate),'days') }} //+ ' days'
      // ]}
      // {headerName: "City", field: "addresses.0.city", sortable:true, resizable:true, filter:true, width:140},
      // {headerName: "State", field: "addresses.0.state", sortable:true, resizable:true, filter:true, width:75},
      // {headerName: "Status", field: "basic.status", sortable:true, resizable:true, filter:true, width:80},
      {
        headerName: "Project",
        children: [
          {
            headerName: "ID",
            field: "projectid",
            headerTooltip: "Project ID",
            tooltipField: "projectid",
            sortable: true,
            resizable: true,
            filter: true,
            hide:true,
            width: 135,
          },
          {
            headerName: "Reporting Start Date",
            field: "project.reportingstartdate",
            headerTooltip: "Reporting Start Date",
            sortable: true,
            resizable: true,
            filter: true,
            width: 105,
            cellRenderer: (params) => {
              return moment(params.data.project.reportingstartdate).format("MM/DD/YYYY");
            },
            filterParams: {
              valueFormatter: (params) => {
                if (params.value) {
                  return moment(params.value).format("MM/DD/YYYY");
                } else{
                  return ''
                }
              },
          },
          },
          {
            headerName: "Reporting End Date",
            field: "project.reportingenddate",
            headerTooltip: "Reporting End Date",
            sortable: true,
            resizable: true,
            filter: true,
            width: 105,
            cellRenderer: (params) => {
              return moment(params.data.project.reportingenddate).format("MM/DD/YYYY");
            },
            filterParams: {
              valueFormatter: (params) => {
                if (params.value) {
                  return moment(params.value).format("MM/DD/YYYY");
                } else{
                  return ''
                }
              },
          },
          },
          
          {
            headerName: "Active",
            field: "project.active",
            headerTooltip: "Project Active",
            tooltipField: "project.active",
            sortable: true,
            resizable: true,
            filter: true,
            width: 80,
            cellRenderer: (params) => {
              if (params.data.project.active){
                return 'Y';
              }
              else {
                return 'N'
              }
            },
          }
        ],
      },
    ],
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
    loadingOverlayComponent: "customLoadingOverlay",
    noRowsOverlayComponent: "customNoRowsOverlay",
    defaultColDef:{ type: 'disableTreeList' },
    columnTypes:{
        disableTreeList: {
          filterParams: { treeList: false },
        },
      }
  };

  componentDidMount() {
    // console.log("PackageResults2 mounted");
    if (this.props.searchCriteria) {
      if (this.props.searchCriteria === "Clear") {
        this.setState({ rowData: null });
      } else {
        this.callAPI();
      }
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log("PackageResults2 updated");
  //   console.log(this.state.filter + " or " + this.props.filter+ " or " +prevProps.filter);
  //   if (this.props.filter && this.props.filter !== prevProps.filter) {
  //     console.log('got in if')
  //     this.setFilters(this.props);
  //     this.selectWhatever(this.props)
  //   } else {
  //     // this.selectFirst()
  //   }
  //   if (this.props.prVersion!==prevProps.prVersion || this.props.qOffset!==prevProps.qOffset) {
  //     console.log('prversion changed')
  //     this.callAPI()
  //     // this.setFilters(this.props.filter);
  //     // this.selectWhatever(this.props.packageid)
  //   } else {
  //     // this.selectFirst()
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
      // console.log("PackageResults2 updated");
      // console.log(this.state.filter + " or " + this.props.filter+ " or " +prevProps.filter);
      if ((this.props.filter) && (this.props.filter !== prevProps.filter || this.props.providerSearch !== prevProps.providerSearch)) {//
        // console.log('got in if')
        this.setFilters(this.props);
        // this.selectWhatever(this.props)
      } else {
        // this.selectFirst()
      }
      // if (this.props.qOffset!==prevProps.qOffset) {
      //   this.selectFirst()
      // }
      if (this.props.active!==prevProps.active) {
        this.gridApi.deselectAll()
        this.gridApi.showLoadingOverlay();
        this.callAPI()
      }
      if(this.props.countCheck !== prevProps.countCheck && prevProps.countCheck !== undefined) {
        this.gridApi.showLoadingOverlay();
        this.callAPI()
      }

  }

  callAPI = () => {
    let webParms = "";
    //        console.log(this.props.searchCriteria)
    _.each(this.props.searchCriteria, (val, key) => {
      webParms += `${key}=${val}&`;
    });
    webParms += `active=${this.props.active}`;
    //        console.log(webParms)
    // this.gridApi.showLoadingOverlay()
    fetch(`${localStorage.getItem("apiUrl")}/getPackageSearch2?${webParms}`
    )
      .then((res) => res.json())
      .then((res) => {
        let forReturn = [...res.rows];
        // console.log(forReturn)     
        this.setState(
          { rowData: forReturn },this.selectNext() //, this.selectFirst() //,this.postCall(this.props)
        );//
        if (forReturn.length === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      })
      // .then(() => {
      //   this.setFilters(this.state.filter);
      // })
      .catch(console.log);
  };

  postCall=(props)=>{
    this.setFilters(localStorage.getItem('filter'))
    // this.selectWhatever(props)
  }

  actionsRenderer = (params) => {
    return (
      <>
        {/* <button name="Show" onClick={()=>this.showHandler(params.data)} title="View" style={{background:'none', border:'none',color:'DarkBlue',padding:'0px', cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}>Show</button> &nbsp; */}
        <button
          name="2sided"
          onClick={() => this.show2Sided(params.data)}
          title="View 2-sided"
          style={{
            background: "none",
            border: "none",
            color: "DarkBlue",
            padding: "0px",
            cursor: "pointer",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          2sided
        </button>{" "}
        &nbsp;
        {/* <button name="View" onClick={()=>this.viewHandler(params.data)} title="View" style={{background:'none', border:'none',color:'DarkBlue',padding:'0px', cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}>View</button> &nbsp; */}
        {/* <button name="Repackage" onClick={()=>this.repackageHandler(params.data)} title="Repackage" style={{background:'none', border:'none',color:'DarkBlue',padding:'0px', cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}>Repackage</button> &nbsp;
                <button name="Reminder" onClick={()=>this.reminderHandler(params.data)} title="Reminder" style={{background:'none', border:'none',color:'DarkBlue',padding:'0px', cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}>Reminder</button> &nbsp; */}
        {/* <a href='http://www.optum.com' target='_blank' rel="noopener noreferrer">View</a> 
                <a href='http://www.optum.com' target='_blank' rel="noopener noreferrer" >ReOpen</a> 
                <a href='http://www.optum.com' target='_blank' rel="noopener noreferrer" >Reminder</a> */}
      </>
    );
  };

  updClaim =  (claim, type) => {
    // this.gridApi.showLoadingOverlay();
    console.log(claim)
    // console.log('we made it here1')
    this.gridApi.forEachNode((node) => {
      if(node.data._id===claim._id) {
        let age = new Date() - new Date(claim.package.createdate)
          // console.log(age)
          age = Math.round(age/86400000)
        // console.log(age)
        if(claim.package.systemstatus !== 'accepted' && claim.package.systemstatus !== 'rejected') {
        claim.days_aging =  age
        } else {
          claim.days_aging = null
        }

        node.data=claim
        this.gridApi.redrawRows(node)
      }
    })
   
    this.gridApi.onFilterChanged();
    this.selectNext() 
     
  }

  updClaim2 = (claim) => {
    // this.gridApi.showLoadingOverlay();
    // console.log('we made it here 2')
    this.gridApi.forEachNode((node) => {
      if(node.data._id===claim._id) {
        if(claim.package.systemstatus !== 'accepted' && claim.package.systemstatus !== 'rejected') {
          // console.log(claim.toDate)
          // console.log(claim.package.createdate)
        let age = new Date() - new Date(claim.package.createdate)
          // console.log(age)
          age = Math.round(age/86400000)
        // console.log(age)
        claim.days_aging = age
        }
        node.data=claim
        this.gridApi.redrawRows(node)
      }
    })
 
    this.gridApi.onFilterChanged();

    let selectedData = this.gridApi.getSelectedNodes();
    // console.log(selectedData)
    if(selectedData.length > 0) {
    let index = selectedData[0].childIndex
    // console.log(index)
    let pageNumber = index > 5 ? Math.floor(index / 6) : 0
    // console.log(pageNumber)
    this.gridApi.paginationGoToPage(pageNumber)
    }
  }

  // reselectClaim = (id) => {
  //   this.gridApi.deselectAll()
  //   this.gridApi.forEachNode((node)=>{
  //     // console.log(`row id :${node.data._id}  prop ID: ${id}... do they match? ${node.data._id == id}`)
  //     node.setSelected(node.data._id === id,true)
  //   })
  //   let selectedData = this.gridApi.getSelectedNodes();
  //   let index = selectedData[0].childIndex
  //   console.log(index)
  //   let pageNumber = index > 5 ? Math.floor(index / 6) : 0
  //   console.log(pageNumber)
  //   this.gridApi.paginationGoToPage(pageNumber)
  //   //  this.gridApi.onFilterChanged();
  // }

  selectPackage = (e) => {
    // console.log('selectHappened')
    // console.log(this.gridApi.getSelectedNodes())
    if(this.gridApi.getSelectedNodes()[0]) {
    localStorage.setItem('qIndex', JSON.stringify(this.gridApi.getSelectedNodes()[0].rowIndex))
    console.log(this.gridApi.getSelectedNodes()[0])
    // if (this.gridApi.getSelectedNodes()[0].data.package.systemstatus==='new') {
    //   alert('Please refer to the menu bar, select Resources, and then PCR User Guide for step by step instructions or contact your Outreach Ops representative.')
    // }
    this.props.changeHandler(this.gridApi.getSelectedNodes()[0].data);
    // console.log('is this actually firing')
    this.props.showClaims()
    } else {
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  };

  // onRowDataUpdated = params => {
  //     console.log('At 200')
  //     let savedSorts=[
  //         { colId: 'age', sort: 'desc' }
  //         ]

  //     if(this.gridApi) {
  //         this.gridColumnApi.applyColumnState({state:savedSorts,defaultState:{sort:null}})
  //         console.log('At 300')
  //         if(this.props.searchCriteria==='Clear'){
  //             this.gridApi.hideOverlay()
  //         }
  //     }
  // }

  showHandler = (obj) => {
    // console.log(e.target.name)
    let params = { twoSided: false, ...obj };
    this.props.showHideClaim(params);
  };
  show2Sided = (obj) => {
    // console.log(e.target.name)
    let params = { twoSided: true, ...obj };
    this.props.showHideClaim(params);
  };

  setFilters = (props) => {
    // console.log("got into setFilters");
    // console.log(props)
    let instance;
    let inValues=[]
    switch (props.filter) {
      // case "newDay":
      //   this.gridApi.setFilterModel({
      //     newclaim: { values: ["true"] },
      //   });
      //   this.gridApi.onFilterChanged();
      //   break;
      case "all":
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
        break;
      case "adjusted":
        // this.gridApi.setFilterModel({
        //   newclaim: { values: ["false"] },
        // });
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
        break;
      case 'aged':
        this.gridApi.setFilterModel(null)
        inValues=[]
        this.gridApi.forEachNode(node=>{
          if (moment().diff(moment(node.data.package.createdate),'days')>=7 && node.data.package.systemstatus!=='accepted'  && node.data.package.systemstatus!=='rejected') {
            inValues.push(node.data._id)
          }
        })
        // console.log(inValues)
        this.gridApi.setFilterModel({
          '_id':{values:inValues}
        })
        this.gridApi.onFilterChanged();
        break;
      case 'days7older':
        this.gridApi.setFilterModel(null)
        inValues=[]
        this.gridApi.forEachNode(node=>{
          if (moment().diff(moment(node.data.package.createdate),'days')>=7 && moment().diff(moment(node.data.package.createdate),'days')<14  && node.data.package.systemstatus!=='accepted' && node.data.package.systemstatus!=='rejected') {
            inValues.push(node.data._id)
          }
        })
        // console.log(inValues)
        this.gridApi.setFilterModel({
          '_id':{values:inValues},
          // 'systemstatus':{ values: ['open', 'new']}
        })
        this.gridApi.onFilterChanged();
        break;
      case 'days14older':
        this.gridApi.setFilterModel(null)
        inValues=[]
        this.gridApi.forEachNode(node=>{
          if (moment().diff(moment(node.data.package.createdate),'days')>=14 && node.data.package.systemstatus!=='accepted' && node.data.package.systemstatus!=='rejected') {
            inValues.push(node.data._id)
          }
        })
        // console.log(inValues)
        this.gridApi.setFilterModel({
          '_id':{values:inValues}
        })
        this.gridApi.onFilterChanged();
        break;
      // case "newOpen":
      //   this.gridApi.setFilterModel({
      //     "package.systemstatus": { values: ["new", "open", "pended"] },
      //     newclaim: { values: ["true"] },
      //   });
      //   this.gridApi.onFilterChanged();
      //   break;
      // case "newAccepted":
      //   this.gridApi.setFilterModel({
      //     "package.systemstatus": { values: ["accepted"] },
      //     newclaim: { values: ["true"] },
      //   });
      //   this.gridApi.onFilterChanged();
      //   break;
      // case "newRejected":
      //   this.gridApi.setFilterModel({
      //     "package.systemstatus": { values: ["rejected"] },
      //     newclaim: { values: ["true"] },
      //   });
      //   this.gridApi.onFilterChanged();
      //   break;
      case "adjustedOpen":
        this.gridApi.setFilterModel({
          "package.systemstatus": { values: ["new", "open", "pended"] },
          // newclaim: { values: ["false"] },
        });
        this.gridApi.onFilterChanged();
        break;
      case "adjustedAccepted":
        this.gridApi.setFilterModel({
          "package.systemstatus": { values: ["accepted"] },
          // newclaim: { values: ["false"] },
        });
        this.gridApi.onFilterChanged();
        break;
      case "adjustedRejected":
        // this.gridApi.setFilterModel({
        //   "package.systemstatus": { values: ["rejected"] },
        //   // newclaim: { values: ["false"] },
        // });
        inValues=[]
        this.gridApi.forEachNode(node=>{
          // console.log(node.data.package.systemstatus)
          if (node.data.package.systemstatus === "rejected") {
            inValues.push(node.data._id)
          }
        })
        console.log(inValues)
        this.gridApi.setFilterModel({
          '_id':{values:inValues}
        })
        this.gridApi.onFilterChanged();
        break;
      case "new":
        this.gridApi.setFilterModel({
          "package.systemstatus": { values: ["new","open"] }
        });
        this.gridApi.onFilterChanged();
        break;
      case "pended":
        this.gridApi.setFilterModel({
          "package.systemstatus": { values: ["pended"] },
        });
        this.gridApi.onFilterChanged();
        break;
      case "allOpen":
        this.gridApi.setFilterModel(null);
        instance = this.gridApi.getFilterInstance("package.systemstatus");
        instance.setModel({ values: ["new", "open", "pended"] });
        this.gridApi.onFilterChanged();
        break;
      case "allAccepted":
        this.gridApi.setFilterModel(null);
        instance = this.gridApi.getFilterInstance("package.systemstatus");
        instance.setModel({ values: ["accepted"] });
        this.gridApi.onFilterChanged();
        break;
      case "allRejected":
        this.gridApi.setFilterModel(null);
        instance = this.gridApi.getFilterInstance("package.systemstatus");
        instance.setModel({ values: ["rejected"] });
        this.gridApi.onFilterChanged();
        break;
        case "provider":
        localStorage.setItem('qIndex', "0")
        this.gridApi.setFilterModel(null);
        instance = this.gridApi.getFilterInstance("provider_id");
        instance.setModel({ values: [this.props.providerSearch] });
        this.gridApi.onFilterChanged();
        break;
      default:
        break;
    }
    // console.log(inValues)
    this.selectNext()

  };


  // selectFirst=()=>{
  //   console.log('got into selectFirst')
  //   let selectIndex=localStorage.getItem('qIndex')?parseInt(localStorage.getItem('qIndex')) : 0
  //   let totalRows = this.gridApi.getDisplayedRowCount()
  //   // if (this.state.qOffset) {
  //   //   selectIndex+=this.state.qOffset
  //   // }
  //   console.log(selectIndex);
  //   console.log(totalRows)
  //   console.log(selectIndex == totalRows)
  //   if(totalRows == 0) {
  //     this.props.clearClaims()
  //     this.gridApi.deselectAll()
  //   } else {
  //   if(selectIndex == totalRows) {
  //     selectIndex = 0
  //     this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
  //       node.setSelected(node.rowIndex===selectIndex,true)
  //     })
  //     this.gridApi.paginationGoToPage(0);
  //     this.props.showClaims()
  //   } else {
  //     console.log(selectIndex)
  //     this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
  //       node.setSelected(node.rowIndex===selectIndex,true)
  //     })
  //     let selectedData = this.gridApi.getSelectedNodes();
  //     let index = selectedData[0].childIndex
  //     console.log(index)
  //     let pageNumber = index > 5 ? Math.floor(index / 6): 0
  //     console.log(pageNumber)
  //     this.gridApi.paginationGoToPage(pageNumber)
  //     this.props.showClaims()
  //   }
  //   }
  // }

  selectNext=()=>{
    // console.log('got into selectNext')
    let selectIndex=localStorage.getItem('qIndex')?parseInt(localStorage.getItem('qIndex')) : 0
    let totalRows = this.gridApi.getDisplayedRowCount()
   
    // console.log(selectIndex);
    // console.log(totalRows)
    // console.log(selectIndex === totalRows)
    if(totalRows === 0) {
      this.props.clearClaims()
      this.gridApi.deselectAll()
      localStorage.setItem('qIndex', "0")
    } else {
    if(selectIndex === totalRows || selectIndex > totalRows) {
      this.gridApi.deselectAll()
      selectIndex = 0
      this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
        node.setSelected(node.rowIndex===selectIndex,true)
      })
      this.gridApi.paginationGoToPage(0);
      this.props.showClaims()
    } else {
      // console.log(selectIndex)
      this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
        node.setSelected(node.rowIndex===selectIndex,true)
      })
      let selectedData = this.gridApi.getSelectedNodes();
      // console.log(selectedData)
      if(selectedData.length > 0) {
      let index = selectedData[0].childIndex
      // console.log(index)
      let pageNumber = index > 5 ? Math.floor(index / 6) : 0
      // console.log(pageNumber)
      this.gridApi.paginationGoToPage(pageNumber)
      }
      this.props.showClaims()
    }
    }
  }


  selectWhatever=(props)=>{
    //console.log('selectWhatever runs' + ' ' + props.packageid)
    this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
      if (node.data.package.id===props.packageid) {
        console.log('got a match')
        node.setSelected(true, true)
      }
    })
  }

  onFirstDataRendered = (params) => {
    // console.log('onfirst runs')
    // params.api.forEachNodeAfterFilterAndSort((node) => {
    //   node.setSelected(node.rowIndex === 0);
    // });
    this.setFilters(this.state.filter?this.state:this.props);
    // this.selectFirst()
  };

  onFilterChanged=()=>{
    // console.log('onFilterChanged event')
    // this.selectFirst()
  }

  rowClickedHandler = (e) => {
    // console.log('rowClicked')
    if(this.props.editenabled) {
      if(window.confirm('Are you sure you want to leave the Edit mode? Any changes will be lost.')) {
        this.props.exitEdit()  // get out of edit mode
        e.node.setSelected(true) // Go to the clicked row
      }
    }
  }

  render() {
    // console.log(this.state.qOffset)
    // console.log(this.state.qIndex)
    return (
      // <div className="ag-theme-balham" >
      //  </div>
      (<AgGridReact
        onGridReady={this.onGridReady}
        rowSelection="single"
        onSelectionChanged={this.selectPackage}
        onRowClicked = {this.rowClickedHandler}
        suppressRowClickSelection={this.props.editenabled}
        // onRowDataUpdated={this.onRowDataUpdated}
        onFilterChanged={this.onFilterChanged}
        pagination={true}
        paginationAutoPageSize={true}
        // autoSize={true}
        columnDefs={this.state.columnDefs}
        rowData={this.state.rowData}
        // enableCellTextSelection={true}
        onFirstDataRendered={this.onFirstDataRendered}
        groupHeaderHeight={20}
        headerHeight={45}
        rowHeight={20}
        enableCellTextSelection={true}
        suppressCopyRowsToClipboard={true}
        components={this.state.frameworkComponents}
        reactiveCustomComponents={true}
        loadingOverlayComponent={this.state.loadingOverlayComponent}
        noRowsOverlayComponent={this.state.noRowsOverlayComponent}
        suppressCsvExport={true}
        suppressExcelExport={true}
        defaultColDef={this.state.defaultColDef}
        columnTypes={this.state.columnTypes}

      ></AgGridReact>)
    );
  }
}

export default PackageResults2;
