import React from 'react';
import {Card, Button, Container, Row, Col} from 'react-bootstrap'

const LoginError = () => {
    const closeHandler=()=>{
        localStorage.clear()
        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
    }
    return ( 

        <React.Fragment>
        <Container style={{paddingTop:'200px'}}>
            <Row>
                <Col lg={2}></Col>
                <Col lg={8}>
                <Card className='justify-content-center' >
            {/* <Card.Img variant="top" src={process.env.PUBLIC_URL + "/UHCCommunity.png"} height='80px' className='text-center'/> */}
            <Card.Header className='text-center'><h5>Service Outage</h5></Card.Header>
            <Card.Body>
            <Card.Text className='text-center'>
            <p>We’re experiencing a service outage with the PCR application. Our team is currently working to restore the service. We apologize for any inconvenience, please try again later.</p>
            <Button variant="warning" onClick={closeHandler}>Close</Button>
            </Card.Text>
            </Card.Body>
        </Card>
                </Col>
                <Col lg={2}></Col>
            </Row>
        </Container>

    </React.Fragment>
      
     );
}
 
export default LoginError;