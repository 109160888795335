import React, { Component } from 'react';
import { Navbar, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Paper } from '@mui/material';
class NavBarBottom extends Component {
    state = { 
        closed:this.props.closed,
        active:this.props.active,
        disabledAccept: this.props.disabledAccept,
        readOnly:this.props.readOnly
     }
    
    clickHandler=(e)=>{
        this.props.buttonsHandler(e.target.name ? e.target.name : e.target.id)
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('NavBarBottom updated')
        // console.log(this.props)
        if(this.props.closed!==prevProps.closed  || this.props.active!==prevProps.active || this.props.disabledAccept!== prevProps.disabledAccept || this.props.readOnly !== prevProps.readOnly){ 
            this.setState({closed:this.props.closed, active:this.props.active, disabledAccept: this.props.disabledAccept, readOnly: this.props.readOnly})}
            // console.log(this.props.disabledAccept)
    }

    bleh = () => {
        return (
            <button>test</button>
        )
    }

    render() { 
        return ( 
            <Container fluid>
                <Row >
                    <Col className='justify-content-center' >
                        <Navbar bg='white' variant='light' fixed='bottom' className='ag-theme-balham justify-content-center'>
                            {/* || !this.state.readOnly This is for 5A */}
                            {(this.state.active && !this.state.closed)  ?
                            <>
                            <Button className="rounded-pill border" bg='#078576' size="md" variant="success" title="Accept the Claim Form" name="accept" onClick={this.clickHandler} disabled={this.state.closed ? this.state.closed : this.props.disabledAccept ? true : false } style={{backgroundColor:'#078576'}} active={false}>
                            <Icon.CheckCircle id="accept"/> &ensp;
                            ACCEPT
                            </Button>
                            &emsp;
                            <Button className="rounded-pill border" size="md" variant="danger" title="Reject the Claim Form" name='reject' onClick={this.clickHandler} disabled={this.state.closed} style={{backgroundColor:'#d13f44'}}>
                            <Icon.XCircle id='reject'/> &ensp;
                            REJECT
                            </Button>
                            &emsp;
                            <Button className="rounded-pill border" size="md" variant="warning" title="Pend the Claim Form" name='pend' onClick={this.clickHandler} disabled={this.state.closed} style={{backgroundColor:'#f2b411'}}>
                            <Icon.Save2 id='pend'/> &ensp;
                            PEND
                            </Button>
                            &emsp;
                            <Button
                            className="rounded-pill border" size="md" variant="secondary" name="edit" title="Edit the Claim Form" onClick={this.clickHandler} disabled={this.state.closed} 
                            // editenabled={this.state.editenabled} style={{backgroundColor:this.props.editenabled ? 'yellow' : '', color:this.props.editenabled ?'black' : ''}}
                            >
                            <Icon.PencilSquare id="edit"/> &ensp;
                            EDIT
                            </Button>
                            </>
                            :null}
                            &emsp;
                             {/* <Button className="rounded-pill border" size="md" variant="primary" name='compare' title="Compare Adjusted Claim to the Original Claim" onClick={this.clickHandler} style={{backgroundColor:'#316bbe'}}>
                            <Icon.JournalArrowUp /><Icon.JournalArrowDown />  &ensp;
                            COMPARE
                            </Button>&emsp; */}
                            {/* <Button className="rounded-pill border" size="md" variant="primary" name='showOriginal' title="Toggle display of Original Claim" onClick={this.clickHandler} style={{backgroundColor:'#316bbe'}}>
                            <Icon.JournalArrowUp /><Icon.JournalArrowDown />  &ensp;
                            Show/Hide Original
                            </Button>&emsp;
                            <Button className="rounded-pill border" size="md" variant="primary" name='showAdjusted' title="Toggle display of Adjusted Claim" onClick={this.clickHandler} style={{backgroundColor:'#316bbe'}}>
                            <Icon.JournalArrowUp /><Icon.JournalArrowDown />  &ensp;
                            Show/Hide Adjusted
                            </Button>&emsp; */}
                            <Button className="rounded-pill border" size="md" variant="warning" name='medicalRecord' title="Show the Medical Record for the selected Claim" onClick={this.clickHandler} style={{backgroundColor:'#e87722', color:'white'}}>
                            <Icon.JournalMedical id='medicalRecord' /> &ensp;
                            MEDICAL RECORD
                            </Button>
                            &emsp;
                            {/* <Button className="rounded-pill border" size="md" variant="primary" name='eop' title="Show the EOP for the selected Claim" onClick={this.clickHandler} style={{ color:'white', marginRight: 10 }}>
                            <Icon.JournalMedical id='eop' /> &ensp;
                            EOP
                            </Button> */}
                            <Dropdown drop='up' >
                                <Dropdown.Toggle className="rounded-pill border" size="md"  variant="success" id="dropdown-basic">
                                <Icon.JournalArrowUp /><Icon.JournalArrowDown />  &ensp;  VIEW
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{position: 'fixed', padding: 0}} >
                                    <Paper>
                                    <Dropdown.Item style={{marginTop: '-180px'}} onClick={this.clickHandler}  name='sidebyside'>Side by Side: Adjusted, Original</Dropdown.Item>
                                    <Dropdown.Item  onClick={this.clickHandler} name='tripod'>Tripod: Medical Record, Adjusted, Original</Dropdown.Item>
                                    {/* <Dropdown.Item  onClick={this.clickHandler} name='fourblock' >Four Block: Medical Record, EOP, Adjusted, Original</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clickHandler} name='array'>Cascade: Medical Record, EOP, Adjusted, Original</Dropdown.Item> */}
                                    <Dropdown.Item onClick={this.clickHandler} name='array'>Cascade: Medical Record, Adjusted, Original</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clickHandler} name='single'>Default: Adjusted</Dropdown.Item>
                                    </Paper>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar> 
                        {/* <br />
                        &copy;2022 Optum, Inc. All rights reserved.                              */}
                    </Col>
                </Row>
            </Container>
            
         );
    }
}
 
export default NavBarBottom;