const posDescr=(pos)=>{
    let ans=''
    switch (pos) {
        case '01': ans='Pharmacy';break
        case '02': ans='Telehealth';break
        case '03': ans='School';break
        case '04': ans='Homeless Shelter';break
        case '05': ans='Indian Health Service Free-standing Facility';break
        case '06': ans='Indian Health Service Provider-based Facility';break
        case '07': ans='Tribal 638 Free-standing Facility';break
        case '08': ans='Tribal 638 Provider-based Facility';break
        case '09': ans='Prison/Correctional Facility';break
        case '10': ans="Telehealth Provided in Patient’s Home";break
        case '11': ans='Office';break
        case '12': ans='Home';break
        case '13': ans='Assisted Living Facility';break
        case '14': ans='Group Home';break
        case '15': ans='Mobile Unit';break
        case '16': ans='Temporary Lodging';break
        case '17': ans='Walk-in Retail Health Clinic';break
        case '18': ans='Place of Employment-Worksite';break
        case '19': ans='Off Campus-Outpatient Hospital';break
        case '20': ans='Urgent Care Facility';break
        case '21': ans='Inpatient Hospital';break
        case '22': ans='On Campus-Outpatient Hospital';break
        case '23': ans='Emergency Room – Hospital';break
        case '24': ans='Ambulatory Surgical Center';break
        case '25': ans='Birthing Center';break
        case '26': ans='Military Treatment Facility';break
        case '31': ans='Skilled Nursing Facility';break
        case '32': ans='Nursing Facility';break
        case '33': ans='Custodial Care Facility';break
        case '34': ans='Hospice';break
        case '41': ans='Ambulance - Land';break
        case '42': ans='Ambulance – Air or Water';break
        case '49': ans='Independent Clinic';break
        case '50': ans='Federally Qualified Health Center';break
        case '51': ans='Inpatient Psychiatric Facility';break
        case '52': ans='Psychiatric Facility-Partial Hospitalization';break
        case '53': ans='Community Mental Health Center';break
        case '54': ans='Intermediate Care Facility/ Individuals with Intellectual Disabilities';break
        case '55': ans='Residential Substance Abuse Treatment Facility';break
        case '56': ans='Psychiatric Residential Treatment Center';break
        case '57': ans='Non-residential Substance Abuse Treatment Facility';break
        case '58': ans='Non-residential Opioid Treatment Facility';break
        case '60': ans='Mass Immunization Center';break
        case '61': ans='Comprehensive Inpatient Rehabilitation Facility';break
        case '62': ans='Comprehensive Outpatient Rehabilitation Facility';break
        case '65': ans='End-Stage Renal Disease Treatment Facility';break
        case '71': ans='Public Health Clinic';break
        case '72': ans='Rural Health Clinic';break
        case '81': ans='Independent Laboratory';break
        case '99': ans='Other Place of Service';break
        default: ans='';break
        }
    return ans
}
const modifierDescr=(modifier)=>{
    let ans=''
    switch (modifier) {
        case '21': ans='Prolonged Evaluation and Management Services';break
        case '22': ans='Increased procedural services';break
        case '23': ans='Unusual Anesthesia.';break
        case '24': ans='Unrelated E/M service during post-op period.';break
        case '25': ans='Significant, separately identifiable E/M service by the same physician on the same day of the procedure or other service.';break
        case '26': ans='Professional Component.';break
        case '27': ans='Multiple Outpatient Hospital E/M Encounters on the Same Date.';break
        case '29': ans='Global procedures, those procedures where one provider is responsible for both the professional and technical component.';break
        case '32': ans='Mandated Services.';break
        case '33': ans='Preventive Service.';break
        case '47': ans='Anesthesia by Surgeon.';break
        case '50': ans='Bilateral Procedure.';break
        case '51': ans='Multiple Procedures.';break
        case '52': ans='Reduced Services.';break
        case '53': ans='Discontinued Procedure.';break
        case '54': ans='Surgical Care Only.';break
        case '55': ans='Postoperative Management Only.';break
        case '56': ans='Preoperative Management Only.';break
        case '57': ans='Decision for Surgery.';break
        case '58': ans='Staged or Related Procedure or Service by the Same Physician During the Postoperative Period.';break
        case '59': ans='Distinct procedural service.';break
        case '62': ans='Two Surgeons.';break
        case '63': ans='Procedure Performed on Infants less than 4kg.';break
        case '66': ans='Surgical Team.';break
        case '73': ans='Discontinued Outpatient Hospital/Ambulatory Surgery Center (ASC) Procedure prior to the Administration of Anesthesia.';break
        case '74': ans='Discontinued Outpatient Hospital/Ambulatory Surgery Center (ASC) Procedure after Administration of Anesthesia.';break
        case '76': ans='Repeat Procedure by Same Physician.';break
        case '77': ans='Repeat Procedure by Another Physician.';break
        case '78': ans='Return to the Operating Room for a Related Procedure During the Postoperative Period.';break
        case '79': ans='Unrelated procedure by the same physician during the post-operated period.';break
        case '80': ans='Assistant Surgeon.';break
        case '81': ans='Minimum Assistant Surgeon.';break
        case '82': ans='Assistant Surgeon (when qualified resident surgeon not available).';break
        case '90': ans='Reference (Outside) Laboratory.';break
        case '91': ans='Repeat Clinical Diagnostic Laboratory Test.';break
        case '92': ans='Alternative Laboratory Platform Testing.';break
        case '96': ans='Habilitative Services.';break
        case '97': ans='Rehabilitative Services.';break
        case '99': ans='Multiple Modifiers.';break
        case 'AA': ans='Anesthesia services personally performed by anesthesiologist.';break
        case 'AD': ans='Medical supervision by a physician: More than 4 concurrent anesthesia procedures.';break
        case 'AE': ans='Registered Dietician.';break
        case 'AF': ans='Specialty Physician.';break
        case 'AG': ans='Primary Physician.';break
        case 'AH': ans='Clinical Psychologist.';break
        case 'AI': ans='Principal Physician of Record.';break
        case 'AJ': ans='Clinical Social Worker.';break
        case 'AK': ans='Non Participating Physician.';break
        case 'AM': ans='Physician, team member service.';break
        case 'AP': ans='Determination of refractive state was not performed in the course of diagnostic ophthalmological examination.';break
        case 'AQ': ans='Service performed in a Health Professional Shortage Area.';break
        case 'AR': ans='Physician providing services in a physician scarcity area.';break
        case 'AS': ans='Physician Assistant, Nurse Practitioner, or Clinical Nurse Specialist services for assistant-at-surgery, non-team member.';break
        case 'AT': ans='Acute treatment (chiropractic claims).';break
        case 'AU': ans='Item furnished in conjunction with a urological, ostomy, or tracheostomy supply.';break
        case 'AV': ans='Item furnished in conjunction with a prosthetic device, prosthetic or orthotic.';break
        case 'AW': ans='Item furnished in conjunction with a surgical dressing.';break
        case 'AX': ans='Item furnished in conjunction with dialysis services.';break
        case 'AY': ans='Item or service furnished to an ESRD patient that is not for the treatment of ERSD.';break
        case 'AZ': ans='Physician providing a service in a dental Health Professional Shortage Area for the purpose of an Electronic Health Record Incentive Payment.';break
        default: ans='';break
    }
    return ans;
}
const taxonomyDescr=async (taxonomyCode)=>{
    // switch (taxonomyCode) {
    //     case '193200000X': ans='Multi-Specialty Group';break
    //     case '193400000X': ans='Single-Specialty Group';break
    //     case '207K00000X': ans='Allergy & Immunology';break
    //     case '207KA0200X': ans='Allergy & Immunology';break
    //     case '207KI0005X': ans='Clinical & Laboratory Immunology';break
    //     case '207L00000X': ans='Anesthesiology';break
    //     case '207LA0401X': ans='Anesthesiology';break
    //     case '207LC0200X': ans='Anesthesiology';break
    //     case '207LH0002X': ans='Anesthesiology';break
    //     case '207LP2900X': ans='Anesthesiology';break
    //     case '207LP3000X': ans='Anesthesiology';break
    //     case '208U00000X': ans='Clinical Pharmacology';break
    //     case '208C00000X': ans='Colon & Rectal Surgery';break
    //     case '207N00000X': ans='Dermatology';break
    //     case '207NI0002X': ans='Clinical & Laboratory Dermatological Immunology';break
    //     case '207ND0900X': ans='Dermatopathology';break
    //     case '207ND0101X': ans='MOHS-Micrographic Surgery';break
    //     case '207NP0225X': ans='Pediatric Dermatology';break
    //     case '207NS0135X': ans='Procedural Dermatology';break
    //     case '204R00000X': ans='Electrodiagnostic Medicine';break
    //     case '207P00000X': ans='Emergency Medicine';break
    //     case '207PE0004X': ans='Emergency Medical Services';break
    //     case '207PH0002X': ans='Hospice and Palliative Medicine';break
    //     case '207PT0002X': ans='Medical Toxicology';break
    //     case '207PP0204X': ans='Pediatric Emergency Medicine';break
    //     case '207PS0010X': ans='Sports Medicine';break
    //     case '207PE0005X': ans='Undersea and Hyperbaric Medicine';break
    //     case '207Q00000X': ans='Family Medicine';break
    //     case '207QA0401X': ans='Addiction Medicine';break
    //     default: ans='';break
    // }
    let resj = await validateTxCodes(taxonomyCode)
    if(resj.rows.length>0) {
        console.log('001')
        return resj.rows[0].specialization        
    }
    console.log('002')
    return 'Invalid Taxonomy Code';
}

const validateTxCodes= async (TaxonomyCDs)=>{
    console.log(TaxonomyCDs)
    //http://apsrp07827.uhc.com:8088/api/v1.0/getTaxonomy?code=193200000X,207LC0200X
    try {
        let res = await fetch(localStorage.getItem('apiUrl') + '/getTaxonomy?code='+TaxonomyCDs)
        let resj = await res.json()
        return resj
    } catch(err){
        console.log(err)
    }
}

export {posDescr,modifierDescr,taxonomyDescr}
