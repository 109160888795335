import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
export default class CustomLoadingOverlay extends Component {
  render() {
    return (
      <div
        className="ag-overlay-loading-center"
        style={{ backgroundColor: "#00a86b", height: "9%" }}
      >
        <Spinner animation="border" />
        <i className="fas fa-hourglass-half">One Moment Please...</i>
      </div>
    );
  }
}
