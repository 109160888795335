import React from "react";
import { Route, Switch } from "react-router-dom";
//import Dashboard from "../containers/Dashboard";
//import Login from '../Login';
// import Packages from "../containers/Packages";
// //import Super from "../containers/Super";
// //import Single from "../containers/Single";
// import PortalDash from "../containers/PortalDash";
// import DashCMS from "../containers/DashCMS";
import DashCMS2 from "../containers/DashCMS2";
import UserGuide from "./UserGuide";
import FAQ from "./FAQ";
import TOU from "./TOU";


/**
 *
 */
 const RouteSwitch = (props) => {
  return (
    <Switch style={{ margin: "0px", padding: "0px" }}>
      {/* <Route path="/login" component={Login} /> */}
      {/* <Route path="/single" component={Single} />
      <Route path="/super" component={Super} />
      <Route path="/dashboard" component={Dashboard} /> */}
      {/* <Route path="/packages" component={Packages} /> */}
      {/* <Route path="/portalDash" component={PortalDash} />
      <Route path="/dash" component={DashCMS} /> */}
      <Route path="/dashboard" render={(prop) => (<DashCMS2 showBroadcastMessage={props.showBroadcastMessage} {...prop} />)} />
      <Route path="/userGuide" component={UserGuide} />
      <Route path="/faq" component={FAQ} />
      <Route path="/tou" component={TOU} />
      <Route path="/" render={(prop) => (<DashCMS2 showBroadcastMessage={props.showBroadcastMessage} {...prop} />)} />
      <Route render={() => <h1>Not found</h1>} />
    </Switch>
  );
};

export default RouteSwitch;
